import React, { cloneElement, Children } from "react";
import dayjs from "dayjs";

import styles from "./MonthView.module.css";
import { CalendarContext } from "../../Context/CalendarContext";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import Day from "./Day";

export default function MonthView({ month }) {
  const {
    setDaySelected,
    setShowEventModal,
    filteredEvents,
    setSelectedEvent,
    savedCalendars,
  } = React.useContext(CalendarContext);

  const [eventsInWeek, setEventsInWeek] = React.useState([]);
  const [eventsOutWeek, setEventsOutWeek] = React.useState([]);
  const [eventsEndOutMatrix, setEventsEndOutMatrix] = React.useState([]);
  const [eventsStartOutMatrix, setEventsStartOutMatrix] = React.useState([]);
  const [eventMatrix, setEventMatrix] = React.useState(
    month.map((row, r) => row.map((day, c) => []))
  );
  const [dayMatrix, setDayMatrix] = React.useState([]);
  const [showEventsListModal, setShowEventsListModal] = React.useState(false);
  const [eventList, setEventList] = React.useState(0);
  const [mouseX, setMouseX] = React.useState(0);
  const [mouseY, setMouseY] = React.useState(0);

  React.useEffect(() => {
    const checkedCalendars = savedCalendars.filter((cal) => cal.checked);

    const daysInMatrix = [].concat(...month);

    var reducedEvents = filteredEvents.reduce(function (result, evt) {
      if (
        dayjs(daysInMatrix.at(-1)).diff(dayjs(evt.dayStart)) >= 0 &&
        dayjs(daysInMatrix[0]).diff(dayjs(evt.dayEnd)) <= 0 &&
        checkedCalendars.map((cal) => cal.id).includes(evt.calendar.id)
      ) {
        result.push(evt);
      }
      return result;
    }, []);
    // Sort to show the first event on top
    reducedEvents.sort(function (a, b) {
      return a.dayStart - b.dayStart;
    });

    const endOutMatrix = reducedEvents.filter((evt) => {
      return (
        daysInMatrix
          .map((d) => dayjs(d).format("DD-MM-YY"))
          .indexOf(dayjs(evt.dayEnd).format("DD-MM-YY")) < 0
      );
    });

    const startOutMatrix = reducedEvents.filter((evt) => {
      return (
        daysInMatrix
          .map((d) => dayjs(d).format("DD-MM-YY"))
          .indexOf(dayjs(evt.dayStart).format("DD-MM-YY")) < 0 &&
        daysInMatrix
          .map((d) => dayjs(d).format("DD-MM-YY"))
          .indexOf(dayjs(evt.dayEnd).format("DD-MM-YY")) >= 0
      );
    });

    const eOutWeek = reducedEvents.filter((evt) => {
      return (
        dayjs(evt.dayEnd).diff(dayjs(evt.dayStart).day(6), "day") > 0 &&
        !endOutMatrix.includes(evt) &&
        !startOutMatrix.includes(evt)
      );
    });

    const eInWeek = reducedEvents.filter(
      (evt) =>
        !eOutWeek.includes(evt) &&
        !endOutMatrix.includes(evt) &&
        !startOutMatrix.includes(evt)
    );

    let matrixCopy = month.map((row, r) => row.map((day, c) => [0, 0, 0]));

    function addEvtToMatrix(evt, matrix, r, c, evtSize) {
      // first event
      if (matrix[r][c][0] === 0) {
        matrix[r][c][0] = evt;
        var countC = 0;
        while (evtSize > 0) {
          evtSize = evtSize - 1;
          countC = countC + 1;
          if (c + countC < 7) {
            matrix[r][c + countC][0] = 1;
          } else if (r < 5) {
            countC = 0;
            c = 0;
            r = r + 1;
            matrix[r][c + countC][0] = 1;
          }
        }
      }
      // second event
      else if (matrix[r][c][0] !== 0 && matrix[r][c][1] === 0) {
        matrix[r][c][1] = evt;
        countC = 0;
        while (evtSize > 0) {
          evtSize = evtSize - 1;
          countC = countC + 1;
          if (c + countC < 7) {
            matrix[r][c + countC][1] = 1;
          } else if (r < 5) {
            countC = 0;
            c = 0;
            r = r + 1;
            matrix[r][c + countC][1] = 1;
          }
        }
      }
      // third event
      else if (
        matrix[r][c][0] !== 0 &&
        matrix[r][c][1] !== 0 &&
        matrix[r][c][2] === 0
      ) {
        matrix[r][c][2] = [evt];
        countC = 0;
        while (evtSize > 0) {
          evtSize = evtSize - 1;
          countC = countC + 1;
          if (c + countC < 7) {
            matrix[r][c + countC][2] = [evt];
          } else if (r < 5) {
            countC = 0;
            c = 0;
            r = r + 1;
            matrix[r][c + countC][2] = [evt];
          }
        }
      } else {
        Array.isArray(matrix[r][c][2]) && matrix[r][c][2].push(evt);

        countC = -1;
        while (evtSize >= 0) {
          evtSize = evtSize - 1;
          countC = countC + 1;
          if (c + countC < 7 && matrix[r][c + countC][2] !== 0) {
            Array.isArray(matrix[r][c + countC][2]) &&
              matrix[r][c + countC][2].indexOf(evt) < 0 &&
              matrix[r][c + countC][2].push(evt);
          } else if (c + countC < 7 && matrix[r][c + countC][2] === 0) {
            matrix[r][c + countC][2] = [evt];
          } else if (r < 5) {
            countC = -1;
            c = 0;
            r = r + 1;
            evtSize = evtSize + 1;
          }
        }
      }
    }

    function addEvtBetweenToMatrix(evt, matrix, r, c, evtSize) {
      // first event
      if (matrix[r][c][0] === 0) {
        matrix[r][c][0] = evt;
        var countC = 0;
        while (evtSize > 0) {
          evtSize = evtSize - 1;
          countC = countC + 1;
          if (c + countC < 7) {
            matrix[r][c + countC][0] = 1;
          } else if (r < 5) {
            countC = 0;
            c = 0;
            r = r + 1;
            matrix[r][c + countC][0] = 1;
          }
        }
      }
      // second event
      else if (
        matrix[r][c][0] !== 0 &&
        matrix[r][c][0] !== evt &&
        matrix[r][c][1] === 0
      ) {
        matrix[r][c][1] = evt;
        countC = 0;
        while (evtSize > 0) {
          evtSize = evtSize - 1;
          countC = countC + 1;
          if (c + countC < 7) {
            matrix[r][c + countC][1] = 1;
          } else if (r < 5) {
            countC = 0;
            c = 0;
            r = r + 1;
            matrix[r][c + countC][1] = 1;
          }
        }
      }
      // third event
      else if (
        matrix[r][c][0] !== 0 &&
        matrix[r][c][1] !== 0 &&
        matrix[r][c][0] !== evt &&
        matrix[r][c][1] !== evt
      ) {
        if (matrix[r][c][2] === 0) {
          matrix[r][c][2] = [evt];
          countC = 0;
          while (evtSize > 0) {
            evtSize = evtSize - 1;
            countC = countC + 1;
            if (c + countC < 7) {
              matrix[r][c + countC][2] = [evt];
            } else if (r < 5) {
              countC = 0;
              c = 0;
              r = r + 1;
              matrix[r][c + countC][2] = [evt];
            }
          }
        } else if (
          Array.isArray(matrix[r][c][2]) &&
          matrix[r][c][2].findIndex((e) => e.id === evt.id) < 0
        ) {
          matrix[r][c][2] = [...matrix[r][c][2], evt];
          countC = 0;
          while (evtSize > 0) {
            evtSize = evtSize - 1;
            countC = countC + 1;
            if (c + countC < 7) {
              matrix[r][c + countC][2] = [...matrix[r][c + countC][2], evt];
            } else if (r < 5) {
              countC = 0;
              c = 0;
              r = r + 1;
              matrix[r][c + countC][2] = [...matrix[r][c + countC][2], evt];
            }
          }
        }
      } else return null;
    }

    function getEventSize(evt) {
      var eventSize = dayjs(evt.dayEnd).diff(dayjs(evt.dayStart)) / 86400000;
      if (
        dayjs(evt.dayStart).format("DD-MM-YY") ===
        dayjs(evt.dayEnd).format("DD-MM-YY")
      ) {
        return eventSize - 1;
      } else {
        return eventSize;
      }
    }

    // Desenvolve a matrix de eventos
    month.map((row, r) =>
      row.map((day, c) =>
        reducedEvents.map((evt) => {
          // Handle day in or out matrix
          // days in matrix
          if (
            dayjs(day).format("DD-MM-YY") ===
              dayjs(evt.dayStart).format("DD-MM-YY") &&
            matrixCopy[r][c].findIndex((e) => e.id === evt.id) < 0
          ) {
            return addEvtToMatrix(evt, matrixCopy, r, c, getEventSize(evt));
          }
          // days out matrix
          else if (
            daysInMatrix
              .map((d) => dayjs(d).format("DD-MM-YY"))
              .indexOf(dayjs(evt.dayStart).format("DD-MM-YY")) < 0 &&
            dayjs(day).format("DD-MM-YY") ===
              dayjs(evt.dayEnd).format("DD-MM-YY") &&
            matrixCopy[r][c].findIndex((e) => e.id === evt.id) < 0
          ) {
            return addEvtToMatrix(
              evt,
              matrixCopy,
              0,
              0,
              dayjs(evt.dayEnd).diff(dayjs(evt.dayStart), "day") -
                dayjs(daysInMatrix.at(0)).diff(dayjs(evt.dayStart), "day")
            );
          }
          // days between matrix
          else if (
            daysInMatrix
              .map((d) => dayjs(d).format("DD-MM-YY"))
              .indexOf(dayjs(evt.dayStart).format("DD-MM-YY")) < 0 &&
            daysInMatrix
              .map((d) => dayjs(d).format("DD-MM-YY"))
              .indexOf(dayjs(evt.dayEnd).format("DD-MM-YY")) < 0 &&
            day.diff(dayjs(evt.dayStart), "day") >= 0 &&
            day.diff(dayjs(evt.dayEnd), "day") < 0 &&
            matrixCopy[r][c].findIndex((e) => e.id === evt.id) < 0
          ) {
            return addEvtBetweenToMatrix(
              evt,
              matrixCopy,
              0,
              0,
              dayjs(evt.dayEnd).diff(dayjs(evt.dayStart), "day")
            );
          } else return null;
        })
      )
    );
    // console.log(checkedCalendars.map((cal) => cal));
    // console.log(filteredEvents);
    // console.log(reducedEvents);

    setEventMatrix(matrixCopy);
    setDayMatrix(daysInMatrix);
    setEventsEndOutMatrix(endOutMatrix);
    setEventsStartOutMatrix(startOutMatrix);
    setEventsOutWeek(eOutWeek);
    setEventsInWeek(eInWeek);
  }, [filteredEvents, savedCalendars, month]);

  function getEvtSize(evt, isOutWeek = false) {
    const dayStartZero = new Date(evt.dayStart);
    dayStartZero.setHours(0);
    dayStartZero.setMinutes(0);
    const dayEndZero = new Date(evt.dayEnd);
    dayEndZero.setHours(0);
    dayEndZero.setMinutes(0);

    if (isOutWeek) {
      var eventSize = dayjs(dayEndZero).diff(dayjs(dayStartZero).day(6), "day");
      return eventSize;
    } else {
      eventSize = dayjs(dayEndZero).diff(dayjs(dayStartZero), "day");
      return eventSize;
    }
  }

  // SHOW EVENT IN WEEK
  function isInWeek(evt, iRow, iColumn, day, index) {
    return (
      <>
        <div
          key={evt.id}
          className={`${styles.event} ${evt.calendar.color} ${
            index === 1 ? styles.eventOne : styles.eventTwo
          }`}
          style={{
            gridRow: iRow + 2,
            gridColumn: `${iColumn + 1} / span ${getEvtSize(evt) + 1}`,
          }}
          onClick={() => {
            setSelectedEvent(evt);
            setDaySelected(day);
            setShowEventModal(true);
          }}
        >
          {evt.allDay
            ? evt.title
            : `${dayjs(evt.dayStart).format("h:mma")} - ${evt.title}`}
          {evt.title ? "" : "(No title)"}
        </div>
      </>
    );
  }

  // CHECK IF EVENT END OUT OF MATRIX AND SHOW IT
  function isEndOutMatrix(evt, iRow, iColumn, day, index) {
    if (eventsEndOutMatrix.some((e) => e.id === evt.id)) {
      return iRow === 5 ? (
        <div
          key={evt.id}
          className={`${styles.eventOutMonth}  ${evt.calendar.color} ${
            index === 1 ? styles.eventOne : styles.eventTwo
          }`}
          style={{
            gridRow: iRow + 2,
            gridColumn: `${iColumn + 1} / span ${getEvtSize(evt) + 1}`,
          }}
          onClick={() => {
            setSelectedEvent(evt);
            setDaySelected(day);
            setShowEventModal(true);
          }}
        >
          {evt.allDay
            ? evt.title
            : `${dayjs(evt.dayStart).format("h:mma")} - ${evt.title}`}
          {evt.title ? "" : "(No title)"}
        </div>
      ) : (
        <React.Fragment key={evt.id}>
          <div
            className={`${styles.eventOutMonth}  ${evt.calendar.color} ${
              index === 1 ? styles.eventOne : styles.eventTwo
            }`}
            style={{
              gridRow: iRow + 2,
              gridColumn: `${iColumn + 1} / span ${getEvtSize(evt) + 1}`,
            }}
            onClick={() => {
              setSelectedEvent(evt);
              setDaySelected(day);
              setShowEventModal(true);
            }}
          >
            {evt.allDay
              ? evt.title
              : `${dayjs(evt.dayStart).format("h:mma")} - ${evt.title}`}
            {evt.title ? "" : "(No title)"}
          </div>
          {evtEndOutMatrix(evt, iRow, iColumn, day, index)}
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  function evtEndOutMatrix(evt, iRow, iColumn, day, index) {
    if (iRow === 5) {
      return null;
    }
    if (iRow < 5) {
      return (
        <>
          <div
            key={evt.id + 2}
            className={`${styles.eventOut} ${evt.calendar.color}  ${
              index === 1 ? styles.eventOne : styles.eventTwo
            }`}
            style={{
              gridRow: iRow + 3,
              gridColumn: `${1} / span 7`,
            }}
            onClick={() => {
              setSelectedEvent(evt);
              setDaySelected(day);
              setShowEventModal(true);
            }}
          >
            {evt.allDay
              ? evt.title
              : `${dayjs(evt.dayStart).format("h:mma")} - ${evt.title}`}
            {evt.title ? "" : "(No title)"}
          </div>
          {evtEndOutMatrix(evt, iRow + 1, iColumn, day, index)}
        </>
      );
    }
  }

  // CHECK IF EVENT IS OUT WEEK
  function isOutWeek(
    evt,
    iRow,
    iColumn,
    day,
    index,
    eventSize = getEvtSize(evt, true)
  ) {
    return (
      <>
        <div
          key={evt.id}
          className={`${styles.eventOut} ${evt.calendar.color} ${
            index === 1 ? styles.eventOne : styles.eventTwo
          }`}
          style={{
            gridRow: iRow + 2,
            gridColumn: `${iColumn + 1} / span ${getEvtSize(evt) + 1}`,
          }}
          onClick={() => {
            setSelectedEvent(evt);
            setDaySelected(day);
            setShowEventModal(true);
          }}
        >
          {evt.allDay
            ? evt.title
            : `${dayjs(evt.dayStart).format("h:mma")} - ${evt.title}`}
          {evt.title ? "" : "(No title)"}
        </div>
        {evtOutWeek(evt, iRow, iColumn, day, index, eventSize)}
      </>
    );
  }

  // SHOW OUT WEEK EVENT TILL END
  function evtOutWeek(
    evt,
    iRow,
    iColumn,
    day,
    index,
    eventSize = getEvtSize(evt, true)
  ) {
    if (eventSize > 0) {
      if (eventSize <= 7) {
        return (
          <>
            <div
              key={evt.id + 2}
              className={`${styles.event} ${evt.calendar.color} ${
                index === 1 ? styles.eventOne : styles.eventTwo
              }`}
              style={{
                gridRow: iRow + 3,
                gridColumn: `${1} / span ${eventSize}`,
              }}
              onClick={() => {
                setSelectedEvent(evt);
                setDaySelected(day);
                setShowEventModal(true);
              }}
            >
              {evt.allDay
                ? evt.title
                : `${dayjs(evt.dayStart).format("h:mma")} - ${evt.title}`}
              {evt.title ? "" : "(No title)"}
            </div>
          </>
        );
      }
      if (eventSize > 7) {
        return (
          <>
            <div
              key={evt.id + 2}
              className={`${styles.eventOut} ${evt.calendar.color} ${
                index === 1 ? styles.eventOne : styles.eventTwo
              }`}
              style={{
                gridRow: iRow + 3,
                gridColumn: `${1} / span ${eventSize}`,
              }}
              onClick={() => {
                setSelectedEvent(evt);
                setDaySelected(day);
                setShowEventModal(true);
              }}
            >
              {evt.allDay
                ? evt.title
                : `${dayjs(evt.dayStart).format("h:mma")} - ${evt.title}`}
              {evt.title ? "" : "(No title)"}
            </div>
            {evtOutWeek(evt, iRow + 1, iColumn, day, index, eventSize - 7)}
          </>
        );
      } else {
        return null;
      }
    }
  }

  // CHECK IF EVENT START OUT MATRIX AND SHOW IT
  function isStartOutMatrix(evt, iRow, iColumn, day, index) {
    var eventSize =
      dayjs(evt.dayEnd).diff(dayjs(evt.dayStart), "day") -
      dayjs(dayMatrix.at(0)).diff(dayjs(evt.dayStart), "day") -
      6;

    if (eventsStartOutMatrix.some((e) => e.id === evt.id)) {
      return (
        <>
          <div
            key={evt.id}
            className={`${styles.eventOut} ${evt.calendar.color} ${
              index === 1 ? styles.eventOne : styles.eventTwo
            }`}
            style={{
              gridRow: iRow + 2,
              gridColumn: `${iColumn + 1} / span ${
                dayjs(evt.dayEnd).diff(dayjs(dayMatrix.at(0)), "day") + 1
              }`,
            }}
            onClick={() => {
              setSelectedEvent(evt);
              setDaySelected(day);
              setShowEventModal(true);
            }}
          >
            {evt.allDay
              ? evt.title
              : `${dayjs(evt.dayStart).format("h:mma")} - ${evt.title}`}
            {evt.title ? "" : "(No title)"}
          </div>
          {evtOutWeek(evt, iRow, iColumn, day, index, eventSize)}
        </>
      );
    } else {
      return null;
    }
  }

  const CountWrapper = ({ children }) =>
    Children.map(children, (child, index) =>
      cloneElement(child, {
        index: index + 1,
        total: Children.count(children),
      })
    );

  const Event = ({ index, total, iRow, iColumn, day }) => {
    var event1 = eventMatrix[iRow][iColumn][0];
    var event2 = eventMatrix[iRow][iColumn][1];
    var event3 = eventMatrix[iRow][iColumn][2];

    if (index === 1 && event1) {
      // check if isInWeek
      if (eventsInWeek.some((e) => e.id === event1.id)) {
        return isInWeek(event1, iRow, iColumn, day, index);
      }
      // check if isOutWeek
      else if (eventsOutWeek.some((e) => e.id === event1.id)) {
        return isOutWeek(event1, iRow, iColumn, day, index);
      }
      // check if isEndOutMatrix
      else if (eventsEndOutMatrix.some((e) => e.id === event1.id)) {
        return isEndOutMatrix(event1, iRow, iColumn, day, index);
      }
      // check if isStartOutMatrix
      else if (eventsStartOutMatrix.some((e) => e.id === event1.id)) {
        return isStartOutMatrix(event1, iRow, iColumn, day, index);
      } else {
        return null;
      }
    }
    if (index === 2 && event2) {
      // check if isInWeek
      if (eventsInWeek.some((e) => e.id === event2.id)) {
        return isInWeek(event2, iRow, iColumn, day, index);
      }
      // check if isOutWeek
      else if (eventsOutWeek.some((e) => e.id === event2.id)) {
        return isOutWeek(event2, iRow, iColumn, day, index);
      }
      // check if isOutMatrix
      else if (eventsEndOutMatrix.some((e) => e.id === event2.id)) {
        return isEndOutMatrix(event2, iRow, iColumn, day, index);
      }
      // check if isStartOutMatrix
      else if (eventsStartOutMatrix.some((e) => e.id === event2.id)) {
        return isStartOutMatrix(event2, iRow, iColumn, day, index);
      } else {
        return null;
      }
    }
    if (index === 3 && Array.isArray(event3)) {
      return (
        <>
          <div
            style={{
              gridRow: iRow + 2,
              gridColumn: `${iColumn + 1}`,
            }}
            className={`${styles.event} ${styles.eventMore}`}
            onClick={(e) => {
              setMouseX(e.screenX);
              setMouseY(e.screenY);
              setDaySelected(day);
              setShowEventsListModal(day);
              setEventList(event3);
            }}
          >
            {event3.length} more
          </div>
        </>
      );
    } else return null;
  };

  return (
    <div className={styles.month}>
      <span className={styles.day_name}>sun</span>
      <span className={styles.day_name}>mon</span>
      <span className={styles.day_name}>tue</span>
      <span className={styles.day_name}>wed</span>
      <span className={styles.day_name}>thu</span>
      <span className={styles.day_name}>fri</span>
      <span className={styles.day_name}>sat</span>
      {month.map((row, i) => (
        <React.Fragment key={i}>
          {row.map((day, idx) => (
            <Day day={day} key={idx} rowIdx={i} />
          ))}
        </React.Fragment>
      ))}
      {month.map((row, iRow) => (
        <React.Fragment key={iRow}>
          {row.map((day, iColumn) => (
            <React.Fragment key={iColumn}>
              <CountWrapper>
                <Event iRow={iRow} iColumn={iColumn} day={day} />
                <Event iRow={iRow} iColumn={iColumn} day={day} />
                <Event iRow={iRow} iColumn={iColumn} day={day} />
              </CountWrapper>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
      {showEventsListModal && (
        <OutsideAlerter setMenuButton={setShowEventsListModal}>
          <div
            className={`${styles.eventsModal}`}
            style={{ left: `${mouseX - 80}px`, top: `${mouseY - 100}px` }}
          >
            <div>
              <div className={styles.eventModalDay}>
                {dayjs(showEventsListModal).format("ddd")}
              </div>
              <div className={styles.eventModalDayNumber}>
                {dayjs(showEventsListModal).format("DD")}
              </div>
              {eventList.map((evt) => (
                <div
                  key={evt.id}
                  className={`${styles.eventList} ${evt.calendar.color}`}
                  onClick={() => {
                    setShowEventsListModal(false);
                    setSelectedEvent(evt);
                    setDaySelected(showEventsListModal);
                    setShowEventModal(true);
                  }}
                >
                  {evt.allDay
                    ? evt.title
                    : `${dayjs(evt.dayStart).format("h:mma")} - ${evt.title}`}
                </div>
              ))}
            </div>
          </div>
        </OutsideAlerter>
      )}
    </div>
  );
}
