import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./StartFlashcard.module.css";
import { FlashcardContext } from "../../Context/FlashcardContext";
import { UserContext } from "../../Context/UserContext";
import { getDeckFirebase } from "../../Utils/firebaseFlashcard";
import Head from "../../Utils/Head";
import { setFavoritesFirebase } from "../../Utils/firebaseUser";

export const StartFlashcard = () => {
  const {
    user,
    userData,
    savedFavorites,
    favoritesFlashcardsId,
    setFavoritesFlashcardsId,
    favoritesFlashcardsTitle,
    setFavoritesFlashcardsTitle,
  } = React.useContext(UserContext);
  const {
    dispatchMyDecks,
    flashcardsReview,
    dispatchFlashcardsReview,
    flashcardsReviewArray,
  } = React.useContext(FlashcardContext);
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [deck, setDeck] = useState("");
  const [cards, setCards] = useState([]);
  const [flip, setFlip] = useState(false);
  const [indexCard, setIndexCard] = useState(0);
  const [endDeck, setEndDeck] = useState(false);
  const [cardsRev, setCardsRev] = useState([]);
  const [enableNewDeck, setEnableNewDeck] = useState(true);
  const [favorite, setFavorite] = useState(false);

  useEffect(() => {
    getDeckFirebase(params.id).then(function (result) {
      if (favoritesFlashcardsId.indexOf(result.id) >= 0) {
        setFavorite(true);
      }
      if (
        result &&
        flashcardsReviewArray.length > 4 &&
        flashcardsReviewArray.indexOf(result.id) < 0
      ) {
        setEnableNewDeck(false);
      }
      result ? setDeck(result) : setDeck(false);
      result && setCards(result.cards);
      setLoading(false);
    });
  }, [user, params.id, flashcardsReviewArray, favoritesFlashcardsId]);

  function handleNextCard() {
    if (cards.length > indexCard + 1) {
      setIndexCard(indexCard + 1);
      setFlip(false);
    } else {
      setEndDeck(true);
    }
  }

  function handleReviewCard() {
    let newCardReview = {
      id: cards[indexCard].id,
      deckTitle: deck.title,
      front: cards[indexCard].front,
      back: cards[indexCard].back,
    };

    setCardsRev([...cardsRev, newCardReview]);
    if (cards.length > indexCard + 1) {
      setIndexCard(indexCard + 1);
      setFlip(false);
    } else {
      setEndDeck(true);
    }
  }

  function updateFlashcardsReview() {
    let updatedFlashcardsReview = flashcardsReview;
    cardsRev[0]
      ? (updatedFlashcardsReview[deck.id] = cardsRev)
      : delete updatedFlashcardsReview[deck.id];

    dispatchFlashcardsReview({
      type: "update",
      user: user,
      payload: updatedFlashcardsReview,
    });
    navigate("/");
  }

  function handleRatingCard(newRating) {
    if (cards.length > indexCard) {
      cards[indexCard].rating = newRating;
      const today = new Date();
      cards[indexCard].date = today.getTime();

      dispatchMyDecks({
        type: "updateCards",
        user: user,
        deck: deck,
        cards: cards,
      });
      if (cards.length > indexCard + 1) {
        setIndexCard(indexCard + 1);
        setFlip(false);
      } else {
        setEndDeck(true);
      }
    }
  }

  const setFavoriteFirebase = () => {
    let updatedfavorites = savedFavorites;
    updatedfavorites.flashcardsId = favoritesFlashcardsId;
    updatedfavorites.flashcardsTitle = favoritesFlashcardsTitle;
    if (favorite) {
      updatedfavorites.flashcardsId = updatedfavorites.flashcardsId.filter(
        (fav) => fav !== deck.id
      );
      updatedfavorites.flashcardsTitle =
        updatedfavorites.flashcardsTitle.filter((fav) => fav !== deck.title);
      setFavoritesFlashcardsId(updatedfavorites.flashcardsId);
      setFavoritesFlashcardsTitle(updatedfavorites.flashcardsTitle);
      setFavoritesFirebase(user, updatedfavorites);
      setFavorite(!favorite);
    } else {
      if (updatedfavorites.flashcardsId.length < 5) {
        updatedfavorites.flashcardsId.push(deck.id);
        updatedfavorites.flashcardsTitle.push(deck.title);
        setFavoritesFlashcardsId(updatedfavorites.flashcardsId);
        setFavoritesFlashcardsTitle(updatedfavorites.flashcardsTitle);
        setFavoritesFirebase(user, updatedfavorites);
        setFavorite(!favorite);
      } else {
        alert("Maximum capacity of favorites (5)");
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  } else if (deck === false) {
    return (
      <>
        <h2>Flashcard not found</h2>
      </>
    );
  } else {
    return (
      <>
        <Head
          title={`Start ${deck.title}`}
          description={`Start ${deck.title} - Mairimed`}
        />
        <div className={css.wrapper}>
          <div className={css.body}>
            <h2>{deck.title}</h2>
            <p>{deck.description}</p>
            <div className={css.options}>
              {user.uid === deck.userId && (
                <>
                  <i
                    className="material-icons materialIcon"
                    onClick={() => navigate(`/study/editflashcard/${deck.id}`)}
                  >
                    edit
                  </i>
                </>
              )}
              {favorite ? (
                <i
                  className="material-icons materialIcon"
                  onClick={() => {
                    setFavoriteFirebase();
                  }}
                >
                  favorite
                </i>
              ) : (
                <i
                  className="material-icons materialIcon"
                  onClick={() => {
                    setFavoriteFirebase();
                  }}
                >
                  favorite_border
                </i>
              )}
            </div>
            <div className={css.cardContainer}>
              {!endDeck ? (
                <>
                  <div
                    className={`${css.card} ${flip ? css.flip : ""}`}
                    onClick={() => setFlip(!flip)}
                  >
                    <div className={css.front}>{cards[indexCard].front}</div>
                    <div className={css.back}>{cards[indexCard].back}</div>
                  </div>
                  <div className={css.control}>
                    <p>
                      {indexCard + 1} / {cards.length}
                    </p>
                  </div>
                  {flip ? (
                    <div className={css.options}>
                      {userData.member ? (
                        <>
                          <button
                            className="redButton"
                            onClick={() => handleRatingCard("extreme")}
                          >
                            Extreme
                          </button>
                          {/* 25min */}
                          <button
                            className="orangeButton"
                            onClick={() => handleRatingCard("hard")}
                          >
                            Hard
                          </button>
                          {/* 1 day */}
                          <button
                            className="greenButton"
                            onClick={() => handleRatingCard("good")}
                          >
                            Good
                          </button>
                          {/* 3 days */}
                          <button
                            className="blueButton"
                            onClick={() => handleRatingCard("easy")}
                          >
                            Easy
                          </button>
                          {/* 4 days */}
                        </>
                      ) : (
                        <>
                          <button
                            className="deleteButton"
                            onClick={() => handleReviewCard()}
                          >
                            Review
                          </button>
                          <button
                            className="createNewButton"
                            onClick={() => handleNextCard()}
                          >
                            Next
                          </button>
                        </>
                      )}
                    </div>
                  ) : (
                    <div style={{ marginTop: "2.8rem" }}></div>
                  )}
                </>
              ) : (
                <>
                  <div className={`${css.card}`}>
                    <div className={css.front}>Deck Ended</div>
                  </div>
                  {!enableNewDeck ? (
                    <p>You already have 5 flashcards decks to review</p>
                  ) : (
                    <button
                      className="saveButton"
                      onClick={() => updateFlashcardsReview()}
                    >
                      Save results
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
};
