import {
  doc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

export const getTagZettelsFirebase = async (tag) => {
  const resultZettels = [];
  const docRef = collection(db, "zettels");
  const q1 = query(docRef, where("tag1.title", "==", tag));
  const q2 = query(docRef, where("tag2.title", "==", tag));
  const q3 = query(docRef, where("tag3.title", "==", tag));
  const querySnap1 = await getDocs(q1);
  const querySnap2 = await getDocs(q2);
  const querySnap3 = await getDocs(q3);
  if (querySnap1) {
    console.log(`zettels tag1=${tag} loaded`);
    let docs = querySnap1.docs.map((doc) => ({ ...doc.data() }));
    docs.length > 0 && docs.forEach((doc) => resultZettels.push(doc));
  }
  if (querySnap2) {
    console.log(`zettels tag2=${tag} loaded`);
    let docs = querySnap2.docs.map((doc) => ({ ...doc.data() }));
    docs.length > 0 && docs.forEach((doc) => resultZettels.push(doc));
  }
  if (querySnap3) {
    console.log(`zettels tag3=${tag} loaded`);
    let docs = querySnap3.docs.map((doc) => ({ ...doc.data() }));
    docs.length > 0 && docs.forEach((doc) => resultZettels.push(doc));
  } else {
    console.log("No such document");
  }
  return resultZettels;
};

export const getZettelsSnippetsFirebase = async (user) => {
  const docRef = doc(db, "users", user.uid, "snippets", "zettelsSnippets");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log("zettelsSnippets loaded");
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const getUserZettelsFirebase = async (user) => {
  const docRef = collection(db, "zettels");
  const q = query(docRef, where("userId", "==", user.uid));
  const querySnap = await getDocs(q);
  if (querySnap) {
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const getZettelFirebase = async (id) => {
  const docRef = doc(db, "zettels", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`zettel ${id} loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const createZettelFirebase = async (user, zettel, zettelsSnippets) => {
  await setDoc(doc(db, "zettels", zettel.id), {
    id: zettel.id,
    userId: zettel.userId,
    userName: zettel.userName,
    title: zettel.title,
    reference: zettel.reference,
    text: zettel.text,
    tag1: zettel.tag1,
    tag2: zettel.tag2,
    tag3: zettel.tag3,
    next: zettel.next,
    prev: zettel.prev,
    more: zettel.more,
  });

  await setDoc(doc(db, "users", user.uid, "snippets", "zettelsSnippets"), {
    zettelsSnippets,
  });

  console.log(`Zettel ${zettel.title} created`);
};

export const updateZettelFirebase = async (user, zettel, zettelsSnippets) => {
  await updateDoc(doc(db, "zettels", zettel.id), {
    id: zettel.id,
    userId: zettel.userId,
    userName: zettel.userName,
    title: zettel.title,
    reference: zettel.reference,
    text: zettel.text,
    tag1: zettel.tag1,
    tag2: zettel.tag2,
    tag3: zettel.tag3,
    next: zettel.next,
    prev: zettel.prev,
    more: zettel.more,
  });

  await setDoc(doc(db, "users", user.uid, "snippets", "zettelsSnippets"), {
    zettelsSnippets,
  });

  console.log(`Zettel ${zettel.title} updated`);
};

export const updateZettelsReview = async (user, zettelsReview) => {
  await setDoc(
    doc(db, "users", user.uid, "analytics", "zettelsReview"),
    zettelsReview
  );
  console.log(`zettelsReview updated`);
};

export const deleteZettelFirebase = async (user, zettel, zettelsSnippets) => {
  await deleteDoc(doc(db, "zettels", zettel.id));
  await setDoc(doc(db, "users", user.uid, "snippets", "zettelsSnippets"), {
    zettelsSnippets,
  });

  console.log(`Zettel ${zettel.title} deleted`);
};
