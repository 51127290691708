import {
  doc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

export const getQuizzesFirebase = async () => {
  const docRef = collection(db, "quizzes");
  const docSnap = await getDocs(docRef);
  if (docSnap) {
    console.log("all quizzes loades");
    return docSnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const getTagQuizzesFirebase = async (tag) => {
  const resultQuizzes = [];
  const docRef = collection(db, "quizzes");
  const q1 = query(
    docRef,
    where("tag1.title", "==", tag),
    where("hide", "==", false)
  );
  const q2 = query(
    docRef,
    where("tag2.title", "==", tag),
    where("hide", "==", false)
  );
  const q3 = query(
    docRef,
    where("tag3.title", "==", tag),
    where("hide", "==", false)
  );
  const querySnap1 = await getDocs(q1);
  const querySnap2 = await getDocs(q2);
  const querySnap3 = await getDocs(q3);
  if (querySnap1) {
    console.log(`quizzes tag1=${tag} loaded`);
    let docs = querySnap1.docs.map((doc) => ({ ...doc.data() }));
    docs.length > 0 && docs.forEach((doc) => resultQuizzes.push(doc));
  }
  if (querySnap2) {
    console.log(`quizzes tag2=${tag} loaded`);
    let docs = querySnap2.docs.map((doc) => ({ ...doc.data() }));
    docs.length > 0 && docs.forEach((doc) => resultQuizzes.push(doc));
  }
  if (querySnap3) {
    console.log(`quizzes tag3=${tag} loaded`);
    let docs = querySnap3.docs.map((doc) => ({ ...doc.data() }));
    docs.length > 0 && docs.forEach((doc) => resultQuizzes.push(doc));
  } else {
    console.log("No such document");
  }
  return resultQuizzes;
};

export const getQuizzesSnippetsFirebase = async (user) => {
  const docRef = doc(db, "users", user.uid, "snippets", "quizzesSnippets");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log("quizzesSnippets loaded");
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const getQuizzesScoresFirebase = async (user) => {
  const docRef = doc(db, "users", user.uid, "analytics", "quizzesScores");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log("quizzesScores loaded");
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const getUserQuizzesFirebase = async (user) => {
  const docRef = collection(db, "quizzes");
  const q = query(docRef, where("userId", "==", user.uid));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log("user quizzes loaded");
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const getQuizFirebase = async (id) => {
  const docRef = doc(db, "quizzes", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`quiz ${id} loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const createQuizFirebase = async (user, quiz, quizzesSnippets) => {
  await setDoc(doc(db, "quizzes", quiz.id), {
    id: quiz.id,
    hide: quiz.hide,
    userId: quiz.userId,
    userName: quiz.userName,
    title: quiz.title,
    description: quiz.description,
    questions: quiz.questions,
    tag1: quiz.tag1,
    tag2: quiz.tag2,
    tag3: quiz.tag3,
  });
  await setDoc(doc(db, "users", user.uid, "snippets", "quizzesSnippets"), {
    quizzesSnippets,
  });
  console.log(`Quiz ${quiz.title} created`);
};

export const updateQuizFirebase = async (user, quiz, quizzesSnippets) => {
  await updateDoc(doc(db, "quizzes", quiz.id), {
    id: quiz.id,
    hide: quiz.hide,
    userId: quiz.userId,
    userName: quiz.userName,
    title: quiz.title,
    description: quiz.description,
    questions: quiz.questions,
    tag1: quiz.tag1,
    tag2: quiz.tag2,
    tag3: quiz.tag3,
  });
  await setDoc(doc(db, "users", user.uid, "snippets", "quizzesSnippets"), {
    quizzesSnippets,
  });
  console.log(`quiz ${quiz.title} updated`);
};

export const updateQuizzesScores = async (user, quizzesScores) => {
  await setDoc(doc(db, "users", user.uid, "analytics", "quizzesScores"), {
    quizzesScores,
  });
  console.log(`quizzes scores updated`);
};

export const deleteQuizFirebase = async (user, quiz, quizzesSnippets) => {
  await deleteDoc(doc(db, "quizzes", quiz.id));
  await setDoc(doc(db, "users", user.uid, "snippets", "quizzesSnippets"), {
    quizzesSnippets,
  });

  console.log("quiz deleted");
};
