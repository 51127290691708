import {
  doc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

// USER

export const updateUserFirebase = async (user, displayName, email) => {
  console.log("user updated");
  await updateDoc(doc(db, "users", user.uid), {
    displayName,
    email,
  });
};

// TAGS

export const getTagsFirebase = async () => {
  const docRef = collection(db, "tags");
  const docSnap = await getDocs(docRef);
  if (docSnap) {
    console.log("all tags loaded");
    return docSnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const createTagFirebase = async (tag) => {
  await setDoc(doc(db, "tags", tag.id), {
    id: tag.id,
    title: tag.title,
  });
  console.log("tag created");
};

export const updateTagFirebase = async (tag) => {
  await updateDoc(doc(db, "tags", tag.id), {
    id: tag.id,
    title: tag.title,
  });
  console.log("tag updated");
};

export const addTagDeckFirebase = async (tag, deckSnippet) => {
  await setDoc(doc(db, "tagedDecks"), {
    tagId: tag.id,
    deckId: deckSnippet.id,
    title: deckSnippet.title,
    tags: deckSnippet.tags,
    cards: deckSnippet.cards.length,
  });
};

export const delTagDeckFirebase = async (tag, deck) => {
  await deleteDoc(doc(db, "tagedDecks", tag.id), {});
};

// FAVORITES

export const getFavoritesFirebase = async (user) => {
  const docRef = doc(db, "users", user.uid, "favorites", "favorites");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`favorites loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const setFavoritesFirebase = async (user, favorites) => {
  await setDoc(doc(db, "users", user.uid, "favorites", "favorites"), {
    favorites,
  });
  console.log("favorites updated");
};
