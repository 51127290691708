import React from "react";

import { getMonth } from "./getDate";
import Head from "../../Utils/Head";
import { CalendarContext } from "../../Context/CalendarContext";
import EventModal from "./EventModal";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import MonthView from "./MonthView";

const Calendar = () => {
  const { smallCalendarMonth, showEventModal, setShowEventModal, view } =
    React.useContext(CalendarContext);
  const [currentMonth, setCurrentMonth] = React.useState(getMonth());

  React.useEffect(() => {
    setCurrentMonth(getMonth(smallCalendarMonth));
  }, [smallCalendarMonth]);

  return (
    <>
      <Head title="Calendar" description="Calendar - Mairimed" />
      {showEventModal && (
        <OutsideAlerter setMenuButton={setShowEventModal}>
          <EventModal />
        </OutsideAlerter>
      )}

      {view === "month" && <MonthView month={currentMonth} />}
    </>
  );
};

export default Calendar;
