import React from "react";

import styles from "./Details.module.css";
import { mapColors } from "../../Utils/listColors";

function Details({ setColor, color, ...props }) {
  const changeColor = (list, item, color) => {
    list.forEach((i, idx) => {
      if (i.id === item.id) {
        list[idx].color = color;
      }
      if (item.level === 1 && i.levelZeroId === item.levelZeroId) {
        list[idx].color = color;
      }
      if (item.level === 2 && i.parentId === item.id) {
        list[idx].color = color;
      }
    });
    props.setChanged(true);
  };

  function deleteTag(tag) {
    let filteredTags = props.tags.filter((t) => t.id !== tag.id);
    props.setTags(filteredTags);
    props.setChanged(true);
  }

  return (
    <div className={styles.container}>
      <form onSubmit={(e) => e.preventDefault()}>
        <fieldset>
          <label>Saved: </label>
          {props.changed ? (
            <span className={styles.save} onClick={props.saveMindmap}>
              save
            </span>
          ) : (
            <span>all saved</span>
          )}
        </fieldset>
        <fieldset>
          {props.userData.staff && (
            <button
              className={props.hide ? "defaultActiveButton" : "defaultButton"}
              onClick={(e) => {
                e.preventDefault();
                props.setHide(!props.hide);
                props.setChanged(true);
              }}
            >
              Hide
            </button>
          )}
        </fieldset>
        <fieldset>
          <label>Name</label>
          <input
            className={styles.input}
            onChange={props.onChangeName}
            value={props.itemSelected.title}
            name={"name"}
            maxLength="25"
            type="text"
            autoComplete="off"
          />
        </fieldset>
        <fieldset>
          <label>Icon </label>
          <button
            className="defaultButton"
            onClick={() => props.setShowIconModal(true)}
          >
            Choose icon
          </button>
          <i className="material-icons">{props.itemSelected.icon}</i>
        </fieldset>
        <fieldset>
          <label>Color</label>
          <div>
            {mapColors.map((col, i) => (
              <span
                key={i}
                onClick={() => {
                  setColor(col);
                  changeColor(props.list, props.itemSelected, col);
                }}
                className={`${col} ${styles.roundIcon}`}
              >
                {color === col && (
                  <span className={`material-icons ${styles.materialCheck}`}>
                    check
                  </span>
                )}
              </span>
            ))}
          </div>
        </fieldset>
        <fieldset>
          <label>Comment</label>
          <textarea
            rows="5"
            onChange={props.onChangeComment}
            onClick={(e) => e.preventDefault()}
            value={props.comment}
            className={styles.textarea}
            maxLength="150"
          ></textarea>
        </fieldset>
        <fieldset>
          <label>Reference</label>
          <input
            className={styles.input}
            onChange={props.onChangeRef}
            value={props.reference}
            name={"name"}
            maxLength="25"
            type="text"
            autoComplete="off"
          />
        </fieldset>
        <fieldset>
          <label>Tags </label>
          <button
            className="defaultButton"
            disabled={props.tags.length > 2 ? true : false}
            onClick={() => props.setShowTagsModal(true)}
          >
            Choose tag
          </button>
          <div className={styles.tagsContainer}>
            {props.tags.map((tag, i) => (
              <div
                className={styles.tag}
                key={i}
                onClick={() => deleteTag(tag)}
              >
                <span>{tag.title}</span>
                <i className={`material-icons ${styles.tagClose}`}>clear</i>
              </div>
            ))}
            {/* {props.tags.tag1.title && (
              <div className={styles.tag} onClick={() => deleteTag(props.tag1)}>
                <span>{props.tags.tag1.title}</span>
                <i className={`material-icons ${styles.tagClose}`}>clear</i>
              </div>
            )}
            {props.tags.tag2.title && (
              <div className={styles.tag} onClick={() => deleteTag(props.tag2)}>
                <span>{props.tags.tag2.title}</span>
                <i className={`material-icons ${styles.tagClose}`}>clear</i>
              </div>
            )}
            {props.tags.tag3.title && (
              <div className={styles.tag} onClick={() => deleteTag(props.tag3)}>
                <span>{props.tags.tag3.title}</span>
                <i className={`material-icons ${styles.tagClose}`}>clear</i>
              </div>
            )} */}
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default Details;
