import React from "react";

import { UserContext } from "./UserContext";
import { getZettelsSnippetsFirebase } from "../Utils/firebaseZettel";
import {
  savedZettelsReducer,
  savedZettelsSnippetsReducer,
} from "./zettelsReducer";

export const ZettelContext = React.createContext();

export const ZettelStorage = ({ children }) => {
  const { user } = React.useContext(UserContext);
  const [savedZettels, dispatchZettels] = React.useReducer(
    savedZettelsReducer,
    []
  );
  const [savedZettelsSnippets, dispatchZettelsSnippets] = React.useReducer(
    savedZettelsSnippetsReducer,
    []
  );

  // POPULATE ZETTELS
  React.useEffect(() => {
    if (user) {
      if (savedZettelsSnippets.length === 0) {
        getZettelsSnippetsFirebase(user).then(function (result) {
          dispatchZettelsSnippets({
            type: "init",
            payload: result ? result.zettelsSnippets : [],
          });
        });
      }
    }
  }, [user, savedZettelsSnippets.length]);

  return (
    <ZettelContext.Provider
      value={{
        savedZettels,
        dispatchZettels,
        savedZettelsSnippets,
        dispatchZettelsSnippets,
      }}
    >
      {children}
    </ZettelContext.Provider>
  );
};
