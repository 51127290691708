import React from "react";

import styles from "./SmallCalendar.module.css";
import { getMonth } from "./getDate";
import dayjs from "dayjs";
import { CalendarContext } from "../../Context/CalendarContext";

export default function SmallCalendarModal({
  setSmallCalendarModal,
  dayModalStart,
  setDayModal,
  dayModal,
  setEditRecur,
  setUpdateDay,
  selectedEvent,
  checkRecurEvents,
}) {
  const { smallCalendarMonth, setSmallCalendarMonth } =
    React.useContext(CalendarContext);
  const [currentMonth, setCurrentMonth] = React.useState(getMonth());

  React.useEffect(() => {
    setCurrentMonth(getMonth(smallCalendarMonth));
  }, [smallCalendarMonth]);

  function handlePrevMonth(e) {
    e.preventDefault();
    setSmallCalendarMonth(smallCalendarMonth - 1);
  }

  function handleNextMonth(e) {
    e.preventDefault();
    setSmallCalendarMonth(smallCalendarMonth + 1);
  }

  function handleReset(e) {
    e.preventDefault();
    setSmallCalendarMonth(dayjs().month());
  }

  function handleDayClick(day) {
    selectedEvent &&
      checkRecurEvents() &&
      dayModal !== day &&
      setEditRecur(true);
    selectedEvent &&
      checkRecurEvents() &&
      dayModal !== day &&
      setUpdateDay(true);
    setDayModal(day);
    setSmallCalendarModal(false);
  }

  function getDayClass(day) {
    const format = "DD-MM-YY";
    const nowDay = dayjs().format(format);
    const selDay = dayjs(dayModal).format(format);
    const currDay = day.format(format);
    if (nowDay === currDay) {
      return styles.currentDay;
    }
    if (selDay === currDay) {
      return styles.selectedDay;
    } else {
      return "";
    }
  }

  return (
    <div className={styles.smallcalendar}>
      <header className={styles.header}>
        <h3>
          {dayjs(new Date(dayjs().year(), smallCalendarMonth)).format(
            "MMMM YYYY"
          )}
        </h3>
        <div className={styles.arrowButtonDiv}>
          <button className={styles.arrowButton}>
            <span
              className={`material-icons ${styles.materialIcon}`}
              onClick={handlePrevMonth}
            >
              chevron_left
            </span>
          </button>
          <button onClick={handleReset} className={styles.button}>
            T
          </button>
          <button className={styles.arrowButton}>
            <span
              className={`material-icons ${styles.materialIcon}`}
              onClick={handleNextMonth}
            >
              chevron_right
            </span>
          </button>
        </div>
      </header>
      <div className={styles.smallCalendarCells}>
        {currentMonth[0].map((day, i) => (
          <span key={i} className={styles.cell}>
            {day.format(`dd`).charAt(0)}
          </span>
        ))}
        {currentMonth.map((row, i) => (
          <React.Fragment key={i}>
            {row.map((day, idx) => (
              <button
                key={idx}
                className={`${styles.cellButton} ${getDayClass(day)}`}
                onClick={() => handleDayClick(day)}
              >
                <span className={styles.cellText}>{day.format(`D`)}</span>
              </button>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
