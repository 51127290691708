import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./NewZettel.module.css";
import { UserContext } from "../../Context/UserContext";
import { ZettelContext } from "../../Context/ZettelContext";
import Head from "../../Utils/Head";
import { newId } from "../../Utils/newId";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { Search } from "../Forms/Search";
import {
  getUserZettelsFirebase,
  getZettelFirebase,
} from "../../Utils/firebaseZettel";
import { createTagFirebase } from "../../Utils/firebaseUser";

export const EditZettel = () => {
  const { user, userData, savedTags, setSavedTags } =
    React.useContext(UserContext);
  const { dispatchZettels, savedZettelsSnippets, dispatchZettelsSnippets } =
    React.useContext(ZettelContext);
  const params = useParams();
  const navigate = useNavigate();
  const [savedZettels, setSavedZettels] = useState("");
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [reference, setReference] = useState("");
  const [text, setText] = useState("");
  const [tags, setTags] = useState([]);
  const [next, setNext] = useState("");
  const [prev, setPrev] = useState("");
  const [more, setMore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showMoreModalSelect, setShowMoreModalSelect] = useState(false);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [showPrevModal, setShowPrevModal] = useState(false);
  const [showNextModal, setShowNextModal] = useState(false);
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");

  useEffect(() => {
    getUserZettelsFirebase(user).then(function (result) {
      result ? setSavedZettels(result) : setSavedZettels("");
    });
  }, [user]);

  useEffect(() => {
    getZettelFirebase(params.id).then(function (result) {
      setId(result.id);
      setTitle(result.title);
      setReference(result.reference);
      setText(result.text);
      const tagsResult = [];
      result.tag1 !== "" && tagsResult.push(result.tag1);
      result.tag2 !== "" && tagsResult.push(result.tag2);
      result.tag3 !== "" && tagsResult.push(result.tag3);
      setTags(tagsResult);
      setPrev(result.prev);
      setNext(result.next);
      setMore(result.more);
      user.uid === result.userId && setLoading(false);
    });
  }, [user.uid, params.id]);

  const filterTags = (tagsFil, query) => {
    if (!query) {
      return [];
    }
    return tagsFil.filter((tag) => {
      const tagName = tag.title.toLowerCase();
      return tagName.includes(query.toLowerCase());
    });
  };

  const filteredTags = filterTags(savedTags, searchQuery);

  const filterZettels = (zettelsFil, query) => {
    if (!query) {
      return savedZettels;
    }
    return zettelsFil.filter((zet) => {
      const zettelTitle = zet.title.toLowerCase();
      // RETIRAR PREV E NEXT JA SELECIONADOS
      return zettelTitle.includes(query);
    });
  };

  const filteredZettels = filterZettels(savedZettels, searchQuery);

  function handleSubmit() {
    const updatedZettel = {
      id,
      userId: user.uid,
      userName: user.displayName,
      title,
      reference,
      text,
      tag1: tags[0] ? tags[0] : "",
      tag2: tags[1] ? tags[1] : "",
      tag3: tags[2] ? tags[2] : "",
      next,
      prev,
      more,
    };
    const updatedZettelSnippet = {
      id: updatedZettel.id,
      userName: updatedZettel.userName,
      title: updatedZettel.title,
      tag1: tags[0] ? tags[0].title : "",
      tag2: tags[1] ? tags[1].title : "",
      tag3: tags[2] ? tags[2].title : "",
      zettels:
        1 +
        updatedZettel.next.length +
        updatedZettel.prev.length +
        updatedZettel.more.length,
    };

    const zettelsSnippets = savedZettelsSnippets.map((snippet) =>
      snippet.id === updatedZettelSnippet.id ? updatedZettelSnippet : snippet
    );

    dispatchZettelsSnippets({
      type: "update",
      payload: updatedZettelSnippet,
    });

    dispatchZettels({
      type: "update",
      zettel: updatedZettel,
      user: user,
      zettelsSnippets: zettelsSnippets,
    });
    navigate("/study/myzettels");
  }

  function handleNewTag() {
    const newTag = {
      id: newId(),
      title: searchQuery.toLowerCase(),
    };
    setSavedTags([...savedTags, newTag]);
    createTagFirebase(newTag);
    addTag(newTag);
  }

  function addTag(tag) {
    if (tags.indexOf(tag) < 0) {
      setTags([...tags, tag]);
    }
    setSearchQuery("");
    setShowTagsModal(false);
  }

  function deleteTag(tag) {
    let filteredTags = tags.filter((t) => t.id !== tag.id);
    setTags(filteredTags);
  }

  function addPrev(zettel) {
    if (prev !== zettel) {
      setPrev(zettel);
    }
    setShowPrevModal(false);
  }

  function addNext(zettel) {
    if (next !== zettel) {
      setNext(zettel);
    }
    setShowNextModal(false);
  }

  function addMore(zettel) {
    if (!more.map((zet) => zet.id).includes(zettel.id)) {
      const arrayMore = more;
      arrayMore.push(zettel);
      setMore(arrayMore);
    }
    setShowMoreModalSelect(false);
  }

  function delMore(zettel) {
    const delAtrrayMore = more.filter((zet) => zet.id !== zettel.id);
    setMore(delAtrrayMore);
    setShowMoreModal(false);
  }

  function handleNavigate(zettel) {
    setShowMoreModal(false);
    return navigate(`/study/zettel/${zettel.id}`);
  }

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <Head
          title={`Edit ${title}`}
          description={`${title} - Edit Mairimed`}
        />
        <div className={css.card}>
          <form className="form">
            <div className={css.options}>
              {userData.staff && (
                <button
                  className="defaultButton"
                  onClick={(e) => e.preventDefault()}
                >
                  Hide
                </button>
              )}
              <input
                type="text"
                name="title"
                placeholder="Title"
                value={title}
                required
                onChange={(e) => setTitle(e.target.value)}
              />
              <div onClick={handleSubmit}>
                <i className="material-icons materialIcon">done</i>
              </div>
            </div>
            <div className={css.head}>
              <input
                type="text"
                name="reference"
                placeholder="Reference"
                value={reference}
                required
                onChange={(e) => setReference(e.target.value)}
              />
            </div>
            <textarea
              placeholder="Your main text here"
              name="zettel"
              rows="10"
              cols="70"
              maxLength="150"
              value={text}
              required
              onChange={(e) => setText(e.target.value)}
            />
          </form>
          <div className={css.footer}>
            <button
              className="defaultButton"
              disabled={tags.length > 2 ? true : false}
              onClick={(e) => {
                e.preventDefault();
                setShowTagsModal(true);
              }}
            >
              Tags
            </button>
            <div className={css.tagsContainer}>
              {tags.map((tag) => (
                <div
                  className={css.tag}
                  key={tag.id}
                  onClick={() => deleteTag(tag)}
                >
                  <span>{tag.title}</span>
                  <i className={`material-icons ${css.tagClose}`}>clear</i>
                </div>
              ))}
            </div>
            <button
              className="defaultButton"
              onClick={(e) => {
                e.preventDefault();
                setShowMoreModalSelect(true);
              }}
            >
              More
            </button>
          </div>
          <div className={css.footer}>
            <button
              className="defaultButton"
              onClick={(e) => {
                e.preventDefault();
                setShowPrevModal(true);
              }}
            >
              Prev
            </button>

            {prev ? (
              <div className={css.prev} onClick={() => handleNavigate(prev)}>
                <i className="material-icons materialIcon">chevron_left</i>
                <div>{prev.title}</div>
              </div>
            ) : (
              <div className={css.prev}></div>
            )}
            <div
              className={css.more}
              onClick={(e) => {
                e.preventDefault();
                setShowMoreModal(true);
              }}
            >
              {more.length + " more"}
            </div>
            {next ? (
              <div className={css.next} onClick={() => handleNavigate(next)}>
                <div>{next.title}</div>
                <i className="material-icons materialIcon">chevron_right</i>
              </div>
            ) : (
              <div className={css.next}></div>
            )}

            <button
              className="defaultButton"
              onClick={(e) => {
                e.preventDefault();
                setShowNextModal(true);
              }}
            >
              Next
            </button>
          </div>
        </div>
        {showTagsModal && (
          <OutsideAlerter setMenuButton={setShowTagsModal}>
            <div className={css.tagsModal}>
              <h3>Tags</h3>
              <Search
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              {filteredTags.map((tag) => (
                <div
                  className={css.tag}
                  key={tag.id}
                  onClick={() => {
                    addTag(tag);
                  }}
                >
                  {tag.title}
                </div>
              ))}
              <div className={css.modalFooter}></div>
              <button
                className="saveButton"
                onClick={() => handleNewTag()}
                disabled={
                  !filteredTags
                    .map((tag) => tag.title)
                    .includes(searchQuery.toLowerCase()) &&
                  searchQuery.length > 0
                    ? false
                    : true
                }
              >
                Save New Tag
              </button>
            </div>
          </OutsideAlerter>
        )}
        {showMoreModalSelect && (
          <OutsideAlerter setMenuButton={setShowMoreModalSelect}>
            <div className={css.tagsModal}>
              <h3>More</h3>
              <Search
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              {filteredZettels.map((zet) => (
                <div
                  className={css.tag}
                  key={zet.id}
                  onClick={() => addMore(zet)}
                >
                  {zet.title}
                </div>
              ))}
              <div className={css.modalFooter}></div>
            </div>
          </OutsideAlerter>
        )}
        {showMoreModal && (
          <OutsideAlerter setMenuButton={setShowMoreModal}>
            <div className={css.tagsModal}>
              <h3>More</h3>
              {more.map((zet) => (
                <div className={css.tagsContainer} key={zet.id}>
                  <div className={css.tag} onClick={() => handleNavigate(zet)}>
                    {zet.title}
                  </div>
                  <i
                    className="material-icons materialIcon"
                    onClick={() => delMore(zet)}
                  >
                    delete
                  </i>
                </div>
              ))}
            </div>
          </OutsideAlerter>
        )}
        {showPrevModal && (
          <OutsideAlerter setMenuButton={setShowPrevModal}>
            <div className={css.tagsModal}>
              <h3>Select Prev Zettel</h3>
              <Search
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              {filteredZettels.map((zet) => (
                <div
                  className={css.tag}
                  key={zet.id}
                  onClick={() => addPrev(zet)}
                >
                  {zet.title}
                </div>
              ))}
            </div>
          </OutsideAlerter>
        )}
        {showNextModal && (
          <OutsideAlerter setMenuButton={setShowNextModal}>
            <div className={css.tagsModal}>
              <h3>Select Next Zettel</h3>
              <Search
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              {filteredZettels.map((zet) => (
                <div
                  className={css.tag}
                  key={zet.id}
                  onClick={() => addNext(zet)}
                >
                  {zet.title}
                </div>
              ))}
            </div>
          </OutsideAlerter>
        )}
      </>
    );
  }
};
