import dayjs from "dayjs";
import React from "react";

import styles from "./TimeModal.module.css";

export default function TimeModal({
  dayModal,
  setDayModal,
  setTimeModal,
  setEditRecur,
  setUpdateTime,
  selectedEvent,
  checkRecurEvents,
}) {
  function handleTime(h, m) {
    const dayTime = new Date(dayModal);
    dayTime.setHours(h);
    dayTime.setMinutes(m);
    selectedEvent &&
      checkRecurEvents() &&
      dayjs(dayTime).format("hh:mm") !== dayjs(dayModal).format("hh:mm") &&
      setEditRecur(true);
    selectedEvent &&
      checkRecurEvents() &&
      dayjs(dayTime).format("hh:mm") !== dayjs(dayModal).format("hh:mm") &&
      setUpdateTime(true);
    setDayModal(dayTime.valueOf());
    setTimeModal(false);
  }

  return (
    <div className={styles.body}>
      <button
        onClick={() => {
          handleTime(0, 0);
        }}
      >
        12:00am
      </button>
      <button
        onClick={() => {
          handleTime(0, 15);
        }}
      >
        12:15am
      </button>
      <button
        onClick={() => {
          handleTime(0, 30);
        }}
      >
        12:30am
      </button>
      <button
        onClick={() => {
          handleTime(0, 45);
        }}
      >
        12:45am
      </button>
      <button
        onClick={() => {
          handleTime(1, 0);
        }}
      >
        1:00am
      </button>
      <button
        onClick={() => {
          handleTime(1, 15);
        }}
      >
        1:15am
      </button>
      <button
        onClick={() => {
          handleTime(1, 30);
        }}
      >
        1:30am
      </button>
      <button
        onClick={() => {
          handleTime(1, 45);
        }}
      >
        1:45am
      </button>
      <button
        onClick={() => {
          handleTime(2, 0);
        }}
      >
        2:00am
      </button>
      <button
        onClick={() => {
          handleTime(2, 15);
        }}
      >
        2:15am
      </button>
      <button
        onClick={() => {
          handleTime(2, 30);
        }}
      >
        2:30am
      </button>
      <button
        onClick={() => {
          handleTime(2, 45);
        }}
      >
        2:45am
      </button>
      <button
        onClick={() => {
          handleTime(3, 0);
        }}
      >
        3:00am
      </button>
      <button
        onClick={() => {
          handleTime(3, 15);
        }}
      >
        3:15am
      </button>
      <button
        onClick={() => {
          handleTime(3, 30);
        }}
      >
        3:30am
      </button>
      <button
        onClick={() => {
          handleTime(3, 45);
        }}
      >
        3:45am
      </button>
      <button
        onClick={() => {
          handleTime(4, 0);
        }}
      >
        4:00am
      </button>
      <button
        onClick={() => {
          handleTime(4, 15);
        }}
      >
        4:15am
      </button>
      <button
        onClick={() => {
          handleTime(4, 30);
        }}
      >
        4:30am
      </button>
      <button
        onClick={() => {
          handleTime(4, 45);
        }}
      >
        4:45am
      </button>
      <button
        onClick={() => {
          handleTime(5, 0);
        }}
      >
        5:00am
      </button>
      <button
        onClick={() => {
          handleTime(5, 15);
        }}
      >
        5:15am
      </button>
      <button
        onClick={() => {
          handleTime(5, 30);
        }}
      >
        5:30am
      </button>{" "}
      <button
        onClick={() => {
          handleTime(5, 45);
        }}
      >
        5:45am
      </button>
      <button
        onClick={() => {
          handleTime(6, 0);
        }}
      >
        6:00am
      </button>
      <button
        onClick={() => {
          handleTime(6, 15);
        }}
      >
        6:15am
      </button>
      <button
        onClick={() => {
          handleTime(6, 30);
        }}
      >
        6:30am
      </button>
      <button
        onClick={() => {
          handleTime(6, 45);
        }}
      >
        6:45am
      </button>
      <button
        onClick={() => {
          handleTime(7, 0);
        }}
      >
        7:00am
      </button>
      <button
        onClick={() => {
          handleTime(7, 15);
        }}
      >
        7:15am
      </button>
      <button
        onClick={() => {
          handleTime(7, 30);
        }}
      >
        7:30am
      </button>
      <button
        onClick={() => {
          handleTime(7, 45);
        }}
      >
        7:45am
      </button>
      <button
        onClick={() => {
          handleTime(8, 0);
        }}
      >
        8:00am
      </button>
      <button
        onClick={() => {
          handleTime(8, 15);
        }}
      >
        8:15am
      </button>
      <button
        onClick={() => {
          handleTime(8, 30);
        }}
      >
        8:30am
      </button>
      <button
        onClick={() => {
          handleTime(8, 45);
        }}
      >
        8:45am
      </button>
      <button
        onClick={() => {
          handleTime(9, 0);
        }}
      >
        9:00am
      </button>
      <button
        onClick={() => {
          handleTime(9, 15);
        }}
      >
        9:15am
      </button>
      <button
        onClick={() => {
          handleTime(9, 30);
        }}
      >
        9:30am
      </button>
      <button
        onClick={() => {
          handleTime(9, 45);
        }}
      >
        9:45am
      </button>
      <button
        onClick={() => {
          handleTime(10, 0);
        }}
      >
        10:00am
      </button>
      <button
        onClick={() => {
          handleTime(10, 15);
        }}
      >
        10:15am
      </button>
      <button
        onClick={() => {
          handleTime(10, 30);
        }}
      >
        10:30am
      </button>
      <button
        onClick={() => {
          handleTime(10, 45);
        }}
      >
        10:45am
      </button>
      <button
        onClick={() => {
          handleTime(11, 0);
        }}
      >
        11:00am
      </button>
      <button
        onClick={() => {
          handleTime(11, 15);
        }}
      >
        11:15am
      </button>
      <button
        onClick={() => {
          handleTime(11, 30);
        }}
      >
        11:30am
      </button>
      <button
        onClick={() => {
          handleTime(11, 45);
        }}
      >
        11:45am
      </button>
      <button
        onClick={() => {
          handleTime(12, 0);
        }}
      >
        12:00pm
      </button>
      <button
        onClick={() => {
          handleTime(12, 15);
        }}
      >
        12:15pm
      </button>
      <button
        onClick={() => {
          handleTime(12, 30);
        }}
      >
        12:30pm
      </button>
      <button
        onClick={() => {
          handleTime(12, 45);
        }}
      >
        12:45pm
      </button>
      <button
        onClick={() => {
          handleTime(13, 0);
        }}
      >
        1:00pm
      </button>
      <button
        onClick={() => {
          handleTime(13, 15);
        }}
      >
        1:15pm
      </button>
      <button
        onClick={() => {
          handleTime(13, 30);
        }}
      >
        1:30pm
      </button>
      <button
        onClick={() => {
          handleTime(13, 45);
        }}
      >
        1:45pm
      </button>
      <button
        onClick={() => {
          handleTime(14, 0);
        }}
      >
        2:00pm
      </button>
      <button
        onClick={() => {
          handleTime(14, 15);
        }}
      >
        2:15pm
      </button>
      <button
        onClick={() => {
          handleTime(14, 30);
        }}
      >
        2:30pm
      </button>
      <button
        onClick={() => {
          handleTime(14, 45);
        }}
      >
        2:45pm
      </button>
      <button
        onClick={() => {
          handleTime(15, 0);
        }}
      >
        3:00pm
      </button>
      <button
        onClick={() => {
          handleTime(15, 15);
        }}
      >
        3:15pm
      </button>
      <button
        onClick={() => {
          handleTime(15, 30);
        }}
      >
        3:30pm
      </button>
      <button
        onClick={() => {
          handleTime(15, 45);
        }}
      >
        3:45pm
      </button>
      <button
        onClick={() => {
          handleTime(16, 0);
        }}
      >
        4:00pm
      </button>
      <button
        onClick={() => {
          handleTime(16, 15);
        }}
      >
        4:15pm
      </button>
      <button
        onClick={() => {
          handleTime(16, 30);
        }}
      >
        4:30pm
      </button>
      <button
        onClick={() => {
          handleTime(16, 45);
        }}
      >
        4:45pm
      </button>
      <button
        onClick={() => {
          handleTime(17, 0);
        }}
      >
        5:00pm
      </button>
      <button
        onClick={() => {
          handleTime(17, 15);
        }}
      >
        5:15pm
      </button>
      <button
        onClick={() => {
          handleTime(17, 30);
        }}
      >
        5:30pm
      </button>{" "}
      <button
        onClick={() => {
          handleTime(17, 45);
        }}
      >
        5:45pm
      </button>
      <button
        onClick={() => {
          handleTime(18, 0);
        }}
      >
        6:00pm
      </button>
      <button
        onClick={() => {
          handleTime(18, 15);
        }}
      >
        6:15pm
      </button>
      <button
        onClick={() => {
          handleTime(18, 30);
        }}
      >
        6:30pm
      </button>
      <button
        onClick={() => {
          handleTime(18, 45);
        }}
      >
        6:45pm
      </button>
      <button
        onClick={() => {
          handleTime(19, 0);
        }}
      >
        7:00pm
      </button>
      <button
        onClick={() => {
          handleTime(19, 15);
        }}
      >
        7:15pm
      </button>
      <button
        onClick={() => {
          handleTime(19, 30);
        }}
      >
        7:30pm
      </button>
      <button
        onClick={() => {
          handleTime(19, 45);
        }}
      >
        7:45pm
      </button>
      <button
        onClick={() => {
          handleTime(20, 0);
        }}
      >
        8:00pm
      </button>
      <button
        onClick={() => {
          handleTime(20, 15);
        }}
      >
        8:15pm
      </button>
      <button
        onClick={() => {
          handleTime(20, 30);
        }}
      >
        8:30pm
      </button>
      <button
        onClick={() => {
          handleTime(20, 45);
        }}
      >
        8:45pm
      </button>
      <button
        onClick={() => {
          handleTime(21, 0);
        }}
      >
        9:00pm
      </button>
      <button
        onClick={() => {
          handleTime(21, 15);
        }}
      >
        9:15pm
      </button>
      <button
        onClick={() => {
          handleTime(21, 30);
        }}
      >
        9:30pm
      </button>
      <button
        onClick={() => {
          handleTime(21, 45);
        }}
      >
        9:45pm
      </button>
      <button
        onClick={() => {
          handleTime(22, 0);
        }}
      >
        10:00pm
      </button>
      <button
        onClick={() => {
          handleTime(22, 15);
        }}
      >
        10:15pm
      </button>
      <button
        onClick={() => {
          handleTime(22, 30);
        }}
      >
        10:30pm
      </button>
      <button
        onClick={() => {
          handleTime(22, 45);
        }}
      >
        10:45pm
      </button>
      <button
        onClick={() => {
          handleTime(23, 0);
        }}
      >
        11:00pm
      </button>
      <button
        onClick={() => {
          handleTime(23, 15);
        }}
      >
        11:15pm
      </button>
      <button
        onClick={() => {
          handleTime(23, 30);
        }}
      >
        11:30pm
      </button>
      <button
        onClick={() => {
          handleTime(23, 45);
        }}
      >
        11:45pm
      </button>
    </div>
  );
}
