import React from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";

import Header from "../Layout/Header";
import css from "./StartPage.module.css";
import { mairimedTags } from "../../Utils/listTags";

export const StartDiscover = () => {
  const [filteredTags, setFilteredTags] = React.useState(mairimedTags);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState("");

  React.useEffect(() => {
    setFilteredTags(mairimedTags);
  }, []);

  const useNavigateParams = () => {
    return (url, params) => {
      const path = generatePath(":url?:queryString", {
        url,
        queryString: params,
      });
      navigate(path);
    };
  };

  const navigateSearch = useNavigateParams();

  const navigateHandler = () => {
    navigateSearch("discover", `search_query=${searchQuery}`);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    const tagArray = mairimedTags.filter((tag) => tag.includes(e.target.value));
    setFilteredTags(tagArray);
  };

  return (
    <>
      <Header />
      <div className={css.container}>
        <h1>Mairimed Tags</h1>
        <form
          action="/"
          method="get"
          onSubmit={(e) => {
            e.preventDefault();
            navigateHandler();
          }}
          className={css.topbarItens}
        >
          <label htmlFor="header-search">
            <span className={css.visuallyHidden}>Search</span>
          </label>
          <input
            type="text"
            id="header-search"
            placeholder="Search tag..."
            autoComplete="off"
            onInput={(e) => handleSearch(e)}
          />
        </form>
        {filteredTags.map((tag) => (
          <Link to={`/home/tags/${tag}`}>{tag}</Link>
        ))}
      </div>
    </>
  );
};
