import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./NewFlashcard.module.css";
import { FlashcardContext } from "../../Context/FlashcardContext";
import { UserContext } from "../../Context/UserContext";
import Input from "../Forms/Input";
import Head from "../../Utils/Head";
import { newId } from "../../Utils/newId";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { Search } from "../Forms/Search";
import { getDeckFirebase } from "../../Utils/firebaseFlashcard";
import { createTagFirebase } from "../../Utils/firebaseUser";

export const EditFlashcard = () => {
  const { user, userData, savedTags, setSavedTags } =
    React.useContext(UserContext);
  const { dispatchDecks, savedFlashcardsSnippets, dispatchFlashcardsSnippets } =
    React.useContext(FlashcardContext);
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [deck, setDeck] = React.useState("");
  const [cards, setCards] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertCards, setAlertCards] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [showTagsModal, setShowTagsModal] = React.useState(false);
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = React.useState(query || "");
  const [hide, setHide] = React.useState(false);

  React.useEffect(() => {
    getDeckFirebase(params.id).then(function (result) {
      setDeck(result);
      setTitle(result.title);
      setDescription(result.description);
      setHide(result.hide);
      const tagsResult = [];
      result.tag1 !== "" && tagsResult.push(result.tag1);
      result.tag2 !== "" && tagsResult.push(result.tag2);
      result.tag3 !== "" && tagsResult.push(result.tag3);
      setTags(tagsResult);
      const deckCards = result.cards;
      deckCards.sort(function (a, b) {
        return a.index - b.index;
      });
      setCards(deckCards);
      user.uid === result.userId && setLoading(false);
    });
  }, [params.id, user.uid]);

  const filterTags = (tagsFil, query) => {
    if (!query) {
      return [];
    }
    return tagsFil.filter((tag) => {
      const tagName = tag.title.toLowerCase();
      return tagName.includes(query.toLowerCase());
    });
  };

  const filteredTags = filterTags(savedTags, searchQuery);

  function newCard() {
    let newCards = [
      ...cards,
      {
        id: newId(),
        index: cards.length + 1,
        front: "",
        back: "",
      },
    ];
    return setCards(newCards);
  }

  function deleteCard(index) {
    let array = cards;
    array.splice(index, 1); // 2nd parameter means remove one item only
    array.forEach((c) => {
      if (c.index > index) {
        c.index = c.index - 1;
      }
    });
    return setCards([...array]);
  }

  function updateCard(newName, index, side) {
    const newCards = [...cards];
    if (side === "front") {
      newCards[index].front = newName;
    } else {
      newCards[index].back = newName;
    }

    setCards(newCards);
  }

  function handleValidation() {
    let formIsValid = true;
    const filteredCards = cards.filter(
      (card) => card.front !== "" && card.back !== ""
    );

    if (filteredCards.length < 2) {
      formIsValid = false;
      setAlertCards("You need two cards to create a Deck");
      if (!title.value) {
        setAlertTitle("You need a title to create a Deck");
      } else {
        setAlertTitle("");
      }
    } else if (title === "") {
      formIsValid = false;
      setAlertTitle("You need a title to create a Deck");
    } else {
      setAlertCards("");
      setAlertTitle("");
    }
    return formIsValid;
  }

  function handleSubmit() {
    const filteredCards = cards.filter(
      (card) => card.front !== "" && card.back !== ""
    );

    const updatedDeck = {
      id: deck.id,
      hide: hide,
      userId: user.uid,
      userName: user.displayName,
      title: title,
      description: description,
      tag1: tags[0] ? tags[0] : "",
      tag2: tags[1] ? tags[1] : "",
      tag3: tags[2] ? tags[2] : "",
      cards: filteredCards,
    };
    const updatedDeckSnippet = {
      id: updatedDeck.id,
      userName: updatedDeck.userName,
      title: updatedDeck.title,
      tag1: tags[0] ? tags[0].title : "",
      tag2: tags[1] ? tags[1].title : "",
      tag3: tags[2] ? tags[2].title : "",
      cards: updatedDeck.cards.length,
    };

    const flashcardsSnippets = savedFlashcardsSnippets.map((snippet) =>
      snippet.id === updatedDeckSnippet.id ? updatedDeckSnippet : snippet
    );

    dispatchFlashcardsSnippets({
      type: "update",
      payload: updatedDeckSnippet,
    });

    dispatchDecks({
      type: "update",
      deck: updatedDeck,
      user: user,
      flashcardsSnippets: flashcardsSnippets,
    });

    navigate("/study/myflashcards");
  }

  function handleNewTag() {
    const newTag = {
      id: newId(),
      title: searchQuery.toLowerCase(),
    };
    setSavedTags([...savedTags, newTag]);
    createTagFirebase(newTag);
    addTag(newTag);
  }

  function addTag(tag) {
    if (tags.indexOf(tag) < 0) {
      setTags([...tags, tag]);
    }
    setShowTagsModal(false);
  }

  function deleteTag(tag) {
    let filteredTags = tags.filter((t) => t.id !== tag.id);
    setTags(filteredTags);
  }

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <Head title={`Edit ${deck.title}`} />
        <h3>Edit {deck.title}</h3>
        {alertTitle && <p className={styles.error}>{alertTitle}</p>}
        {alertCards && <p className={styles.error}>{alertCards}</p>}

        <form className="form">
          <Input
            label="Title"
            type="text"
            name="title"
            required
            maxLength="50"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <Input
            label="Description"
            type="text"
            name="description"
            maxLength="100"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          {userData.staff && (
            <button
              className={hide ? "defaultActiveButton" : "defaultButton"}
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
              }}
            >
              Hide
            </button>
          )}
          <div className={styles.tagsContainer}>
            Tags:
            {tags.map((tag) => (
              <div
                className={styles.tag}
                key={tag.id}
                onClick={() => deleteTag(tag)}
              >
                <span>{tag.title}</span>
                <i className={`material-icons ${styles.tagClose}`}>clear</i>
              </div>
            ))}
          </div>
          <button
            className="defaultButton"
            onClick={(e) => {
              e.preventDefault();
              setShowTagsModal(true);
            }}
            disabled={tags.length > 2 ? true : false}
          >
            Add Tags
          </button>

          {cards.map((card) => (
            <React.Fragment key={card.id}>
              <div className={styles.card}>
                <div className={styles.header}>
                  <div>{card.index}</div>
                  {cards.length > 2 ? (
                    <div onClick={() => deleteCard(card.index - 1)}>
                      <i className="material-icons materialIcon">delete</i>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className={styles.body}>
                  <Input
                    label="Front"
                    type="text"
                    name="front"
                    maxLength="200"
                    value={card.front}
                    onChange={(e) => {
                      updateCard(e.target.value, card.index - 1, "front");
                    }}
                  />
                  <Input
                    label="Back"
                    type="text"
                    name="back"
                    maxLength="200"
                    value={card.back}
                    onChange={(e) => {
                      updateCard(e.target.value, card.index - 1, "back");
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          ))}

          <button
            className={styles.card}
            onClick={(e) => {
              e.preventDefault();
              newCard();
            }}
            disabled={cards.length > 500 ? true : false}
          >
            <i className="material-icons materialIcon">add_circle_outline</i>{" "}
            New Card
          </button>
          <button
            className={styles.saveButton}
            onClick={(e) => {
              e.preventDefault();
              handleValidation() && handleSubmit();
            }}
          >
            Done
          </button>
          {alertTitle && <p className={styles.error}>{alertTitle}</p>}
          {alertCards && <p className={styles.error}>{alertCards}</p>}
        </form>
        {showTagsModal && (
          <OutsideAlerter setMenuButton={setShowTagsModal}>
            <div className={styles.tagsModal}>
              <h3>Tags</h3>
              <Search
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              {filteredTags.map((tag) => (
                <div
                  className={styles.tag}
                  key={tag.id}
                  onClick={() => {
                    addTag(tag);
                  }}
                >
                  {tag.title}
                </div>
              ))}
              <div className={styles.modalFooter}></div>
              <button
                className="saveButton"
                onClick={() => handleNewTag()}
                disabled={
                  !filteredTags
                    .map((tag) => tag.title)
                    .includes(searchQuery.toLowerCase()) &&
                  searchQuery.length > 0
                    ? false
                    : true
                }
              >
                Save New Tag
              </button>
            </div>
          </OutsideAlerter>
        )}
      </>
    );
  }
};
