import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import styles from "./header.module.css";
import { ReactComponent as Logo } from "../../assets/mairimed-logo.svg";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { UserContext } from "../../Context/UserContext";
import { CalendarToolbar } from "../Calendar/CalendarToolbar";
import IconButton from "./IconButton";

function Header(props) {
  const { user, userLogout } = React.useContext(UserContext);
  const { pathname } = useLocation();
  const [menuButton, setMenuButton] = React.useState(false);
  // const [searchQuery, setSearchQuery] = React.useState("");
  const navigate = useNavigate();

  // const useNavigateParams = () => {
  //   return (url, params) => {
  //     const path = generatePath(":url?:queryString", {
  //       url,
  //       queryString: params,
  //     });
  //     navigate(path);
  //   };
  // };

  // const navigateSearch = useNavigateParams();

  // const navigateHandler = () => {
  //   navigateSearch("discover", `search_query=${searchQuery}`);
  // };

  if (!user) {
    return (
      <div className={styles.topbar}>
        <div className={styles.topbarWrapper}>
          <div className={styles.topLeft}>
            <div className={styles.logo}>
              <Link className={styles.logo} to="/" aria-label="Mairimed - Home">
                <Logo /> Mairimed
              </Link>
            </div>
          </div>
          <div className={styles.topRight}>
            <div className={styles.avatar}>
              <div className={styles.menuContainer}>
                <button
                  className="defaultButton"
                  onClick={() => navigate("/login/")}
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.topbar}>
        <div className={styles.topbarWrapper}>
          <div className={styles.topLeft}>
            <div className={styles.logo}>
              <IconButton name="menu" onClick={(e) => props.onMenuClick(e)} />
              <Link className={styles.logo} to="/" aria-label="Mairimed - Home">
                <Logo />
                Mairimed Beta
              </Link>
              <div className={styles.tollbar}>
                {pathname !== "/calendar" &&
                pathname !== "/calendar/settings" ? (
                  <form
                    action="/"
                    method="get"
                    onSubmit={(e) => {
                      e.preventDefault();
                      // navigateHandler();
                    }}
                    className={styles.topbarItens}
                  >
                    {/* <label htmlFor="header-search">
                      <span className={styles.visuallyHidden}>Search</span>
                    </label>
                    <input
                      type="text"
                      id="header-search"
                      placeholder="Search..."
                      autoComplete="off"
                      onInput={(e) => setSearchQuery(e.target.value)}
                    />
                    <i
                      className={"material-icons materialIcon"}
                      onClick={(e) => {
                        e.preventDefault();
                        navigateHandler();
                      }}
                    >
                      search
                    </i> */}
                  </form>
                ) : (
                  ""
                )}
                {pathname === "/calendar" && (
                  <div className={styles.topbarItens}>
                    <CalendarToolbar />
                  </div>
                )}
                {pathname === "/calendar/settings" && (
                  <div className={styles.topbarItens}>
                    <button className="materialButton">
                      <Link to="/calendar">
                        <span className={`material-icons materialIcon`}>
                          arrow_back
                        </span>
                      </Link>
                    </button>
                    <h2>Calendar Settings</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.topRight}>
            {/* <div className={styles.topbarIconContainer}>
            <i className="material-icons materialIcon">notifications_none</i>
            <span className={styles.topIconBadge}>1</span>
          </div>
          <div className={styles.topbarIconContainer}>
            <i className="material-icons materialIcon">translate</i>
          </div> */}
            {/* <div className={styles.topbarIconContainer}>
              <Link to="/calendar/settings">
                <i className="material-icons materialIcon">settings</i>
              </Link>
            </div> */}
            <div className={styles.avatar}>
              <div className={styles.menuContainer}>
                <OutsideAlerter setMenuButton={setMenuButton}>
                  <button
                    aria-label="Menu"
                    className={styles.userButton}
                    onClick={() => setMenuButton(!menuButton)}
                  ></button>
                  {menuButton && (
                    <nav className={styles.navUserButton}>
                      <ul>
                        <Link to="/account">
                          <li>
                            <button onClick={() => setMenuButton(!menuButton)}>
                              Accont
                            </button>
                          </li>
                        </Link>
                        <li>
                          <button onClick={userLogout}>Logout</button>
                        </li>
                      </ul>
                    </nav>
                  )}
                </OutsideAlerter>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
