import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";

import { db } from "../../firebaseConfig";
import Head from "../../Utils/Head";

export const SchoolMapsSubject = () => {
  const params = useParams();
  const [mindmaps, setMindmaps] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getMindmaps = async () => {
      const docRef = collection(db, "school", params.subject, "mindmaps");
      const docSnap = await getDocs(docRef);
      if (docSnap) {
        return docSnap.docs.map((doc) => ({ ...doc.data() }));
      } else {
        console.log("No such document");
      }
    };

    getMindmaps().then(function (result) {
      if (result) {
        console.log(result);
        setMindmaps(result);
      }
      setLoading(true);
    });
  }, [params.subject]);

  if (!loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <Head
          title={params.subject}
          description={`${params.subject} - Mairimed`}
        />
        <h1>{params.subject.toUpperCase()}</h1>
        {mindmaps.map((map) => (
          <h4 key={map.id}>
            <Link to={`/study/mindmap/${map.id}`}>{map.title}</Link>
          </h4>
        ))}
      </div>
    );
  }
};
