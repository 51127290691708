import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import css from "./Login.module.css";
import useForm from "../../Hooks/useForm";
import Button from "../Forms/Button";
import Input from "../Forms/Input";
import { UserContext } from "../../Context/UserContext";
import Error from "../../Utils/Error";
import Head from "../../Utils/Head";

const LoginForm = () => {
  const { userLogin, loading, error, signInWithGoogle } =
    useContext(UserContext);
  const email = useForm("email");
  const password = useForm();
  const [signInWithMairimed, setSignInWithMairimed] = useState(false);

  async function handleSubmitLogin(event) {
    event.preventDefault();

    if (email.validate() && password.validate()) {
      userLogin(email.value, password.value);
    }
  }

  return (
    <section>
      <Head title="Login" />
      <h1 className="title">Sign In</h1>
      <button onClick={signInWithGoogle} className={css.loginWithGoogleBtn}>
        Sign in with Google
      </button>
      <p>OR</p>
      <button
        onClick={() => setSignInWithMairimed(!signInWithMairimed)}
        className={css.loginWithMairimedBtn}
      >
        Sign In With Mairimed
      </button>
      {signInWithMairimed && (
        <form onSubmit={handleSubmitLogin}>
          <Input label="Email" type="email" name="email" {...email} />
          <Input
            label="Password"
            type="password"
            name="password"
            {...password}
          />
          <Link className="textLink" to="/login/recover">
            Forgot password?
          </Link>
          <div className={css.loginForm}>
            <Link className="textLink" to="/signup">
              Create account
            </Link>
            {loading ? (
              <Button disabled>Loading...</Button>
            ) : (
              <Button>Submit</Button>
            )}
            <Error error={error} />
          </div>
        </form>
      )}
    </section>
  );
};

export default LoginForm;
