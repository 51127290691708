import css from "./Card.module.css";

function Card(props) {
  let className = css.container;
  if (props.isSelected) {
    className += " " + css.selected;
  }

  return (
    <div
      className={className}
      onClick={props.onClick}
      onDoubleClick={props.onDoubleClick}
    >
      <h5 className={css.title}>{props.title}</h5>
      {/* <div className={css.comment}>{props.comment}</div>
      <div className={css.comment}>{props.reference}</div> */}
      <div className={css.infoContainer}>
        <i className={`material-icons ${css.cardIcon}`}>{props.icon}</i>
        <div className={css.tag}>{props.items}</div>
      </div>
      <div className={css.tagsContainer}>
        <i
          className={`material-icons ${css.cardIcon}`}
          style={{ marginTop: props.tag1 ? "" : "0.5rem" }}
        >
          local_offer
        </i>
        {props.tag1.slice(0, 2) && (
          <div className={css.tag}>{props.tag1.slice(0, 13)}</div>
        )}
        {props.tag2 && <div className={css.tag}>{props.tag2.slice(0, 13)}</div>}
        {props.tag3 && <div className={css.tag}>{props.tag3.slice(0, 13)}</div>}
      </div>
      <div
        className={css.user}
        style={{
          marginTop: props.title.length > 40 ? "1rem" : "2rem",
        }}
      >
        <i className={`material-icons ${css.cardIcon}`}>account_circle</i>
        {" " + props.user}
      </div>
    </div>
  );
}

export default Card;
