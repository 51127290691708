import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import css from "./MyQuizzes.module.css";
import Card from "../Layout/Card";
import { QuizContext } from "../../Context/QuizContext";
import { UserContext } from "../../Context/UserContext";
import Toolbar from "../Layout/Toolbar";
import Head from "../../Utils/Head";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { getQuizFirebase } from "../../Utils/firebaseQuiz";
import { deleteObject, ref } from "firebase/storage";
import { storage } from "../../firebaseConfig";

export const MyQuizzes = () => {
  const { user, favoritesQuizId, favoritesQuizTitle } = useContext(UserContext);
  const {
    quizzesScores,
    dispatchQuizzes,
    savedQuizzesSnippets,
    dispatchQuizzesSnippets,
  } = useContext(QuizContext);
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [tagSelected, setTagSelected] = useState(null);
  const [userTags, setUserTags] = useState([]);
  const [filteredQuizzes, setFilteredQuizzes] = useState(savedQuizzesSnippets);
  const sortedQuizzes = quizzesScores.sort(
    (a, b) => a.percentageScore - b.percentageScore
  );

  useEffect(() => {
    setFilteredQuizzes(savedQuizzesSnippets);
    const tagArray = [];
    savedQuizzesSnippets.forEach((quiz) => {
      if (
        quiz.tag1 !== "" &&
        tagArray.map((tag) => tag).indexOf(quiz.tag1) < 0
      ) {
        tagArray.push(quiz.tag1);
      }
      if (
        quiz.tag2 !== "" &&
        tagArray.map((tag) => tag).indexOf(quiz.tag2) < 0
      ) {
        tagArray.push(quiz.tag2);
      }
      if (
        quiz.tag3 !== "" &&
        tagArray.map((tag) => tag).indexOf(quiz.tag3) < 0
      ) {
        tagArray.push(quiz.tag3);
      }
    });
    setUserTags(tagArray);
  }, [savedQuizzesSnippets]);

  function resetTags() {
    setTagSelected(null);
    setFilteredQuizzes(savedQuizzesSnippets);
  }

  function filterQuizzes(tag) {
    setTagSelected(tag);
    let arrayQuizzes = savedQuizzesSnippets.filter(
      (map) => map.tag1 === tag || map.tag2 === tag || map.tag3 === tag
    );
    setFilteredQuizzes([...arrayQuizzes]);
  }

  const actionMenu = [
    savedQuizzesSnippets.length < 100
      ? {
          name: "add",
          type: "alert",
          onClick: () => navigate("/study/newquiz"),
        }
      : {
          name: "add",
          type: "primary",
          onClick: () => alert("Maximum capacity of itens (100)"),
        },

    selected
      ? { name: "delete", type: "alert", onClick: () => setDeleteModal(true) }
      : {
          name: "delete",
          type: "primary",
          onClick: () => alert("Select an item"),
        },
  ];

  function deleteQuiz(quiz) {
    const quizzesSnippets = savedQuizzesSnippets.filter(
      (d) => d.id !== quiz.id
    );

    getQuizFirebase(quiz.id).then(function (result) {
      result?.questions.map((question) => {
        if (question.image) {
          deleteObject(ref(storage, `${question.image}`));
          console.log(`deleted ${question.image}`);
        }
      });
    });

    dispatchQuizzesSnippets({
      type: "delete",
      payload: quiz,
    });
    dispatchQuizzes({
      type: "delete",
      user: user,
      quiz: quiz,
      quizzesSnippets: quizzesSnippets,
    });
    navigate("/study/myquizzes");
  }

  return (
    <>
      <Head title="Quiz" description="Quiz - Mairimed" />
      <div className={css.tagsBar}>
        <button
          className={
            tagSelected === null
              ? `defaultActiveButton ${css.tagItem}`
              : `defaultButton ${css.tagItem}`
          }
          onClick={() => resetTags()}
        >
          All tags
        </button>
        {userTags.map((tag, i) => (
          <button
            key={i}
            onClick={() => filterQuizzes(tag)}
            className={
              tagSelected === tag
                ? `defaultActiveButton ${css.tagItem}`
                : `defaultButton ${css.tagItem}`
            }
          >
            {tag}
          </button>
        ))}
      </div>

      <Toolbar
        list={actionMenu}
        type="alert"
        location={["horizontal", "right", "top"]}
      />

      <div className={css.list}>
        <div className={css.card}>
          <h4>5 Review</h4>
          {sortedQuizzes[0] && (
            <p
              className={css.cardItem}
              onClick={() => navigate(`/study/quiz/${sortedQuizzes[0].quizId}`)}
            >
              {sortedQuizzes[0].quizTitle}: {sortedQuizzes[0].percentageScore}%
            </p>
          )}
          {sortedQuizzes[1] && (
            <p
              className={css.cardItem}
              onClick={() => navigate(`/study/quiz/${sortedQuizzes[1].quizId}`)}
            >
              {sortedQuizzes[1].quizTitle}: {sortedQuizzes[1].percentageScore}%
            </p>
          )}
          {sortedQuizzes[2] && (
            <p
              className={css.cardItem}
              onClick={() => navigate(`/study/quiz/${sortedQuizzes[2].quizId}`)}
            >
              {sortedQuizzes[2].quizTitle}: {sortedQuizzes[2].percentageScore}%
            </p>
          )}
          {sortedQuizzes[3] && (
            <p
              className={css.cardItem}
              onClick={() => navigate(`/study/quiz/${sortedQuizzes[3].quizId}`)}
            >
              {sortedQuizzes[3].quizTitle}: {sortedQuizzes[3].percentageScore}%
            </p>
          )}
          {sortedQuizzes[4] && (
            <p
              className={css.cardItem}
              onClick={() => navigate(`/study/quiz/${sortedQuizzes[4].quizId}`)}
            >
              {sortedQuizzes[4].quizTitle}: {sortedQuizzes[4].percentageScore}%
            </p>
          )}
        </div>
        <div className={css.card}>
          <h4>5 Favorites</h4>
          {favoritesQuizTitle.map((map, i) => (
            <p
              key={favoritesQuizId[i]}
              className={css.cardItem}
              onClick={() => navigate(`/study/quiz/${favoritesQuizId[i]}`)}
            >
              {map}
            </p>
          ))}
        </div>
      </div>
      <div className={css.list}>
        {filteredQuizzes
          .sort((a, b) => a.title.charCodeAt(0) - b.title.charCodeAt(0))
          .map((quiz) => (
            <div key={quiz.id}>
              <Card
                onClick={() => setSelected(quiz)}
                onDoubleClick={() => navigate(`/study/quiz/${quiz.id}`)}
                isSelected={quiz.id === selected.id}
                icon="quiz"
                title={quiz.title}
                items={`${quiz.questions} questions`}
                tag1={quiz.tag1 && quiz.tag1}
                tag2={quiz.tag2 && quiz.tag2}
                tag3={quiz.tag3 && quiz.tag3}
                user={quiz.userName}
              />
            </div>
          ))}
      </div>
      {deleteModal && (
        <OutsideAlerter setMenuButton={setDeleteModal}>
          <div className={css.deleteModal}>
            <h3>Delete {selected.title}?</h3>
            <p>
              You are about to delete this quiz and all of its data. No one will
              be able to access this quiz ever again.
            </p>
            <div>
              <button
                className="defaultButton"
                onClick={() => setDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className="deleteButton"
                onClick={() => {
                  deleteQuiz(selected);
                  setDeleteModal(false);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </OutsideAlerter>
      )}
    </>
  );
};
