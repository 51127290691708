import React from "react";

import styles from "./Schedule.module.css";
import { getWeek } from "../Calendar/getDate";
import Head from "../../Utils/Head";
import dayjs from "dayjs";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { ScheduleContext } from "../../Context/ScheduleContext";
import { UserContext } from "../../Context/UserContext";

export const Schedule = () => {
  const { selectedTask, savedMeals, dispatchUserSchedule, savedUserSchedule } =
    React.useContext(ScheduleContext);
  const { user } = React.useContext(UserContext);
  const currentWeek = getWeek();
  const breakfastWeek = savedUserSchedule.breakfastWeek
    ? savedUserSchedule.breakfastWeek
    : new Array(7).fill("");
  const morningWeek = savedUserSchedule.morningWeek
    ? savedUserSchedule.morningWeek
    : new Array(7).fill("");
  const morningSnackWeek = savedUserSchedule.morningSnackWeek
    ? savedUserSchedule.morningSnackWeek
    : new Array(7).fill("");
  const lunchWeek = savedUserSchedule.lunchWeek
    ? savedUserSchedule.lunchWeek
    : new Array(7).fill("");
  const afternoonWeek = savedUserSchedule.afternoonWeek
    ? savedUserSchedule.afternoonWeek
    : new Array(7).fill("");
  const afternoonSnackWeek = savedUserSchedule.afternoonSnackWeek
    ? savedUserSchedule.afternoonSnackWeek
    : new Array(7).fill("");
  const dinnerWeek = savedUserSchedule.dinnerWeek
    ? savedUserSchedule.dinnerWeek
    : new Array(7).fill("");
  const nightWeek = savedUserSchedule.nightWeek
    ? savedUserSchedule.nightWeek
    : new Array(7).fill("");
  const nightSnackWeek = savedUserSchedule.nightSnackWeek
    ? savedUserSchedule.nightSnackWeek
    : new Array(7).fill("");
  const [showMealsModal, setShowMealsModal] = React.useState(false);
  const [showTasksModal, setShowTasksModal] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState("");
  const [selectedType, setSelectedType] = React.useState("");
  const [task, setTask] = React.useState(selectedTask ? selectedTask.task : "");

  function getCurrentDayClass(day) {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? styles.currentDay
      : "";
  }

  function handleMealChoice(type, index, meal) {
    if (type === "breakfast") {
      let updateUserSchedule = savedUserSchedule;
      breakfastWeek[index] = meal;
      updateUserSchedule.breakfastWeek = breakfastWeek;
      dispatchUserSchedule({
        type: "push",
        user: user,
        payload: updateUserSchedule,
      });
    }
    if (type === "morning") {
      let updateUserSchedule = savedUserSchedule;
      morningWeek[index] = meal;
      updateUserSchedule.morningWeek = morningWeek;
      dispatchUserSchedule({
        type: "push",
        user: user,
        payload: updateUserSchedule,
      });
    }
    if (type === "morningSnack") {
      let updateUserSchedule = savedUserSchedule;
      morningSnackWeek[index] = meal;
      updateUserSchedule.morningSnackWeek = morningSnackWeek;
      dispatchUserSchedule({
        type: "push",
        user: user,
        payload: updateUserSchedule,
      });
    }
    if (type === "lunch") {
      let updateUserSchedule = savedUserSchedule;
      lunchWeek[index] = meal;
      updateUserSchedule.lunchWeek = lunchWeek;
      dispatchUserSchedule({
        type: "push",
        user: user,
        payload: updateUserSchedule,
      });
    }
    if (type === "afternoon") {
      let updateUserSchedule = savedUserSchedule;
      afternoonWeek[index] = meal;
      updateUserSchedule.afternoonWeek = afternoonWeek;
      dispatchUserSchedule({
        type: "push",
        user: user,
        payload: updateUserSchedule,
      });
    }
    if (type === "afternoonSnack") {
      let updateUserSchedule = savedUserSchedule;
      afternoonSnackWeek[index] = meal;
      updateUserSchedule.afternoonSnackWeek = afternoonSnackWeek;
      console.log(updateUserSchedule);
      dispatchUserSchedule({
        type: "push",
        user: user,
        payload: updateUserSchedule,
      });
    }
    if (type === "dinner") {
      let updateUserSchedule = savedUserSchedule;
      dinnerWeek[index] = meal;
      updateUserSchedule.dinnerWeek = dinnerWeek;
      dispatchUserSchedule({
        type: "push",
        user: user,
        payload: updateUserSchedule,
      });
    }
    if (type === "night") {
      let updateUserSchedule = savedUserSchedule;
      nightWeek[index] = meal;
      updateUserSchedule.nightWeek = nightWeek;
      console.log(updateUserSchedule);
      dispatchUserSchedule({
        type: "push",
        user: user,
        payload: updateUserSchedule,
      });
    }
    if (type === "nightSnack") {
      let updateUserSchedule = savedUserSchedule;
      nightSnackWeek[index] = meal;
      updateUserSchedule.nightSnackWeek = nightSnackWeek;
      console.log(updateUserSchedule);
      dispatchUserSchedule({
        type: "push",
        user: user,
        payload: updateUserSchedule,
      });
    }
  }

  return (
    <>
      <Head title="Schedule" description="Schedule - Mairimed" />
      <div className={styles.month}>
        <span className={styles.cell_title}></span>

        {currentWeek.map((day, i) => (
          <span className={styles.cell_title} key={i}>
            {dayjs(day).format("ddd")}{" "}
            <span className={`${styles.dayNumber} ${getCurrentDayClass(day)}`}>
              {dayjs(day).format("DD")}
            </span>
          </span>
        ))}

        <span className={styles.cell_title}>Breakfast</span>
        {breakfastWeek.map((meal, idx) => (
          <span
            className={styles.day_name}
            key={idx}
            onClick={() => {
              setSelectedIndex(idx);
              setSelectedType("breakfast");
              setShowTasksModal(true);
            }}
          >
            {meal}
          </span>
        ))}

        <span className={styles.cell_title}>Morning</span>
        {morningWeek.map((event, idx) => (
          <span
            className={styles.day_name}
            key={idx}
            onClick={() => {
              setSelectedIndex(idx);
              setSelectedType("morning");
              setShowTasksModal(true);
            }}
          >
            {event}
          </span>
        ))}

        <span className={styles.cell_title}>Morning Snack</span>
        {morningSnackWeek.map((event, idx) => (
          <span
            className={styles.day_name}
            key={idx}
            onClick={() => {
              setSelectedIndex(idx);
              setSelectedType("morningSnack");
              setShowTasksModal(true);
            }}
          >
            {event}
          </span>
        ))}

        <span className={styles.cell_title}>Lunch</span>
        {lunchWeek.map((meal, idx) => (
          <span
            className={styles.day_name}
            key={idx}
            onClick={() => {
              setSelectedIndex(idx);
              setSelectedType("lunch");
              setShowTasksModal(true);
            }}
          >
            {meal}
          </span>
        ))}

        <span className={styles.cell_title}>Afternoon</span>
        {afternoonWeek.map((event, idx) => (
          <span
            className={styles.day_name}
            key={idx}
            onClick={() => {
              setSelectedIndex(idx);
              setSelectedType("afternoon");
              setShowTasksModal(true);
            }}
          >
            {event}
          </span>
        ))}

        <span className={styles.cell_title}>Afternoon Snack</span>
        {afternoonSnackWeek.map((event, idx) => (
          <span
            className={styles.day_name}
            key={idx}
            onClick={() => {
              setSelectedIndex(idx);
              setSelectedType("afternoonSnack");
              setShowTasksModal(true);
            }}
          >
            {event}
          </span>
        ))}

        <span className={styles.cell_title}>Dinner</span>
        {dinnerWeek.map((meal, idx) => (
          <span
            className={styles.day_name}
            key={idx}
            onClick={() => {
              setSelectedIndex(idx);
              setSelectedType("dinner");
              setShowTasksModal(true);
            }}
          >
            {meal}
          </span>
        ))}

        <span className={styles.cell_title}>Night</span>
        {nightWeek.map((event, idx) => (
          <span
            className={styles.day_name}
            key={idx}
            onClick={() => {
              setSelectedIndex(idx);
              setSelectedType("night");
              setShowTasksModal(true);
            }}
          >
            {event}
          </span>
        ))}

        <span className={styles.cell_title}>Night Snack</span>
        {nightSnackWeek.map((event, idx) => (
          <span
            className={styles.day_name}
            key={idx}
            onClick={() => {
              setSelectedIndex(idx);
              setSelectedType("nightSnack");
              setShowTasksModal(true);
            }}
          >
            {event}
          </span>
        ))}
      </div>

      {showMealsModal && (
        <OutsideAlerter setMenuButton={setShowMealsModal}>
          <div className={styles.eventsModal}>
            <header className={styles.header}>
              <span
                onClick={() => setShowMealsModal(false)}
                className={`material-icons ${styles.materialIcon} ${styles.materialButton}`}
              >
                close
              </span>
            </header>

            <div>
              <h3>Meals</h3>
            </div>
            <div>
              {savedMeals.map((meal) => (
                <button
                  key={meal.id}
                  onClick={(e) => {
                    e.preventDefault();
                    handleMealChoice(selectedType, selectedIndex, meal);
                    setShowMealsModal(false);
                  }}
                >
                  {meal.name}
                </button>
              ))}
            </div>

            <footer className={styles.footer}>
              <button type="submit" className="saveButton">
                Save
              </button>
            </footer>
          </div>
        </OutsideAlerter>
      )}
      {showTasksModal && (
        <OutsideAlerter setMenuButton={setShowTasksModal}>
          <div className={styles.eventsModal}>
            <form className={styles.form}>
              <header className={styles.header}>
                <span
                  onClick={() => setShowTasksModal(false)}
                  className={`material-icons ${styles.materialIcon} ${styles.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={styles.body}>
                <h3>Task {selectedType}</h3>
                <input
                  type="text"
                  name="task"
                  placeholder={`Add a ${selectedType}`}
                  value={task}
                  className={styles.task}
                  maxLength="20"
                  onChange={(e) => {
                    setTask(e.target.value);
                  }}
                  autoComplete="off"
                />
              </div>
              <footer className={styles.footer}>
                <button
                  type="submit"
                  className="defaultButton"
                  onClick={(e) => {
                    e.preventDefault();
                    handleMealChoice(selectedType, selectedIndex, task);
                    setShowTasksModal(false);
                  }}
                >
                  Save
                </button>
              </footer>
            </form>
          </div>
        </OutsideAlerter>
      )}
    </>
  );
};
