export const calendarColors = [
  "color0",
  "color1",
  "color2",
  "color3",
  "color4",
  "color5",
  "color6",
  "color7",
  "color8",
  "color9",
  "color10",
  "color11",
  "color12",
  "color13",
];

export const mapColors = [
  "color7",
  "color0",
  "color5",
  "color10",
  "color14",
  "color15",
  "color16",
  "color13",
  "color2",
  "color9",
  "color17",
  "color4",
  "color18",
  "color3",
];
