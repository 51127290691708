import {
  createQuizFirebase,
  updateQuizFirebase,
  deleteQuizFirebase,
  updateQuizzesScores,
} from "../Utils/firebaseQuiz";

export const savedQuizzesSnippetsReducer = (state, { type, payload }) => {
  switch (type) {
    case "init":
      return payload;
    case "push":
      return [...state, payload];
    case "update":
      return state.map((d) => (d.id === payload.id ? payload : d));
    case "delete":
      return state.filter((d) => d.id !== payload.id);
    default:
      throw new Error();
  }
};

export const quizzesScoresReducer = (state, { type, user, payload }) => {
  switch (type) {
    case "init":
      return payload;
    case "push":
      return [...state, payload];
    case "update":
      updateQuizzesScores(user, payload);
      return payload;
    case "delete":
      return state.filter((d) => d.id !== payload.id);
    default:
      throw new Error();
  }
};

export const savedQuizzesReducer = (
  state,
  { type, user, quiz, quizzesSnippets }
) => {
  switch (type) {
    case "init":
      return quiz;
    case "push":
      createQuizFirebase(user, quiz, quizzesSnippets);
      return [...state, quiz];
    case "update":
      updateQuizFirebase(user, quiz, quizzesSnippets);
      return state.map((q) => (q.id === quiz.id ? quiz : q));
    case "delete":
      deleteQuizFirebase(user, quiz, quizzesSnippets);
      return state.filter((q) => q.id !== quiz.id);
    default:
      throw new Error();
  }
};
