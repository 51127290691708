import React from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

export const useGoogleAnalytics = () => {
  const location = useLocation();
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("G-N77S2XYDV0");
      console.log("iniciado GA");
    }
    setInitialized(true);
  }, []);

  React.useEffect(() => {
    if (initialized) {
      console.log("iniciado GA");
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [initialized, location]);
};
