import React from "react";
import dayjs from "dayjs";

import { UserContext } from "./UserContext";
import { newId } from "../Utils/newId";
import {
  getCalendarsFirebase,
  createCalendarFirebase,
  getEventsFirebase,
} from "../Utils/firebaseCalendar";

import { savedCalendarsReducer, savedEventsReducer } from "./calendarReducer";

export const CalendarContext = React.createContext();

export const CalendarStorage = ({ children }) => {
  const { user } = React.useContext(UserContext);
  const [loadingCalendar, setLoadingCalendar] = React.useState(null);
  const labelsClasses = ["study", "diet", "fit"];
  const calendarColors = [
    "color0",
    "color1",
    "color2",
    "color3",
    "color4",
    "color5",
    "color6",
    "color7",
    "color8",
    "color9",
    "color10",
    "color11",
    "color12",
    "color13",
  ];
  const [monthIndex, setMonthIndex] = React.useState(dayjs().month()); // EXCLUIR
  const [smallCalendarMonth, setSmallCalendarMonth] = React.useState(
    dayjs().month()
  );
  const [daySelected, setDaySelected] = React.useState(dayjs());
  const [showEventModal, setShowEventModal] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [selectedCalendar, setSelectedCalendar] = React.useState(null);
  const [showCalendarModal, setShowCalendarModal] = React.useState(false);
  const [view, setView] = React.useState("month");

  const [savedCalendars, dispatchCalendar] = React.useReducer(
    savedCalendarsReducer,
    []
  );

  const [savedEvents, dispatchCalEvent] = React.useReducer(
    savedEventsReducer,
    []
  );

  React.useEffect(() => {
    setLoadingCalendar(true);
  }, []);

  // POPULATE CALENDARS AND EVENTS

  React.useEffect(() => {
    if (user) {
      if (savedCalendars.length === 0) {
        const calendarDefault = {
          name: "Events",
          description: "",
          checked: true,
          color: "color0",
          id: newId(),
        };
        getCalendarsFirebase(user).then(function (result) {
          if (result[0]) {
            dispatchCalendar({ type: "init", payload: result });
          } else {
            createCalendarFirebase(user, calendarDefault);
            dispatchCalendar({ type: "init", payload: [calendarDefault] });
          }
          console.log("init calendars loaded");
        });
      }

      if (savedEvents.length === 0) {
        getEventsFirebase(user).then(function (result) {
          dispatchCalEvent({ type: "init", payload: result });
          console.log("init events loaded");
          setLoadingCalendar(false);
        });
      }
    } else {
      return null;
    }
  }, [user, savedCalendars.length, savedEvents.length]);

  // UPDATE EVENTS

  const filteredEvents = React.useMemo(() => {
    // Trocar cor de todos os eventos
    savedEvents.map((evt, i) => (
      <React.Fragment key={i}>
        {savedCalendars.map(
          (cal) => cal.id === evt.calendar.id && (evt.calendar = cal)
        )}
      </React.Fragment>
    ));
    return savedEvents;
  }, [savedEvents, savedCalendars]);

  React.useEffect(() => {
    if (!showEventModal) {
      setSelectedEvent(null);
    }
  }, [showEventModal]);

  React.useEffect(() => {
    if (!showCalendarModal) {
      setSelectedCalendar(null);
    }
  }, [showCalendarModal]);

  return (
    <CalendarContext.Provider
      value={{
        labelsClasses,
        calendarColors,
        monthIndex,
        setMonthIndex,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daySelected,
        setDaySelected,
        showEventModal,
        setShowEventModal,
        dispatchCalEvent,
        selectedEvent,
        setSelectedEvent,
        savedEvents,
        filteredEvents,
        dispatchCalendar,
        savedCalendars,
        selectedCalendar,
        setSelectedCalendar,
        showCalendarModal,
        setShowCalendarModal,
        loadingCalendar,
        view,
        setView,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};
