import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./StartFlashcard.module.css";
import { FlashcardContext } from "../../Context/FlashcardContext";
import { UserContext } from "../../Context/UserContext";
import Head from "../../Utils/Head";

export const ReviewFlashcard = () => {
  const { user } = React.useContext(UserContext);
  const { cardsReview, flashcardsReview, dispatchFlashcardsReview } =
    React.useContext(FlashcardContext);
  const navigate = useNavigate();
  const params = useParams();
  const [flip, setFlip] = React.useState(false);
  const [indexCard, setIndexCard] = React.useState(0);
  const [endDeck, setEndDeck] = React.useState(false);
  const [cardsRev, setCardsRev] = React.useState([]);

  function handleNextCard() {
    if (cardsReview.length > indexCard + 1) {
      setIndexCard(indexCard + 1);
      setFlip(false);
    } else {
      setEndDeck(true);
    }
  }

  function handleReviewCard() {
    let newCardReview = {
      id: cardsReview[indexCard].id,
      deckTitle: cardsReview[indexCard].deckTitle,
      front: cardsReview[indexCard].front,
      back: cardsReview[indexCard].back,
    };

    setCardsRev([...cardsRev, newCardReview]);
    if (cardsReview.length > indexCard + 1) {
      setIndexCard(indexCard + 1);
      setFlip(false);
    } else {
      setEndDeck(true);
    }
  }

  function updateFlashcardsReview() {
    let updatedFlashcardsReview = flashcardsReview;
    if (cardsRev.length > 0) {
      updatedFlashcardsReview[params.id] = cardsRev;
    } else {
      delete updatedFlashcardsReview[params.id];
    }

    dispatchFlashcardsReview({
      type: "update",
      user: user,
      payload: updatedFlashcardsReview,
    });

    navigate("/");
  }

  if (Array.isArray(cardsReview) && !cardsReview.length) {
    return <>{navigate("/")}</>;
  } else {
    return (
      <>
        <Head title={`Review Deck`} description={`Review Deck - Mairimed`} />
        <div className={css.wrapper}>
          <div className={css.body}>
            <h2>Review</h2>
            <div className={css.options}></div>
            <div className={css.cardContainer}>
              {!endDeck ? (
                <>
                  <div
                    className={`${css.card} ${flip ? css.flip : ""}`}
                    onClick={() => setFlip(!flip)}
                  >
                    <div className={css.front}>
                      {cardsReview[indexCard].front}
                    </div>
                    <div className={css.back}>
                      {cardsReview[indexCard].back}
                    </div>
                  </div>
                  <div className={css.control}>
                    <p>
                      {indexCard + 1} / {cardsReview.length}
                    </p>
                  </div>
                  {flip ? (
                    <div className={css.options}>
                      <>
                        <button
                          className="deleteButton"
                          onClick={() => handleReviewCard()}
                        >
                          Review
                        </button>
                        <button
                          className="createNewButton"
                          onClick={() => handleNextCard()}
                        >
                          Next
                        </button>
                      </>
                    </div>
                  ) : (
                    <div style={{ marginTop: "2.8rem" }}></div>
                  )}
                </>
              ) : (
                <>
                  <div className={`${css.card}`}>
                    <div className={css.front}>Deck Ended</div>
                  </div>

                  <button
                    className="saveButton"
                    onClick={() => updateFlashcardsReview()}
                  >
                    Save results
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
};
