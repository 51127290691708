import {
  createZettelFirebase,
  updateZettelFirebase,
  deleteZettelFirebase,
} from "../Utils/firebaseZettel";

export const savedZettelsSnippetsReducer = (state, { type, payload }) => {
  switch (type) {
    case "init":
      return payload;
    case "push":
      return [...state, payload];
    case "update":
      return state.map((zettel) =>
        zettel.id === payload.id ? payload : zettel
      );
    case "delete":
      return state.filter((zettel) => zettel.id !== payload.id);
    default:
      throw new Error();
  }
};

export const savedZettelsReducer = (
  state,
  { type, user, zettel, zettelsSnippets }
) => {
  switch (type) {
    case "init":
      return zettel;
    case "push":
      createZettelFirebase(user, zettel, zettelsSnippets);
      return [...state, zettel];
    case "update":
      updateZettelFirebase(user, zettel, zettelsSnippets);
      return state.map((z) => (z.id === zettel.id ? zettel : z));
    case "delete":
      deleteZettelFirebase(user, zettel, zettelsSnippets);
      return state.filter((z) => z.id !== zettel.id);
    default:
      throw new Error();
  }
};
