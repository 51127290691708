import React from "react";
import { useNavigate } from "react-router-dom";

import css from "./NewFlashcard.module.css";
import { UserContext } from "../../Context/UserContext";
import { FlashcardContext } from "../../Context/FlashcardContext";
import useForm from "../../Hooks/useForm";
import Input from "../Forms/Input";
import Head from "../../Utils/Head";
import { newId } from "../../Utils/newId";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { Search } from "../Forms/Search";
import { createTagFirebase } from "../../Utils/firebaseUser";

export const NewFlashcard = () => {
  const { user, userData, savedTags, setSavedTags } =
    React.useContext(UserContext);
  const { dispatchDecks, savedFlashcardsSnippets } =
    React.useContext(FlashcardContext);
  const navigate = useNavigate();
  const title = useForm("title");
  const description = useForm(false);
  const [cards, setCards] = React.useState([
    {
      id: newId(),
      index: 1,
      front: "",
      back: "",
    },
    {
      id: newId(),
      index: 2,
      front: "",
      back: "",
    },
    {
      id: newId(),
      index: 3,
      front: "",
      back: "",
    },
  ]);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertCards, setAlertCards] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [showTagsModal, setShowTagsModal] = React.useState(false);
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = React.useState(query || "");
  const [hide, setHide] = React.useState(false);

  const filterTags = (tagsFil, query) => {
    if (!query) {
      return [];
    }
    return tagsFil.filter((tag) => {
      const tagName = tag.title.toLowerCase();
      return tagName.includes(query.toLowerCase());
    });
  };

  const filteredTags = filterTags(savedTags, searchQuery);

  function newCard() {
    let newCards = [
      ...cards,
      {
        id: newId(),
        index: cards.length + 1,
        front: "",
        back: "",
      },
    ];
    return setCards(newCards);
  }

  function deleteCard(index) {
    let array = cards;
    array.splice(index, 1); // 2nd parameter means remove one item only
    return setCards([...array]);
  }

  function updateCard(newName, index, side) {
    const newCards = [...cards];
    if (side === "front") {
      newCards[index].front = newName;
    } else {
      newCards[index].back = newName;
    }

    setCards(newCards);
  }

  function handleValidation() {
    let formIsValid = true;
    const filteredCards = cards.filter(
      (card) => card.front !== "" && card.back !== ""
    );

    if (filteredCards.length < 2) {
      formIsValid = false;
      setAlertCards("You need two cards to create a Deck");
      if (!title.value) {
        setAlertTitle("You need a title to create a Deck");
      } else {
        setAlertTitle("");
      }
    } else if (!title.value) {
      formIsValid = false;
      setAlertTitle("You need a title to create a Deck");
    } else {
      setAlertCards("");
      setAlertTitle("");
    }
    return formIsValid;
  }

  function handleSubmit() {
    const filteredCards = cards.filter(
      (card) => card.front !== "" && card.back !== ""
    );
    const newDeck = {
      id: newId(),
      hide: hide,
      userId: user.uid,
      userName: user.displayName,
      title: title.value,
      description: description.value,
      tag1: tags[0] ? tags[0] : "",
      tag2: tags[1] ? tags[1] : "",
      tag3: tags[2] ? tags[2] : "",
      cards: filteredCards,
    };
    const newFlashcardsSnippet = {
      id: newDeck.id,
      userName: newDeck.userName,
      title: newDeck.title,
      tag1: tags[0] ? tags[0].title : "",
      tag2: tags[1] ? tags[1].title : "",
      tag3: tags[2] ? tags[2].title : "",
      cards: newDeck.cards.length,
    };

    let flashcardsSnippets = savedFlashcardsSnippets;

    flashcardsSnippets.push(newFlashcardsSnippet);

    dispatchDecks({
      type: "push",
      user: user,
      deck: newDeck,
      flashcardsSnippets: flashcardsSnippets,
    });

    navigate("/study/myflashcards");
  }

  function handleNewTag() {
    const newTag = {
      id: newId(),
      title: searchQuery.toLowerCase(),
    };
    setSavedTags([...savedTags, newTag]);
    createTagFirebase(newTag);
    addTag(newTag);
  }

  function addTag(tag) {
    if (tags.indexOf(tag) < 0) {
      setTags([...tags, tag]);
    }
    setShowTagsModal(false);
  }

  function deleteTag(tag) {
    let filteredTags = tags.filter((t) => t.id !== tag.id);
    setTags(filteredTags);
  }

  return (
    <>
      <Head title="Create a new deck" />
      <h3>Create a new deck</h3>
      {alertTitle && <p className={css.error}>{alertTitle}</p>}
      {alertCards && <p className={css.error}>{alertCards}</p>}

      <form className="form">
        <Input
          label="Title"
          type="text"
          name="title"
          required
          maxLength="50"
          {...title}
        />
        <Input
          label="Description"
          type="text"
          name="description"
          maxLength="100"
          {...description}
        />
        {userData.staff && (
          <button
            className={hide ? "defaultActiveButton" : "defaultButton"}
            onClick={(e) => {
              e.preventDefault();
              setHide(!hide);
            }}
          >
            Hide
          </button>
        )}

        <div className={css.tagsContainer}>
          Tags:
          {tags.map((tag) => (
            <div
              className={css.tag}
              key={tag.id}
              onClick={() => deleteTag(tag)}
            >
              <span>{tag.title}</span>
              <i className={`material-icons ${css.tagClose}`}>clear</i>
            </div>
          ))}
        </div>
        <button
          className="defaultButton"
          onClick={(e) => {
            e.preventDefault();
            setShowTagsModal(true);
          }}
          disabled={tags.length > 2 ? true : false}
        >
          Add Tags
        </button>

        {cards.map((card) => (
          <React.Fragment key={card.index}>
            <div className={css.card}>
              <div className={css.header}>
                <div>{card.index}</div>
                {card.index > 2 ? (
                  <div onClick={() => deleteCard(card.index - 1)}>
                    <i className="material-icons materialIcon">delete</i>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className={css.body}>
                <Input
                  label="Front"
                  type="text"
                  name="front"
                  maxLength="200"
                  value={card.front}
                  onChange={(e) => {
                    updateCard(e.target.value, card.index - 1, "front");
                  }}
                />
                <Input
                  label="Back"
                  type="text"
                  name="back"
                  maxLength="200"
                  value={card.back}
                  onChange={(e) => {
                    updateCard(e.target.value, card.index - 1, "back");
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        ))}

        <button
          className={css.card}
          onClick={(e) => {
            e.preventDefault();
            newCard();
          }}
          disabled={cards.length > 500 ? true : false}
        >
          <i className="material-icons materialIcon">add_circle_outline</i> New
          Card
        </button>
        <button
          className={css.saveButton}
          onClick={(e) => {
            e.preventDefault();
            handleValidation() && handleSubmit();
          }}
          disabled={savedFlashcardsSnippets.length < 100 ? false : true}
        >
          Done
        </button>
        {alertTitle && <p className={css.error}>{alertTitle}</p>}
        {alertCards && <p className={css.error}>{alertCards}</p>}
      </form>
      {showTagsModal && (
        <OutsideAlerter setMenuButton={setShowTagsModal}>
          <div className={css.tagsModal}>
            <h3>Tags</h3>
            <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            {filteredTags.map((tag) => (
              <div
                className={css.tag}
                key={tag.id}
                onClick={() => {
                  addTag(tag);
                }}
              >
                {tag.title}
              </div>
            ))}
            <div className={css.modalFooter}></div>
            <button
              className="saveButton"
              onClick={() => handleNewTag()}
              disabled={
                !filteredTags
                  .map((tag) => tag.title)
                  .includes(searchQuery.toLowerCase()) && searchQuery.length > 0
                  ? false
                  : true
              }
            >
              Save New Tag
            </button>
          </div>
        </OutsideAlerter>
      )}
    </>
  );
};
