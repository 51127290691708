import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import css from "./MyFlashcards.module.css";
import { FlashcardContext } from "../../Context/FlashcardContext";
import Head from "../../Utils/Head";
import { UserContext } from "../../Context/UserContext";
import Toolbar from "../Layout/Toolbar";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import Card from "../Layout/Card";

export const MyFlashcards = () => {
  const {
    flashcardsReview,
    dispatchDecks,
    savedFlashcardsSnippets,
    dispatchFlashcardsSnippets,
    setCardsReview,
  } = useContext(FlashcardContext);
  const { user, favoritesFlashcardsId, favoritesFlashcardsTitle } =
    useContext(UserContext);
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [tagSelected, setTagSelected] = useState(null);
  const [userTags, setUserTags] = useState([]);
  const [filteredDecks, setFilteredDecks] = useState(savedFlashcardsSnippets);

  React.useEffect(() => {
    setFilteredDecks(savedFlashcardsSnippets);
    const tagArray = [];
    savedFlashcardsSnippets.forEach((deck) => {
      if (
        deck.tag1 !== "" &&
        tagArray.map((tag) => tag).indexOf(deck.tag1) < 0
      ) {
        tagArray.push(deck.tag1);
      }
      if (
        deck.tag2 !== "" &&
        tagArray.map((tag) => tag).indexOf(deck.tag2) < 0
      ) {
        tagArray.push(deck.tag2);
      }
      if (
        deck.tag3 !== "" &&
        tagArray.map((tag) => tag).indexOf(deck.tag3) < 0
      ) {
        tagArray.push(deck.tag3);
      }
    });
    setUserTags([...tagArray]);
  }, [savedFlashcardsSnippets]);

  function getReviewTitles() {
    let array = [];
    for (var i in flashcardsReview) {
      array = [...array, i];
    }

    Object.keys(flashcardsReview)
      .map((key) => [Number(key), flashcardsReview[key]][1])
      .forEach((card, idx) => {
        array[idx] =
          card.length > 1
            ? card[0].deckTitle + ": " + card.length + " cards"
            : card[0].deckTitle + ": " + card.length + " card";
      });

    return array.slice(0, 5);
  }

  function getReviewCards(i) {
    let cards = [];
    Object.keys(flashcardsReview)
      .map((key) => [Number(key), flashcardsReview[key]][1])
      .forEach((card) => {
        cards = [...cards, card];
      });
    return cards[i];
  }

  function handleFlashcardNav(i) {
    setCardsReview(getReviewCards(i));
    let array = [];
    for (var deck in flashcardsReview) {
      array = [...array, deck];
    }
    navigate(`/study/reviewflashcards/${array[i]}`);
  }

  function resetTags() {
    setTagSelected(null);
    setFilteredDecks(savedFlashcardsSnippets);
  }

  function filterDecks(tag) {
    setTagSelected(tag);
    let arrayMaps = savedFlashcardsSnippets.filter(
      (deck) => deck.tag1 === tag || deck.tag2 === tag || deck.tag3 === tag
    );
    setFilteredDecks([...arrayMaps]);
  }

  const actionMenu = [
    savedFlashcardsSnippets.length < 100
      ? {
          name: "add",
          type: "alert",
          onClick: () => navigate("/study/newflashcard"),
        }
      : {
          name: "add",
          type: "primary",
          onClick: () => alert("Maximum capacity of itens (100)"),
        },

    selected
      ? { name: "delete", type: "alert", onClick: () => setDeleteModal(true) }
      : {
          name: "delete",
          type: "primary",
          onClick: () => alert("Select an item"),
        },
  ];

  function deleteDeck(deck) {
    const flashcardsSnippets = savedFlashcardsSnippets.filter(
      (d) => d.id !== deck.id
    );
    const cards = deck.cards;

    dispatchFlashcardsSnippets({
      type: "delete",
      payload: deck,
    });

    dispatchDecks({
      type: "delete",
      user: user,
      deck: deck,
      cards: cards,
      flashcardsSnippets: flashcardsSnippets,
    });
    navigate("/study/myflashcards");
  }

  return (
    <>
      <Head title="Flashcards" description="Flashcards - Mairimed" />
      <div className={css.tagsBar}>
        <button
          className={
            tagSelected === null
              ? `defaultActiveButton ${css.tagItem}`
              : `defaultButton ${css.tagItem}`
          }
          onClick={() => resetTags()}
        >
          All tags
        </button>
        {userTags.map((tag, i) => (
          <button
            key={i}
            onClick={() => filterDecks(tag)}
            className={
              tagSelected === tag
                ? `defaultActiveButton ${css.tagItem}`
                : `defaultButton ${css.tagItem}`
            }
          >
            {tag}
          </button>
        ))}
      </div>

      <Toolbar
        list={actionMenu}
        type="alert"
        location={["horizontal", "right", "top"]}
      />

      <div className={css.list}>
        <div className={css.card}>
          <h4>5 Review</h4>
          {flashcardsReview &&
            getReviewTitles().map((title, i) => (
              <p
                key={i}
                className={css.cardItem}
                onClick={() => handleFlashcardNav(i)}
              >
                {title}
              </p>
            ))}
        </div>
        <div className={css.card}>
          <h4>5 Favorites</h4>
          {favoritesFlashcardsTitle.map((map, i) => (
            <p
              key={favoritesFlashcardsId[i]}
              className={css.cardItem}
              onClick={() =>
                navigate(`/study/flashcard/${favoritesFlashcardsId[i]}`)
              }
            >
              {map}
            </p>
          ))}
        </div>
      </div>
      <div className={css.list}>
        {filteredDecks
          .sort((a, b) => a.title.charCodeAt(0) - b.title.charCodeAt(0))
          .map((deck) => (
            <div key={deck.id}>
              <Card
                onClick={() => setSelected(deck)}
                onDoubleClick={() => navigate(`/study/flashcard/${deck.id}`)}
                isSelected={deck.id === selected.id}
                icon="layers"
                title={deck.title}
                items={`${deck.cards} cards`}
                tag1={deck.tag1 && deck.tag1}
                tag2={deck.tag2 && deck.tag2}
                tag3={deck.tag3 && deck.tag3}
                user={deck.userName}
              />
            </div>
          ))}
      </div>
      {deleteModal && (
        <OutsideAlerter setMenuButton={setDeleteModal}>
          <div className={css.deleteModal}>
            <h3>Delete {selected.title}?</h3>
            <p>
              You are about to delete this deck and all of its data. No one will
              be able to access this deck ever again.
            </p>
            <div>
              <button
                className="defaultButton"
                onClick={() => setDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className="deleteButton"
                onClick={() => {
                  deleteDeck(selected);
                  setDeleteModal(false);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </OutsideAlerter>
      )}
    </>
  );
};
