export const listIcons = [
  "---",
  // SIGNS
  "clear",
  "done",
  "arrow_downward",
  "arrow_upward",
  "broken_image",
  "add_circle_outline",
  "remove_circle_outline",
  "delete",
  "emoji_events",
  "star",
  "savings",
  "paid",
  "credit_card",
  "payments",
  "local_atm",
  "account_balance_wallet",
  "attach_money",
  "money_off_csred",
  "pan_tool",
  "pets",
  "thumb_up_alt",
  "thumb_down",
  "thumbs_up_down",
  "swap_vert",
  "north_east",
  "question_mark",
  "fullscreen",
  "open_in_full",
  "close_fullscreen",
  "vertical_align_center",
  "change_circle",
  "rocket_launch",
  "accessible",
  "tour",
  "outlet",
  "campaign",
  "school",
  "notifications",
  "notifications_off",
  "person",
  "share",
  "construction",
  "psychology",
  "water_drop",
  "precision_manufacturing",
  "cake",
  "coronavirus",
  "sports_soccer",
  "recycling",
  "luggage",
  "sports",
  "sick",
  "outdoor_grill",
  "social_distance",
  "heart_broken",
  "cruelty_free",
  "surfing",
  "sports_motorsports",
  "kayaking",
  "flag",
  "push_pin",
  "content_cut",
  "biotech",
  "weekend",
  "camera_alt",
  "landscape",
  "healing",
  "color_lens",
  "filter_vintage",
  "flare",
  // WEATHER
  "wb_incandescent",
  "light_mode",
  "brightness_5",
  "nightlight_round",
  "bedtime",
  "beach_access",
  "co2",
  "thunderstorm",
  "scuba_diving",
  "cyclone",
  "tsunami",
  "bolt",
  "filter_1",
  "filter_2",
  "filter_3",
  "filter_4",
  "filter_5",
  "shutter_speed",
  "call",
  "location_on",
  "vpn_key",
  "hourglass_bottom",
  "stay_current_portrait",
  "map",
  "flight",
  "directions_car",
  "directions_run",
  "lunch_dining",
  "local_fire_department",
  "celebration",
  "local_cafe",
  "delivery_dining",
  "directions_bike",
  "local_grocery_store",
  "train",
  "hotel",
  "local_florist",
  "park",
  "local_gas_station",
  "electrical_services",
  "local_bar",
  "traffic",
  "liquor",
  "directions_boat",
  "diamond",
  "ramen_dining",
  "sailing",
  "local_pizza",
  "emergency",
  "festival",
  "sos",
  "hearing",
  "attach_file",
  "attach_money",
  "subscript",
  "do_disturb",
  // COMUNICATION
  "ring_volume",
  "speaker_phone",
  "headset_mic",
  "headset_off",
  "phone_in_talk",
  "phone_disabled",
  "question_answer",
  "speaker",
  "voicemail",
  "add_ic_call",
  "contacts",
  "alternate_email",
  "battery_charging_full",
  "medication",
  "signal_wifi_statusbar_4_bar",
  "usb",
  "access_alarm",
  "business_center",
  "storefront",
  "house",
  "ac_unit",
  "free_breakfast",
  "bathtub",
  "all_inclusive",
  "fitness_center",
  "grass",
  "kitchen",
  "podcasts",
  "oil_barrel",
  "energy_savings_leaf",
  "anchor",
  "redeem",
  "quiz",
  // TOOLS
  "build",
  "hardware",
  "architecture",
  "square_foot",
  "auto_fix_high",
  "pan_tool_alt",
  "history_edu",
  "brush",
  "create",
  "movie_creation",
  "expand",
  "timeline",
  "remove_red_eye",
  "health_and_safety",
  "balance",
  // PEOPLE
  "personal_injury",
  "groups",
  "engineering",
  "emoji_emotions",
  "accessibility_new",
  "sentiment_very_dissatisfied",
  "people_outline",
  "person_search",
  "person_add_alt",
  "record_voice_over",
  "person_off",
  "contact_mail",
  "assignment_ind",
  "supervisor_account",
  "escalator_warning",
  "pool",
  "reduce_capacity",
  "nature_people",
  "boy",
  "girl",
  "elderly",
  "elderly_woman",
  "face",
  "hiking",
  "emoji_people",
  "female",
  "male",
  "self_improvement",
  "rowing",
  "follow_the_signs",
  "sports_martial_arts",
  "sports_kabaddi",
  "snowshoeing",
  "group_work",
  "child_friendly",
  "child_care",
  "baby_changing_station",
  "pregnant_woman",
  "crib",
  "no_stroller",
  "bedroom_baby",
  // GAMES
  "gamepad",
  "sports_esports",
  "extension",
  "casino",
  "videogame_asset_off",
  "sports_tennis",
  "sports_cricket",
  "sports_football",
  "public",
  "language",
  "travel_explore",
  "vpn_lock",
  "public_off",
  // TRANSPORTS
  "local_shipping",
  "airport_shuttle",
  "agriculture",
  // OTHERS
  "gavel",
  "join_full",
  "disc_full",
  "masks",
];
