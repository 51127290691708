import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import css from "./NewZettel.module.css";
import { UserContext } from "../../Context/UserContext";
import { ZettelContext } from "../../Context/ZettelContext";
import Head from "../../Utils/Head";
import { newId } from "../../Utils/newId";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { Search } from "../Forms/Search";
import { getUserZettelsFirebase } from "../../Utils/firebaseZettel";
import { createTagFirebase } from "../../Utils/firebaseUser";

export const NewZettel = () => {
  const { user, savedTags, setSavedTags } = useContext(UserContext);
  const { dispatchZettels, savedZettelsSnippets } = useContext(ZettelContext);
  const navigate = useNavigate();
  const [savedZettels, setSavedZettels] = useState("");
  const [title, setTitle] = useState("");
  const [reference, setReference] = useState("");
  const [text, setText] = useState("");
  const [tags, setTags] = useState([]);
  const [next, setNext] = useState("");
  const [prev, setPrev] = useState("");
  const [more, setMore] = useState([]);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showMoreModalSelect, setShowMoreModalSelect] = useState(false);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [showPrevModal, setShowPrevModal] = useState(false);
  const [showNextModal, setShowNextModal] = useState(false);
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");
  const [alertTitle, setAlertTitle] = useState("");

  useEffect(() => {
    setSavedZettels(getUserZettelsFirebase(user));
  }, [user]);

  const filterTags = (tagsFil, query) => {
    if (!query) {
      return [];
    }
    return tagsFil.filter((tag) => {
      const tagName = tag.title.toLowerCase();
      return tagName.includes(query.toLowerCase());
    });
  };

  const filteredTags = filterTags(savedTags, searchQuery);

  const filterZettels = (zettelsFil, query) => {
    if (!query) {
      return savedZettels;
    }
    return zettelsFil.filter((zet) => {
      const zettelTitle = zet.title.toLowerCase();
      // RETIRAR PREV E NEXT JA SELECIONADOS
      return zettelTitle.includes(query);
    });
  };

  const filteredZettels = filterZettels(savedZettels, searchQuery);

  function handleValidation() {
    let formIsValid = true;

    if (!title) {
      formIsValid = false;
      setAlertTitle("You need a title to create a Zettel");
    } else {
      setAlertTitle("");
    }
    return formIsValid;
  }

  function handleSubmit() {
    const newZettel = {
      id: newId(),
      userId: user.uid,
      userName: user.displayName,
      title,
      reference,
      text,
      tag1: tags[0] ? tags[0] : "",
      tag2: tags[1] ? tags[1] : "",
      tag3: tags[2] ? tags[2] : "",
      next,
      prev,
      more,
    };
    const newZettelSnippet = {
      id: newZettel.id,
      userName: newZettel.userName,
      title: newZettel.title,
      tag1: tags[0] ? tags[0].title : "",
      tag2: tags[1] ? tags[1].title : "",
      tag3: tags[2] ? tags[2].title : "",
      zettels:
        1 +
        newZettel.next.length +
        newZettel.prev.length +
        newZettel.more.length,
    };

    let zettelsSnippets = savedZettelsSnippets;

    zettelsSnippets.push(newZettelSnippet);

    dispatchZettels({
      type: "push",
      user: user,
      zettel: newZettel,
      zettelsSnippets: zettelsSnippets,
    });
    navigate("/study/myzettels");
  }

  function handleNewTag() {
    const newTag = {
      id: newId(),
      title: searchQuery.toLowerCase(),
    };
    setSavedTags([...savedTags, newTag]);
    createTagFirebase(newTag);
    addTag(newTag);
  }

  function addTag(tag) {
    if (tags.indexOf(tag) < 0) {
      setTags([...tags, tag]);
    }
    setSearchQuery("");
    setShowTagsModal(false);
  }

  function deleteTag(tag) {
    let filteredTags = tags.filter((t) => t.id !== tag.id);
    setTags(filteredTags);
  }

  function addPrev(zettel) {
    if (prev !== zettel) {
      setPrev(zettel);
    }
    setShowPrevModal(false);
  }

  function addNext(zettel) {
    if (next !== zettel) {
      setNext(zettel);
    }
    setShowNextModal(false);
  }

  function addMore(zettel) {
    if (!more.map((zet) => zet.id).includes(zettel.id)) {
      const arrayMore = more;
      arrayMore.push(zettel);
      setMore(arrayMore);
    }
    setShowMoreModalSelect(false);
  }

  function delMore(zettel) {
    const delAtrrayMore = more.filter((zet) => zet.id !== zettel.id);
    setMore(delAtrrayMore);
    setShowMoreModal(false);
  }

  function handleNavigate(zettel) {
    return navigate(`/study/zettel/${zettel.id}`);
  }

  return (
    <>
      <Head title="Create a new zettel" />
      <h3>Create new zettel</h3>
      {alertTitle && <p className={css.error}>{alertTitle}</p>}

      <div className={css.card}>
        <form className="form">
          <div className={css.options}>
            <input
              type="text"
              name="title"
              placeholder="Title"
              value={title}
              required
              onChange={(e) => setTitle(e.target.value)}
            />
            <div
              onClick={(e) => {
                e.preventDefault();
                handleValidation() && handleSubmit();
              }}
            >
              <i className="material-icons materialIcon">done</i>
            </div>
          </div>
          <div className={css.head}>
            <input
              type="text"
              name="reference"
              placeholder="Reference"
              value={reference}
              required
              onChange={(e) => setReference(e.target.value)}
            />
          </div>
          <textarea
            placeholder="Your main text here"
            name="zettel"
            rows="10"
            cols="70"
            maxLength="200"
            value={text}
            required
            onChange={(e) => setText(e.target.value)}
          />
        </form>
        <div className={css.footer}>
          <button
            className="defaultButton"
            disabled={tags.length > 2 ? true : false}
            onClick={(e) => {
              e.preventDefault();
              setShowTagsModal(true);
            }}
          >
            Tags
          </button>
          <div className={css.tagsContainer}>
            {tags.map((tag) => (
              <div
                className={css.tag}
                key={tag.id}
                onClick={() => deleteTag(tag)}
              >
                <span>{tag.title}</span>
                <i className={`material-icons ${css.tagClose}`}>clear</i>
              </div>
            ))}
          </div>
          <button
            className="defaultButton"
            onClick={(e) => {
              e.preventDefault();
              setShowMoreModalSelect(true);
            }}
          >
            More
          </button>
        </div>
        <div className={css.footer}>
          <button
            className="defaultButton"
            onClick={(e) => {
              e.preventDefault();
              setShowPrevModal(true);
            }}
          >
            Prev
          </button>

          <div className={css.prev} onClick={() => handleNavigate(prev)}>
            <i className="material-icons materialIcon">chevron_left</i>
            <div>{prev.title}</div>
          </div>

          <div
            className={css.more}
            onClick={(e) => {
              e.preventDefault();
              setShowMoreModal(true);
            }}
          >
            {more.length + " more"}
          </div>

          <div className={css.next} onClick={() => handleNavigate(next)}>
            <div>{next.title}</div>
            <i className="material-icons materialIcon">chevron_right</i>
          </div>

          <button
            className="defaultButton"
            onClick={(e) => {
              e.preventDefault();
              setShowNextModal(true);
            }}
          >
            Next
          </button>
        </div>
      </div>
      {showTagsModal && (
        <OutsideAlerter setMenuButton={setShowTagsModal}>
          <div className={css.tagsModal}>
            <h3>Tags</h3>
            <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            {filteredTags.map((tag) => (
              <div
                className={css.tag}
                key={tag.id}
                onClick={() => {
                  addTag(tag);
                }}
              >
                {tag.title}
              </div>
            ))}
            <div className={css.modalFooter}></div>
            <button
              className="saveButton"
              onClick={() => handleNewTag()}
              disabled={
                !filteredTags
                  .map((tag) => tag.title)
                  .includes(searchQuery.toLowerCase()) && searchQuery.length > 0
                  ? false
                  : true
              }
            >
              Save New Tag
            </button>
          </div>
        </OutsideAlerter>
      )}
      {showMoreModalSelect && (
        <OutsideAlerter setMenuButton={setShowMoreModalSelect}>
          <div className={css.tagsModal}>
            <h3>More</h3>
            <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            {filteredZettels.map((zet) => (
              <div
                className={css.tag}
                key={zet.id}
                onClick={() => addMore(zet)}
              >
                {zet.title}
              </div>
            ))}
            <div className={css.modalFooter}></div>
          </div>
        </OutsideAlerter>
      )}
      {showMoreModal && (
        <OutsideAlerter setMenuButton={setShowMoreModal}>
          <div className={css.tagsModal}>
            <h3>More</h3>
            {more.map((zet) => (
              <div className={css.tagsContainer} key={zet.id}>
                <div className={css.tag} onClick={() => handleNavigate(zet)}>
                  {zet.title}
                </div>
                <i
                  className="material-icons materialIcon"
                  onClick={() => delMore(zet)}
                >
                  delete
                </i>
              </div>
            ))}
          </div>
        </OutsideAlerter>
      )}
      {showPrevModal && (
        <OutsideAlerter setMenuButton={setShowPrevModal}>
          <div className={css.tagsModal}>
            <h3>Select Prev Zettel</h3>
            <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            {filteredZettels.map((zet) => (
              <div
                className={css.tag}
                key={zet.id}
                onClick={() => addPrev(zet)}
              >
                {zet.title}
              </div>
            ))}
          </div>
        </OutsideAlerter>
      )}
      {showNextModal && (
        <OutsideAlerter setMenuButton={setShowNextModal}>
          <div className={css.tagsModal}>
            <h3>Select Next Zettel</h3>
            <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            {filteredZettels.map((zet) => (
              <div
                className={css.tag}
                key={zet.id}
                onClick={() => addNext(zet)}
              >
                {zet.title}
              </div>
            ))}
          </div>
        </OutsideAlerter>
      )}
    </>
  );
};
