import React from "react";

import css from "./Connection.module.css";

function Connection(props) {
  const getElement = (element) => {
    let className = css.connection;
    if (element.level === 0) {
      return null;
    }
    className += " " + css[element.color];
    const curveX = element.isLeftSide ? -200 : 200;
    const curveY = element.isUp ? 0 : 0;
    const d1 = element.isLeftSide
      ? `M ${
          element.level > 1
            ? element.px - element.pName.length * 15
            : element.px
        } ${element.py} C ${element.px + curveX - element.pName.length * 15} ${
          element.py + curveY
        }, ${element.px + curveX - element.pName.length * 6} ${
          element.py + curveY
        },  ${element.x + 200} ${element.y}`
      : `M ${
          element.level > 1
            ? element.px + element.pName.length * 15
            : element.px
        } ${element.py} C ${element.px + curveX + element.pName.length * 10} ${
          element.py + curveY
        }, ${element.px + curveX + element.pName.length * 6} ${
          element.py + curveY
        }, ${element.x - 200} ${element.y}`;
    const d2 = element.isLeftSide
      ? `M ${element.x - element.title.length * 15}  ${element.y} C ${
          element.x - 200
        } ${element.y}, ${element.x + 200} ${element.y}, ${element.x + 200}   ${
          element.y
        }`
      : `M ${element.x - 200} ${element.y} C ${element.x - 200} ${element.y}, ${
          element.x - 200
        } ${element.y}, ${element.x + element.title.length * 15} ${element.y}`;
    return (
      <React.Fragment key={element.id}>
        <path d={d1} className={className} />
        <path id={element.id} d={d2} className={className} />
      </React.Fragment>
    );
  };
  return props.list.map((element) => getElement(element));
}

export default Connection;
