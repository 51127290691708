import React, { useContext } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { UserContext } from "../../Context/UserContext";
import css from "./Discover.module.css";
import Head from "../../Utils/Head";
import CardCategory from "../Layout/CardCategory";

export const Discover = () => {
  const navigate = useNavigate();
  const { savedTags } = useContext(UserContext);
  const [filteredTags, setFilteredTags] = React.useState(savedTags);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [selected, setSelected] = React.useState("");

  React.useEffect(() => {
    setFilteredTags(savedTags);
  }, [savedTags]);

  const useNavigateParams = () => {
    return (url, params) => {
      const path = generatePath(":url?:queryString", {
        url,
        queryString: params,
      });
      navigate(path);
    };
  };

  const navigateSearch = useNavigateParams();

  const navigateHandler = () => {
    navigateSearch("discover", `search_query=${searchQuery}`);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    const tagArray = savedTags.filter((tag) =>
      tag.title.includes(e.target.value)
    );
    setFilteredTags(tagArray);
  };

  return (
    <>
      <Head title="Discover" description="Discover - Mairimed" />
      <div className={css.list}>
        <CardCategory
          icon={"route"}
          title={"Mindmaps"}
          onClick={() => setSelected("mindmaps")}
          onDoubleClick={() => navigate("/discover/mindmaps")}
          isSelected={selected === "mindmaps"}
        />
        <CardCategory
          icon={"layers"}
          title={"Flashcards"}
          onClick={() => setSelected("flashcards")}
          onDoubleClick={() => navigate("/discover/flashcards")}
          isSelected={selected === "flashcards"}
        />
        <CardCategory
          icon={"quiz"}
          title={"Quiz"}
          onClick={() => setSelected("quiz")}
          onDoubleClick={() => navigate("/discover/quiz")}
          isSelected={selected === "quiz"}
        />
        {/* <CardCategory
          icon={"notes"}
          title={"Zettels"}
          onClick={() => setSelected("zettels")}
          onDoubleClick={() => navigate("/discover/zettels")}
          isSelected={selected === "zettels"}
        /> */}
      </div>
      <form
        action="/"
        method="get"
        onSubmit={(e) => {
          e.preventDefault();
          navigateHandler();
        }}
        className={css.topbarItens}
      >
        <label htmlFor="header-search">
          <span className={css.visuallyHidden}>Search</span>
        </label>
        <input
          type="text"
          id="header-search"
          placeholder="Search tag..."
          autoComplete="off"
          onInput={(e) => handleSearch(e)}
        />
      </form>
      {/* ALL TAGS */}
      <div>
        {filteredTags.map((tag) => (
          <button
            key={tag.id}
            onClick={() => navigate(`/discover/${selected}/${tag.title}`)}
            className={`defaultButton ${css.tagItem}`}
            disabled={!selected}
          >
            {tag.title}
          </button>
        ))}
      </div>
    </>
  );
};
