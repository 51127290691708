import React from "react";
import dayjs from "dayjs";

import styles from "./EventModal.module.css";
import { CalendarContext } from "../../Context/CalendarContext";
import { UserContext } from "../../Context/UserContext";
import SmallCalendarModal from "./SmallCalendarModal";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import TimeModal from "./TimeModal";
import RepeatModal from "./RepeatModal";
import EventCalendarModal from "./EventCalendarModal";
import { newId } from "../../Utils/newId";

export default function EventModal() {
  const {
    setShowEventModal,
    daySelected,
    dispatchCalEvent,
    selectedEvent,
    savedCalendars,
    filteredEvents,
  } = React.useContext(CalendarContext);
  const { user } = React.useContext(UserContext);
  const [dayStart, setDayStart] = React.useState(
    selectedEvent ? selectedEvent.dayStart : daySelected
  );
  const [dayEnd, setDayEnd] = React.useState(
    selectedEvent ? selectedEvent.dayEnd : daySelected
  );
  const [title, setTitle] = React.useState(
    selectedEvent ? selectedEvent.title : ""
  );
  const [description, setDescription] = React.useState(
    selectedEvent ? selectedEvent.description : ""
  );
  const [selectedCalendar, setSelectedCalendar] = React.useState(
    selectedEvent ? selectedEvent.calendar : savedCalendars[0]
  );
  const [allDay, setAllDay] = React.useState(
    selectedEvent ? selectedEvent.allDay : true
  );
  const [rruleName, setRruleName] = React.useState(
    selectedEvent ? selectedEvent.rruleName : "Does not repeat"
  );
  const [recurChange, setRecurChange] = React.useState(false);
  const [freq, setFreq] = React.useState(
    selectedEvent ? selectedEvent.freq : ""
  );
  const [interval, setInterval] = React.useState(
    selectedEvent ? selectedEvent.interval : ""
  );
  const [smallCalendarStart, setSmallCalendarStart] = React.useState(false);
  const [smallCalendarEnd, setSmallCalendarEnd] = React.useState(false);
  const [timeModalStart, setTimeModalStart] = React.useState(false);
  const [timeModalEnd, setTimeModalEnd] = React.useState(false);
  const [repeatModal, setRepeatModal] = React.useState(false);
  const [calendarModal, setCalendarModal] = React.useState(false);
  const [deleteRecurModal, setDeleteRecurModal] = React.useState(false);
  const [changeRecurModal, setChangeRecurModal] = React.useState(false);
  const [editRecur, setEditRecur] = React.useState(false);
  const [editRecurModal, setEditRecurModal] = React.useState(false);
  const [updateTitle, setUpdateTitle] = React.useState(false);
  const [updateAllDay, setUpdateAllDay] = React.useState(false);
  const [updateDescription, setUpdateDescription] = React.useState(false);
  const [updateCalendar, setUpdateCalendar] = React.useState(false);
  const [updateTimeStart, setUpdateTimeStart] = React.useState(false);
  const [updateTimeEnd, setUpdateTimeEnd] = React.useState(false);
  const [updateDayStart, setUpdateDayStart] = React.useState(false);
  const [updateDayEnd, setUpdateDayEnd] = React.useState(false);

  function checkRecurEvents() {
    var filtered = filteredEvents.filter((evt) => evt.id !== selectedEvent.id);
    if (filtered.map((e) => e.rruleId).indexOf(selectedEvent.rruleId) < 0) {
      return false;
    } else {
      return true;
    }
  }

  function handleValidation() {
    let formIsValid = true;
    const dayTimeStart = new Date(dayStart);
    const dayTimeEnd = new Date(dayEnd);
    if (allDay) {
      dayTimeStart.setHours(0);
      dayTimeStart.setMinutes(0);
      dayTimeEnd.setHours(0);
      dayTimeEnd.setMinutes(0);
    }
    if (dayTimeStart > dayTimeEnd) {
      formIsValid = false;
    }
    return formIsValid;
  }

  function handleSubmit() {
    var rruleId = selectedEvent ? selectedEvent.rruleId : newId();

    const calendarEvent = {
      user: user.uid,
      title,
      description,
      calendar: selectedCalendar,
      dayStart: dayStart.valueOf(),
      dayEnd: dayEnd.valueOf(),
      allDay,
      id: selectedEvent ? selectedEvent.id : newId(),
      freq: freq,
      interval: interval,
      until: null,
      rruleName: rruleName,
      rruleId,
      location: null,
      url: null,
      geo: null,
      categories: null,
      status: null,
      busyStatus: null,
      organizer: null,
      attendees: null,
    };
    if (selectedEvent) {
      dispatchCalEvent({ user: user, type: "update", payload: calendarEvent });
    } else {
      dispatchCalEvent({ user: user, type: "push", payload: calendarEvent });
    }
    handleRrule(calendarEvent, rruleId);
    setShowEventModal(false);
  }

  function handleChangeRuleFoll() {
    var rruleId = selectedEvent.rruleId;

    const calendarEvent = {
      title,
      description,
      calendar: selectedCalendar,
      dayStart: dayStart.valueOf(),
      dayEnd: dayEnd.valueOf(),
      allDay,
      id: selectedEvent.id,
      freq: freq,
      interval: interval,
      until: null,
      rruleName: rruleName,
      rruleId,
      location: null,
      url: null,
      geo: null,
      categories: null,
      status: null,
      busyStatus: null,
      organizer: null,
      attendees: null,
    };
    dispatchCalEvent({
      user: user,
      type: "deleteRecurFollExceptThis",
      payload: calendarEvent,
    });
    handleRrule(calendarEvent, rruleId);
    setShowEventModal(false);
  }

  function handleChangeRuleAll() {
    var rruleId = selectedEvent.rruleId;

    var eventSerie = filteredEvents.filter((e) => e.rruleId === rruleId);

    var firstEvent = eventSerie[0];
    console.log(dayjs(firstEvent.dayStart));
    var dayREnd = firstEvent.dayEnd;
    var dayRStart = firstEvent.dayStart;

    const calendarEvent = {
      title,
      description,
      calendar: selectedCalendar,
      dayStart: firstEvent.dayStart,
      dayEnd: firstEvent.dayEnd,
      allDay,
      id: firstEvent.id,
      freq: freq,
      interval: interval,
      until: null,
      rruleName: rruleName,
      rruleId,
      location: null,
      url: null,
      geo: null,
      categories: null,
      status: null,
      busyStatus: null,
      organizer: null,
      attendees: null,
    };
    dispatchCalEvent({
      user: user,
      type: "deleteRecurAllExceptThis",
      payload: calendarEvent,
    });
    while (dayjs().endOf("year").diff(dayjs(dayREnd), "day") + 10 >= 0) {
      dayRStart = dayjs(dayRStart).add(interval, freq);
      dayREnd = dayjs(dayREnd).add(interval, freq);
      var calendarEventR = {
        id: newId(),
        title,
        description,
        calendar: selectedCalendar,
        dayStart: dayRStart.valueOf(),
        dayEnd: dayREnd.valueOf(),
        allDay,
        freq: freq,
        interval: interval,
        until: null,
        rruleName: rruleName,
        rruleId: rruleId,
        location: null,
        url: null,
        geo: null,
        categories: null,
        status: null,
        busyStatus: null,
        organizer: null,
        attendees: null,
      };

      dispatchCalEvent({ user: user, type: "push", payload: calendarEventR });
    }
    setShowEventModal(false);
  }

  function handleRrule(calendarEvent, rruleId) {
    if (recurChange === "noRepeat") {
      return dispatchCalEvent({
        type: "deleteRecurAllExceptThis",
        payload: calendarEvent,
      });
    } else if (recurChange === true || editRecur === true) {
      var dayRStart = dayStart;
      var dayREnd = dayEnd;

      while (dayjs().endOf("year").diff(dayjs(dayREnd), "day") + 10 >= 0) {
        dayRStart = dayjs(dayRStart).add(interval, freq);
        dayREnd = dayjs(dayREnd).add(interval, freq);
        var calendarEventR = {
          id: newId(),
          title,
          description,
          calendar: selectedCalendar,
          dayStart: dayRStart.valueOf(),
          dayEnd: dayREnd.valueOf(),
          allDay,
          freq: freq,
          interval: interval,
          until: null,
          rruleName: rruleName,
          rruleId: rruleId,
          location: null,
          url: null,
          geo: null,
          categories: null,
          status: null,
          busyStatus: null,
          organizer: null,
          attendees: null,
        };

        dispatchCalEvent({ user: user, type: "push", payload: calendarEventR });
      }
    } else {
      return null;
    }
  }

  function handleSubmitOne() {
    var rruleId = selectedEvent ? selectedEvent.rruleId : newId();

    const calendarEvent = {
      title,
      description,
      calendar: selectedCalendar,
      dayStart: dayStart.valueOf(),
      dayEnd: dayEnd.valueOf(),
      allDay,
      id: selectedEvent ? selectedEvent.id : newId(),
      freq: freq,
      interval: interval,
      until: null,
      rruleName: rruleName,
      rruleId,
      location: null,
      url: null,
      geo: null,
      categories: null,
      status: null,
      busyStatus: null,
      organizer: null,
      attendees: null,
    };
    dispatchCalEvent({ user: user, type: "update", payload: calendarEvent });
    setShowEventModal(false);
  }

  function handleChangeFoll(e) {
    e.preventDefault();
    var rruleId = selectedEvent.rruleId;

    const calendarEvent = {
      title,
      description,
      calendar: selectedCalendar,
      dayStart: dayStart.valueOf(),
      dayEnd: dayEnd.valueOf(),
      allDay,
      id: selectedEvent ? selectedEvent.id : newId(),
      freq: freq,
      interval: interval,
      rruleName: rruleName,
      rruleId,
      location: null,
      url: null,
      geo: null,
      categories: null,
      status: null,
      busyStatus: null,
      organizer: null,
      attendees: null,
    };

    if (updateTitle) {
      dispatchCalEvent({
        uesr: user,
        type: "updateTitleFoll",
        payload: calendarEvent,
      });
    }
    if (updateDayStart) {
      dispatchCalEvent({
        user: user,
        type: "updateDayStartFoll",
        payload: calendarEvent,
        displacement: dayjs(dayStart).diff(dayjs(daySelected)),
      });
    }
    if (updateDayEnd) {
      dispatchCalEvent({
        user: user,
        type: "updateDayEndFoll",
        payload: calendarEvent,
      });
    }
    if (updateTimeStart) {
      dispatchCalEvent({
        user: user,
        type: "updateTimeStartFoll",
        payload: calendarEvent,
      });
    }
    if (updateTimeEnd) {
      dispatchCalEvent({
        user: user,
        type: "updateTimeEndFoll",
        payload: calendarEvent,
      });
    }
    if (updateAllDay) {
      dispatchCalEvent({
        user: user,
        type: "updateAllDayFoll",
        payload: calendarEvent,
      });
    }
    if (updateDescription) {
      dispatchCalEvent({
        user: user,
        type: "updateDescriptionFoll",
        payload: calendarEvent,
      });
    }
    if (updateCalendar) {
      dispatchCalEvent({
        user: user,
        type: "updateCalendarFoll",
        payload: calendarEvent,
      });
    }
    setShowEventModal(false);
  }

  function handleChangeAll(e) {
    e.preventDefault();
    var rruleId = selectedEvent.rruleId;

    const calendarEvent = {
      title,
      description,
      calendar: selectedCalendar,
      dayStart: dayStart.valueOf(),
      dayEnd: dayEnd.valueOf(),
      allDay,
      id: selectedEvent ? selectedEvent.id : newId(),
      freq: freq,
      interval: interval,
      rruleName: rruleName,
      rruleId,
      location: null,
      url: null,
      geo: null,
      categories: null,
      status: null,
      busyStatus: null,
      organizer: null,
      attendees: null,
    };

    if (updateTitle) {
      dispatchCalEvent({
        user: user,
        type: "updateTitleAll",
        payload: calendarEvent,
      });
    }
    if (updateDayStart) {
      dispatchCalEvent({
        user: user,
        type: "updateDayStartAll",
        payload: calendarEvent,
        displacement: dayjs(dayStart).diff(dayjs(daySelected)),
      });
    }
    if (updateDayEnd) {
      dispatchCalEvent({
        user: user,
        type: "updateDayEndAll",
        payload: calendarEvent,
      });
    }
    if (updateTimeStart) {
      dispatchCalEvent({
        user: user,
        type: "updateTimeStartAll",
        payload: calendarEvent,
      });
    }
    if (updateTimeEnd) {
      dispatchCalEvent({
        user: user,
        type: "updateTimeEndAll",
        payload: calendarEvent,
      });
    }
    if (updateAllDay) {
      dispatchCalEvent({
        user: user,
        type: "updateAllDayAll",
        payload: calendarEvent,
      });
    }
    if (updateDescription) {
      dispatchCalEvent({
        user: user,
        type: "updateDescriptionAll",
        payload: calendarEvent,
      });
    }
    if (updateCalendar) {
      dispatchCalEvent({
        user: user,
        type: "updateCalendarAll",
        payload: calendarEvent,
      });
    }
    setShowEventModal(false);
  }

  function handleDeleteThis(e) {
    e.preventDefault();
    dispatchCalEvent({ user: user, type: "delete", payload: selectedEvent });
    setDeleteRecurModal(false);
    setShowEventModal(false);
  }

  function handleDeleteFoll(e) {
    e.preventDefault();
    dispatchCalEvent({
      user: user,
      type: "deleteRecurFoll",
      payload: selectedEvent,
    });
    setDeleteRecurModal(false);
    setShowEventModal(false);
  }

  function handleDeleteAll(e) {
    e.preventDefault();
    dispatchCalEvent({
      user: user,
      type: "deleteRecurAll",
      payload: selectedEvent,
    });
    setDeleteRecurModal(false);
    setShowEventModal(false);
  }

  return (
    <div className={styles.event}>
      <form className={styles.form}>
        <header className={styles.header}>
          {selectedEvent && (
            <span
              onClick={() => {
                if (checkRecurEvents()) {
                  setDeleteRecurModal(true);
                } else {
                  dispatchCalEvent({
                    user: user,
                    type: "delete",
                    payload: selectedEvent,
                  });
                  setShowEventModal(false);
                }
              }}
              className={`material-icons ${styles.materialIconDelete}`}
            >
              delete
            </span>
          )}

          <span
            onClick={() => setShowEventModal(false)}
            className={`material-icons ${styles.materialIcon} ${styles.materialButton}`}
          >
            close
          </span>
        </header>
        <div className={styles.body}>
          <div></div>
          <input
            type="text"
            name="title"
            placeholder="Add title"
            value={title}
            required
            className={styles.title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            onBlur={() => {
              selectedEvent && checkRecurEvents() && setEditRecur(true);
              selectedEvent && checkRecurEvents() && setUpdateTitle(true);
            }}
            autoComplete="off"
          />

          <span className={`material-icons ${styles.materialIcon}`}>
            schedule
          </span>
          <div>
            <div className={styles.schedule}>
              Start:{" "}
              <input
                type="text"
                name="dayStart"
                placeholder={dayjs(dayStart).format("dddd, MMMM DD")}
                required
                className={styles.date}
                onFocus={(e) => setSmallCalendarStart(true)}
                autoComplete="off"
              />{" "}
              {allDay ? (
                ""
              ) : (
                <input
                  type="text"
                  name="timeStart"
                  placeholder={dayjs(dayStart).format("h:mma")}
                  className={styles.time}
                  onFocus={(e) => setTimeModalStart(true)}
                  autoComplete="off"
                />
              )}
            </div>
            <div className={styles.schedule}>
              End:{" "}
              <input
                type="text"
                name="dayEnd"
                placeholder={dayjs(dayEnd).format("dddd, MMMM DD")}
                className={styles.date}
                onFocus={(e) => setSmallCalendarEnd(true)}
                autoComplete="off"
              />{" "}
              {allDay ? (
                ""
              ) : (
                <input
                  type="text"
                  name="timeEnd"
                  placeholder={dayjs(dayEnd).format("h:mma")}
                  required
                  className={styles.time}
                  onFocus={(e) => setTimeModalEnd(true)}
                  autoComplete="off"
                />
              )}
            </div>
          </div>

          <span></span>
          <label className={styles.allDay}>
            All day
            <input
              type="checkbox"
              checked={allDay}
              onChange={() => {
                setAllDay(!allDay);
                selectedEvent && checkRecurEvents() && setEditRecur(true);
                selectedEvent && checkRecurEvents() && setUpdateAllDay(true);
              }}
            />
            <span className={styles.checkmark}></span>
          </label>

          <span></span>
          <div className={styles.repeatButton}>
            <button
              onClick={(e) => {
                e.preventDefault();
                setRepeatModal(true);
              }}
            >
              {rruleName}
            </button>
            <span
              className={`material-icons ${styles.materialIconRepeat}`}
              onClick={(e) => {
                e.preventDefault();
                setRepeatModal(true);
              }}
            >
              expand_more
            </span>
          </div>

          <span className={`material-icons ${styles.materialIcon}`}>
            segment
          </span>
          <input
            type="text"
            name="description"
            placeholder="Add a description"
            value={description}
            className={styles.description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            onBlur={() => {
              selectedEvent && checkRecurEvents() && setEditRecur(true);
              selectedEvent && checkRecurEvents() && setUpdateDescription(true);
            }}
            autoComplete="off"
          />

          <span className={`material-icons ${styles.materialIcon}`}>
            event_available
          </span>
          <div className={styles.calendar}>
            <div className={styles.repeatButton}>
              {savedCalendars.map((cal) => (
                <React.Fragment key={cal.id}>
                  {selectedCalendar.id === cal.id && (
                    <>
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          setCalendarModal(true);
                        }}
                        className={`${cal.color} ${styles.roundIcon}`}
                      ></span>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setCalendarModal(true);
                        }}
                      >
                        {cal.name}
                      </button>
                    </>
                  )}
                </React.Fragment>
              ))}
              <span
                className={`material-icons ${styles.materialIconRepeat}`}
                onClick={(e) => {
                  e.preventDefault();
                  setCalendarModal(true);
                }}
              >
                expand_more
              </span>
            </div>
          </div>

          {/* MODALS */}

          <span></span>
          <div>
            {smallCalendarStart && (
              <>
                <OutsideAlerter setMenuButton={setSmallCalendarStart}>
                  <nav className={styles.smallCalendarStart}>
                    <SmallCalendarModal
                      dayModalStart={false}
                      dayModal={dayStart}
                      setDayModal={setDayStart}
                      setSmallCalendarModal={setSmallCalendarStart}
                      setEditRecur={setEditRecur}
                      setUpdateDay={setUpdateDayStart}
                      checkRecurEvents={checkRecurEvents}
                      selectedEvent={selectedEvent}
                    />
                  </nav>
                </OutsideAlerter>
              </>
            )}
            {smallCalendarEnd && (
              <>
                <span></span>
                <OutsideAlerter setMenuButton={setSmallCalendarEnd}>
                  <nav className={styles.smallCalendarEnd}>
                    <SmallCalendarModal
                      dayModalStart={dayStart}
                      dayModal={dayEnd}
                      setDayModal={setDayEnd}
                      setSmallCalendarModal={setSmallCalendarEnd}
                      setEditRecur={setEditRecur}
                      setUpdateDay={setUpdateDayEnd}
                      checkRecurEvents={checkRecurEvents}
                      selectedEvent={selectedEvent}
                    />
                  </nav>
                </OutsideAlerter>
              </>
            )}
            {timeModalStart && (
              <>
                <OutsideAlerter setMenuButton={setTimeModalStart}>
                  <nav className={styles.timeModalStart}>
                    <TimeModal
                      dayModal={dayStart}
                      setDayModal={setDayStart}
                      setTimeModal={setTimeModalStart}
                      setEditRecur={setEditRecur}
                      setUpdateTime={setUpdateTimeStart}
                      checkRecurEvents={checkRecurEvents}
                      selectedEvent={selectedEvent}
                    />
                  </nav>
                </OutsideAlerter>
              </>
            )}
            {timeModalEnd && (
              <>
                <span></span>
                <OutsideAlerter setMenuButton={setTimeModalEnd}>
                  <nav className={styles.timeModalEnd}>
                    <TimeModal
                      dayModal={dayEnd}
                      setDayModal={setDayEnd}
                      setTimeModal={setTimeModalEnd}
                      setEditRecur={setEditRecur}
                      setUpdateTime={setUpdateTimeEnd}
                      checkRecurEvents={checkRecurEvents}
                      selectedEvent={selectedEvent}
                    />
                  </nav>
                </OutsideAlerter>
              </>
            )}
            {repeatModal && (
              <>
                <span></span>
                <OutsideAlerter setMenuButton={setRepeatModal}>
                  <nav className={styles.repeatModal}>
                    <RepeatModal
                      daySelected={daySelected}
                      rruleName={rruleName}
                      setRecurChange={setRecurChange}
                      setRruleName={setRruleName}
                      setFreq={setFreq}
                      setInterval={setInterval}
                      setRepeatModal={setRepeatModal}
                    />
                  </nav>
                </OutsideAlerter>
              </>
            )}
            {calendarModal && (
              <>
                <span></span>
                <OutsideAlerter setMenuButton={setCalendarModal}>
                  <nav className={styles.calendarModal}>
                    <EventCalendarModal
                      savedCalendars={savedCalendars}
                      selectedCalendar={selectedCalendar}
                      setSelectedCalendar={setSelectedCalendar}
                      setCalendarModal={setCalendarModal}
                      setEditRecur={setEditRecur}
                      setUpdateCalendar={setUpdateCalendar}
                      checkRecurEvents={checkRecurEvents}
                      selectedEvent={selectedEvent}
                    />
                  </nav>
                </OutsideAlerter>
              </>
            )}
            {deleteRecurModal && (
              <>
                <span></span>
                <OutsideAlerter setMenuButton={setDeleteRecurModal}>
                  <div className={styles.deleteModal}>
                    <h3>Delete recurring event</h3>
                    <button
                      className="defaultButton"
                      onClick={handleDeleteThis}
                    >
                      This event
                    </button>
                    <button
                      className="defaultButton"
                      onClick={handleDeleteFoll}
                    >
                      This and following events
                    </button>
                    <button className="defaultButton" onClick={handleDeleteAll}>
                      All events
                    </button>
                  </div>
                </OutsideAlerter>
              </>
            )}
            {changeRecurModal && (
              <>
                <span></span>
                <OutsideAlerter setMenuButton={setChangeRecurModal}>
                  <div className={styles.deleteModal}>
                    <h3>Edit recurring event</h3>
                    <button
                      className="defaultButton"
                      onClick={handleChangeRuleFoll}
                    >
                      This and following events
                    </button>
                    <button
                      className="defaultButton"
                      onClick={handleChangeRuleAll}
                    >
                      All events
                    </button>
                  </div>
                </OutsideAlerter>
              </>
            )}
            {editRecurModal && (
              <>
                <span></span>
                <OutsideAlerter setMenuButton={setEditRecurModal}>
                  <div className={styles.deleteModal}>
                    <h3>Edit recurring event</h3>
                    <button className="defaultButton" onClick={handleSubmitOne}>
                      This event
                    </button>
                    <button
                      className="defaultButton"
                      onClick={handleChangeFoll}
                    >
                      This and following events
                    </button>
                    <button className="defaultButton" onClick={handleChangeAll}>
                      All events
                    </button>
                  </div>
                </OutsideAlerter>
              </>
            )}
          </div>

          <span></span>
        </div>
        <footer className={styles.footer}>
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              if (selectedEvent && recurChange === true && checkRecurEvents()) {
                handleValidation()
                  ? setChangeRecurModal(true)
                  : alert("Event must end after it starts.");
              } else if (
                selectedEvent &&
                editRecur === true &&
                checkRecurEvents()
              ) {
                handleValidation()
                  ? setEditRecurModal(true)
                  : alert("Event must end after it starts.");
              } else {
                handleValidation()
                  ? handleSubmit()
                  : alert("Event must end after it starts.");
              }
            }}
            className="saveButton"
          >
            Save
          </button>
        </footer>
      </form>
    </div>
  );
}
