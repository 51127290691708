import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./StartPage.module.css";
import { getTagFlashcardsFirebase } from "../../Utils/firebaseFlashcard";
import { getTagMindmapsFirebase } from "../../Utils/firebaseMindmaps";
import { getTagQuizzesFirebase } from "../../Utils/firebaseQuiz";
import Card from "../Layout/Card";
import Header from "../Layout/Header";

export const StartTag = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  const [mindmapList, setMindmapList] = React.useState([]);
  const [flashcardList, setFlashcardList] = React.useState([]);
  const [quizList, setQuizList] = React.useState([]);

  React.useEffect(() => {
    getTagMindmapsFirebase(params.tag).then(function (result) {
      setMindmapList(result);
    });
    getTagFlashcardsFirebase(params.tag).then(function (result) {
      setFlashcardList(result);
    });
    getTagQuizzesFirebase(params.tag).then(function (result) {
      setQuizList(result);
    });
  }, [params.category, params.tag]);

  function handleClick() {
    navigate("/login");
  }

  return (
    <>
      <Header />
      <div className={css.container}>
        <h1>{params.tag}</h1>
        <div className={css.list}>
          {mindmapList[0] && <h3>Mindmaps</h3>}
          {mindmapList[0] &&
            mindmapList.map((map) => (
              <div key={map.id}>
                <Card
                  onClick={() => setSelected(map)}
                  onDoubleClick={() => handleClick(map)}
                  isSelected={map.id === selected.id}
                  title={map.title}
                  icon={"route"}
                  items={map.list.length + " mindmaps"}
                  tag1={map.tag1 && map.tag1.title}
                  tag2={map.tag2 && map.tag2.title}
                  tag3={map.tag3 && map.tag3.title}
                  user={map.userName}
                />
              </div>
            ))}
          {flashcardList[0] && <h3>Flashcards</h3>}
          {flashcardList[0] &&
            flashcardList.map((item) => (
              <div key={item.id}>
                <Card
                  onClick={() => setSelected(item)}
                  onDoubleClick={() => handleClick(item)}
                  isSelected={item.id === selected.id}
                  title={item.title}
                  icon={"layers"}
                  items={item.cards.length + " questions"}
                  tag1={item.tag1 && item.tag1.title}
                  tag2={item.tag2 && item.tag2.title}
                  tag3={item.tag3 && item.tag3.title}
                  user={item.userName}
                />
              </div>
            ))}
          {quizList[0] && <h3>Quiz</h3>}
          {quizList[0] &&
            quizList.map((item) => (
              <div key={item.id}>
                <Card
                  onClick={() => setSelected(item)}
                  onDoubleClick={() => handleClick(item)}
                  isSelected={item.id === selected.id}
                  title={item.title}
                  icon={"quiz"}
                  items={item.questions.length + " questions"}
                  tag1={item.tag1 && item.tag1.title}
                  tag2={item.tag2 && item.tag2.title}
                  tag3={item.tag3 && item.tag3.title}
                  user={item.userName}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
