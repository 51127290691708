import React from "react";
import { Link, useNavigate } from "react-router-dom";

import css from "./StartPage.module.css";
import mindmapExample from "../../assets/mindmapExample.jpeg";
import flashcardExample from "../../assets/flashcardExample.png";
import quizExample from "../../assets/quizExample.png";
import Header from "../Layout/Header";

export const StartPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div className={css.container}>
        <div>
          <h1 className={css.title}>Get Started</h1>
          <button className="defaultButton" onClick={() => navigate("/login")}>
            Sign up for Mairimed
          </button>
        </div>
        <div className={css.card}>
          <h2>Create Mindmaps</h2>
          <img
            className={css.img}
            src={mindmapExample}
            aria-label="mindmap"
            width="50%"
            height="60%"
          />
        </div>
        <div className={css.card}>
          <h2>Study with Flashcards</h2>
          <img
            className={css.img}
            src={flashcardExample}
            aria-label="flashcard"
            width="50%"
            height="60%"
          />
        </div>
        <div className={css.card}>
          <h2>Practice with Quizzes</h2>
          <img
            className={css.img}
            src={quizExample}
            aria-label="quiz"
            width="50%"
            height="60%"
          />
        </div>
        <div className={css.footer}>
          <ul>
            {/* <Link to="/home/tags">Tags</Link>
            <li>About</li>
            <li>Terms</li>
            <li>Contact</li> */}
            <li>© 2022 mairimed.com</li>
          </ul>
        </div>
      </div>
    </>
  );
};
