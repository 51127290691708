import React from "react";
import { useNavigate } from "react-router-dom";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import css from "./NewQuiz.module.css";
import { QuizContext } from "../../Context/QuizContext";
import { UserContext } from "../../Context/UserContext";
import useForm from "../../Hooks/useForm";
import Head from "../../Utils/Head";
import { newId } from "../../Utils/newId";
import Input from "../Forms/Input";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { Search } from "../Forms/Search";
import { storage } from "../../firebaseConfig";
import { createTagFirebase } from "../../Utils/firebaseUser";

export const NewQuiz = () => {
  const { user, userData, savedTags, setSavedTags } =
    React.useContext(UserContext);
  const { dispatchQuizzes, savedQuizzesSnippets } =
    React.useContext(QuizContext);
  const title = useForm("title");
  const description = useForm(false);
  const [questions, setQuestions] = React.useState([
    {
      id: newId(),
      index: 1,
      question: "",
      answers: ["", ""],
      correct: "",
    },
    {
      id: newId(),
      index: 2,
      question: "",
      answers: ["", ""],
      correct: "",
    },
    {
      id: newId(),
      index: 3,
      question: "",
      answers: ["", ""],
      correct: "",
    },
  ]);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertAnswers, setAlertAnswers] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [showTagsModal, setShowTagsModal] = React.useState(false);
  const navigate = useNavigate();
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = React.useState(query || "");
  const [imageUpload, setImageUpload] = React.useState(null);
  const [progress, setProgress] = React.useState(null);
  const [hide, setHide] = React.useState(false);

  const filterTags = (tagsFil, query) => {
    if (!query) {
      return [];
    }
    return tagsFil.filter((tag) => {
      const tagName = tag.title.toLowerCase();
      return tagName.includes(query.toLowerCase());
    });
  };

  const filteredTags = filterTags(savedTags, searchQuery);

  function newAnswer(index) {
    const newQuestions = [...questions];
    const newAnswers = [...questions[index].answers, ""];
    newQuestions[index].answers = newAnswers;
    return setQuestions(newQuestions);
  }

  function deleteAnswer(qIdx, aIdx) {
    const newQuestions = [...questions];
    let newAnswers = [...questions[qIdx].answers];
    newAnswers.splice(aIdx, 1);
    newQuestions[qIdx].answers = newAnswers;
    return setQuestions(newQuestions);
  }

  function newQuestion() {
    const newQuestions = [
      ...questions,
      {
        id: newId(),
        index: questions.length + 1,
        question: "",
        answers: ["", ""],
        correct: "",
      },
    ];
    return setQuestions(newQuestions);
  }

  function deleteQuestion(index) {
    let array = questions;
    array.splice(index, 1); // 2nd parameter means remove one item only
    return setQuestions([...array]);
  }

  function handleValidation() {
    let formIsValid = true;
    const filteredAnswers = questions.filter(
      (question) => question.answers.indexOf("") < 0
    );

    if (filteredAnswers.length < 2) {
      formIsValid = false;
      setAlertAnswers("You need two answers to create a Deck");
      if (!title.value) {
        setAlertTitle("You need a title to create a Deck");
      } else {
        setAlertTitle("");
      }
    } else if (!title.value) {
      formIsValid = false;
      setAlertTitle("You need a title to create a Deck");
    } else {
      setAlertAnswers("");
      setAlertTitle("");
    }
    return formIsValid;
  }

  function handleSubmit() {
    const newQuiz = {
      id: newId(),
      hide: hide,
      userId: user.uid,
      userName: user.displayName,
      title: title.value,
      description: description.value,
      questions: questions.filter(
        (question) => question.answers.indexOf("") < 0
      ),
      tag1: tags[0] ? tags[0] : "",
      tag2: tags[1] ? tags[1] : "",
      tag3: tags[2] ? tags[2] : "",
    };
    const newQuizSnippet = {
      id: newQuiz.id,
      userName: newQuiz.userName,
      title: newQuiz.title,
      tag1: tags[0] ? tags[0].title : "",
      tag2: tags[1] ? tags[1].title : "",
      tag3: tags[2] ? tags[2].title : "",
      questions: newQuiz.questions.length,
    };
    let quizzesSnippets = savedQuizzesSnippets;

    quizzesSnippets.push(newQuizSnippet);

    dispatchQuizzes({
      type: "push",
      user: user,
      quiz: newQuiz,
      quizzesSnippets: quizzesSnippets,
    });

    navigate("/study/myquizzes");
  }

  function handleNewTag() {
    const newTag = {
      id: newId(),
      title: searchQuery.toLowerCase(),
    };
    setSavedTags([...savedTags, newTag]);
    createTagFirebase(newTag);
    addTag(newTag);
  }

  function addTag(tag) {
    if (tags.indexOf(tag) < 0) {
      setTags([...tags, tag]);
    }
    setShowTagsModal(false);
  }

  function deleteTag(tag) {
    let filteredTags = tags.filter((t) => t.id !== tag.id);
    setTags(filteredTags);
  }

  function updateAnswer(newAnswer, qIdx, aIdx) {
    const newQuestions = [...questions];
    questions[qIdx].answers[aIdx] = newAnswer;
    setQuestions(newQuestions);
  }

  function updateQuestion(newQuestion, qIdx) {
    const newQuestions = [...questions];
    questions[qIdx].question = newQuestion;
    setQuestions(newQuestions);
  }

  function handleChecked(qIdx, aIdx) {
    questions[qIdx - 1].correct = aIdx;
  }

  const uploadImage = (qIdx) => {
    const name = newId();
    const imageRef = ref(storage, `quizImages/${name}`);
    const uploadTask = uploadBytesResumable(imageRef, imageUpload);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const newQuestions = [...questions];
          questions[qIdx - 1].image = downloadURL;
          setQuestions(newQuestions);
          alert("Image Uploaded");
        });
      }
    );
  };

  return (
    <>
      <Head title="Create a new quiz" />
      <h3>Create a new quiz</h3>
      {alertTitle && <p className={css.error}>{alertTitle}</p>}
      {alertAnswers && <p className={css.error}>{alertAnswers}</p>}

      <form className="form">
        <Input
          label="Title"
          type="text"
          name="title"
          required
          maxLength="50"
          {...title}
        />
        <Input
          label="Description"
          type="text"
          name="description"
          maxLength="100"
          {...description}
        />
        {userData.staff && (
          <button
            className={hide ? "defaultActiveButton" : "defaultButton"}
            onClick={(e) => {
              e.preventDefault();
              setHide(!hide);
            }}
          >
            Hide
          </button>
        )}

        <div className={css.tagsContainer}>
          Tags:
          {tags.map((tag) => (
            <div
              className={css.tag}
              key={tag.id}
              onClick={() => deleteTag(tag)}
            >
              <span>{tag.title}</span>
              <i className={`material-icons ${css.tagClose}`}>clear</i>
            </div>
          ))}
        </div>
        <button
          className="defaultButton"
          onClick={(e) => {
            e.preventDefault();
            setShowTagsModal(true);
          }}
          disabled={tags.length > 2 ? true : false}
        >
          Add Tags
        </button>

        {questions.map((question) => (
          <React.Fragment key={question.index}>
            <div className={css.card}>
              <div className={css.header}>
                <div></div>
                {question.index > 2 ? (
                  <div onClick={() => deleteQuestion(question.index - 1)}>
                    <i className="material-icons materialIcon">delete</i>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className={css.body}>
                <Input
                  label={`Question ` + question.index}
                  type="text"
                  name="question"
                  maxLength="1500"
                  value={question.question}
                  onChange={(e) =>
                    updateQuestion(e.target.value, question.index - 1)
                  }
                />
                {userData.staff && (
                  <>
                    {question.image && <img alt="" src={question.image} />}
                    <div>
                      <input
                        type="file"
                        onChange={(event) => {
                          setImageUpload(event.target.files[0]);
                        }}
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          uploadImage(question.index);
                        }}
                        disabled={progress !== null && progress < 100}
                      >
                        Upload Image
                      </button>
                    </div>
                  </>
                )}
                {question.answers.map((answer, idx) => (
                  <React.Fragment key={idx}>
                    <small>correct?</small>
                    <div className={css.checkbox}>
                      <input
                        type="checkbox"
                        checked={answer.checked}
                        onChange={() => handleChecked(question.index, idx)}
                      />
                      <input
                        label="Answer"
                        type="text"
                        maxLength="400"
                        placeholder={`answer ` + (idx + 1)}
                        value={question.answers[idx]}
                        onChange={(e) =>
                          updateAnswer(e.target.value, question.index - 1, idx)
                        }
                        autoComplete="off"
                      />
                      {idx > 1 ? (
                        <small
                          onClick={() => deleteAnswer(question.index - 1, idx)}
                        >
                          delete
                        </small>
                      ) : (
                        <small></small>
                      )}
                    </div>
                  </React.Fragment>
                ))}
                <button
                  className={css.card}
                  onClick={(e) => {
                    e.preventDefault();
                    newAnswer(question.index - 1);
                  }}
                  disabled={question.answers.length > 4 ? true : false}
                >
                  <i className="material-icons materialIcon">
                    add_circle_outline
                  </i>
                  New Answer
                </button>
              </div>
            </div>
          </React.Fragment>
        ))}

        <button
          className={css.card}
          onClick={(e) => {
            e.preventDefault();
            newQuestion();
          }}
          disabled={questions.length > 500 ? true : false}
        >
          <i className="material-icons materialIcon">add_circle_outline</i> New
          Question
        </button>
        <button
          className={css.saveButton}
          onClick={(e) => {
            e.preventDefault();
            handleValidation() && handleSubmit();
          }}
          disabled={savedQuizzesSnippets.length < 100 ? false : true}
        >
          Done
        </button>
        {alertTitle && <p className={css.error}>{alertTitle}</p>}
        {alertAnswers && <p className={css.error}>{alertAnswers}</p>}
      </form>

      {showTagsModal && (
        <OutsideAlerter setMenuButton={setShowTagsModal}>
          <div className={css.tagsModal}>
            <h3>Tags</h3>
            <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            {filteredTags.map((tag) => (
              <div
                className={css.tag}
                key={tag.id}
                onClick={() => {
                  addTag(tag);
                }}
              >
                {tag.title}
              </div>
            ))}
            <div className={css.modalFooter}></div>
            <button
              className="saveButton"
              onClick={() => handleNewTag()}
              disabled={
                !filteredTags
                  .map((tag) => tag.title)
                  .includes(searchQuery.toLowerCase()) && searchQuery.length > 0
                  ? false
                  : true
              }
            >
              Save New Tag
            </button>
          </div>
        </OutsideAlerter>
      )}
    </>
  );
};
