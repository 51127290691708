import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "../Discover/DiscoverTag.module.css";
import { getTagQuizzesFirebase } from "../../Utils/firebaseQuiz";
import { getTagFlashcardsFirebase } from "../../Utils/firebaseFlashcard";
import { getTagMindmapsFirebase } from "../../Utils/firebaseMindmaps";
import Card from "../Layout/Card";
import Head from "../../Utils/Head";
import { getTagZettelsFirebase } from "../../Utils/firebaseZettel";

export const DiscoverTag = () => {
  const [list, setList] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (params.category === "mindmaps") {
      getTagMindmapsFirebase(params.tag).then(function (result) {
        setList(result);
      });
    } else if (params.category === "flashcards") {
      getTagFlashcardsFirebase(params.tag).then(function (result) {
        setList(result);
      });
    } else if (params.category === "zettels") {
      getTagZettelsFirebase(params.tag).then(function (result) {
        setList(result);
      });
    } else {
      getTagQuizzesFirebase(params.tag).then(function (result) {
        setList(result);
      });
    }
  }, [params.category, params.tag]);

  function handleClick(item) {
    if (params.category === "mindmaps") {
      return navigate(`/study/mindmap/${item.id}`);
    } else if (params.category === "flashcards") {
      return navigate(`/study/flashcard/${item.id}`);
    } else if (params.category === "quiz") {
      return navigate(`/study/quiz/${item.id}`);
    }
  }

  return (
    <div>
      <Head title={params.tag} description={params.tag + " - Mairimed"} />
      <h4>{params.category}</h4>
      <h5>{params.tag}</h5>
      <div className={css.list}>
        {list.map((item) => (
          <div key={item.id}>
            {params.category === "mindmaps" ? (
              <Card
                onClick={() => setSelected(item)}
                onDoubleClick={() => handleClick(item)}
                isSelected={item.id === selected.id}
                title={item.title}
                icon={"route"}
                items={item.list.length + " mindmaps"}
                tag1={item.tag1 && item.tag1.title}
                tag2={item.tag2 && item.tag2.title}
                tag3={item.tag3 && item.tag3.title}
                user={item.userName}
              />
            ) : params.category === "flashcards" ? (
              <Card
                onClick={() => setSelected(item)}
                onDoubleClick={() => handleClick(item)}
                isSelected={item.id === selected.id}
                title={item.title}
                icon={"layers"}
                items={item.cards.length + " questions"}
                tag1={item.tag1 && item.tag1.title}
                tag2={item.tag2 && item.tag2.title}
                tag3={item.tag3 && item.tag3.title}
                user={item.userName}
              />
            ) : params.category === "zettels" ? (
              <Card
                onClick={() => setSelected(item)}
                onDoubleClick={() => handleClick(item)}
                isSelected={item.id === selected.id}
                title={item.title}
                icon={"notes"}
                items={1 + item.next + item.prev + item.more + " zettels"}
                tag1={item.tag1 && item.tag1.title}
                tag2={item.tag2 && item.tag2.title}
                tag3={item.tag3 && item.tag3.title}
                user={item.userName}
              />
            ) : (
              <Card
                onClick={() => setSelected(item)}
                onDoubleClick={() => handleClick(item)}
                isSelected={item.id === selected.id}
                title={item.title}
                icon={"quiz"}
                items={item.questions.length + " questions"}
                tag1={item.tag1 && item.tag1.title}
                tag2={item.tag2 && item.tag2.title}
                tag3={item.tag3 && item.tag3.title}
                user={item.userName}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
