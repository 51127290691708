import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";

import "./App.css";
import "./theme.css";
import { UserStorage } from "./Context/UserContext";
import Footer from "./Components/Footer";
import Login from "./Components/Login/Login";
import Account from "./Components/Account/Account";
import RequireAuth from "./Utils/RequireAuth";
import Calendar from "./Components/Calendar/Calendar";
import NotFound from "./Components/NotFound";
import CalendarSettings from "./Components/Calendar/CalendarSettings";
import { Schedule } from "./Components/Schedule/Schedule";
import { NewFlashcard } from "./Components/Flashcards/NewFlashcard";
import { EditFlashcard } from "./Components/Flashcards/EditFlashcard";
import { StartFlashcard } from "./Components/Flashcards/StartFlashcard";
import { MyFlashcards } from "./Components/Flashcards/MyFlashcards";
import { FlashcardStorage } from "./Context/FlashcardContext";
import { ReviewFlashcard } from "./Components/Flashcards/ReviewFlashcard";
import { MyZettels } from "./Components/Zettel/MyZettels";
import { NewZettel } from "./Components/Zettel/NewZettel";
import { ZettelStorage } from "./Context/ZettelContext";
import { EditZettel } from "./Components/Zettel/EditZettel";
import MyMindmaps from "./Components/Mindmaps/MyMindmaps";
import EditMindmap from "./Components/Mindmaps/EditMindmap";
import Header from "./Components/Layout/Header";
import Content from "./Components/Layout/Content";
import LeftMenu from "./Components/Layout/LeftMenu";
import { MindmapStorage } from "./Context/MindmapContext";
import { Fullscreen } from "./Components/Mindmaps/Fullscreen";
import { MyQuizzes } from "./Components/Quiz/MyQuizzes";
import { QuizStorage } from "./Context/QuizContext";
import { NewQuiz } from "./Components/Quiz/NewQuiz";
import { StartQuiz } from "./Components/Quiz/StartQuiz";
import { Discover } from "./Components/Discover/Discover";
import { DiscoverTag } from "./Components/Discover/DiscoverTag";
import { ViewMindmap } from "./Components/Mindmaps/ViewMindmap";
import { EditQuiz } from "./Components/Quiz/EditQuiz";
import { useGoogleAnalytics } from "./Hooks/useGoogleAnalytics";
import { StartPage } from "./Components/Start/StartPage";
import LoginCreate from "./Components/Login/LoginCreate";
import { StartDiscover } from "./Components/Start/StartDiscover";
import { StartTag } from "./Components/Start/StartTag";
import { CicloBasico } from "./Components/Med/CicloBasico";
import { Medicines } from "./Components/Med/Medicines";
import { CicloBasicoArticle } from "./Components/Med/CicloBasicoArticle";
import { CicloClinico } from "./Components/Med/CicloClinico";
import { CicloClinicoSpeciality } from "./Components/Med/CicloClinicoSpeciality";
import { CicloClinicoArticle } from "./Components/Med/CicloClinicoArticle";
import { Enem } from "./Components/School/Enem";
import { SchoolMapsSubject } from "./Components/School/SchoolMapsSubject";
import { SchoolMaps } from "./Components/School/SchoolMaps";
import { School } from "./Components/School/School";
import { Home } from "./Components/Home";
import { ICD10 } from "./Components/Med/ICD10";
import { ICD10Details } from "./Components/Med/ICD10Details";

function App() {
  useGoogleAnalytics();
  const [isMenuVisible, setIsMenuVisible] = React.useState(true);

  const toggleMenu = (e) => {
    setIsMenuVisible(!isMenuVisible);
  };
  // const hideMenu = () => {
  //   setIsMenuVisible(false);
  // };

  const SidebarLayout = () => (
    <>
      <Header onMenuClick={toggleMenu} />
      <LeftMenu isMenuVisible={isMenuVisible} />
      <Content
        isMenuVisible={isMenuVisible}
        setIsMenuVisible={setIsMenuVisible}
      />
    </>
  );

  const FooterLayout = () => (
    <div className="">
      <div className="">
        <Outlet />
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );

  return (
    <>
      <UserStorage>
        {/* <CalendarStorage> */}
        {/* <ScheduleStorage> */}
        <FlashcardStorage>
          <ZettelStorage>
            <MindmapStorage>
              <QuizStorage>
                <Routes>
                  <Route path="home/*" element={<StartPage />} />
                  <Route path="home/tags/*" element={<StartDiscover />} />
                  <Route path="home/tags/:tag" element={<StartTag />} />
                  <Route path="login/*" element={<Login />} />
                  <Route path="signup/" element={<LoginCreate />} />
                  <Route element={<RequireAuth />}>
                    <Route element={<SidebarLayout />}>
                      <Route path="/" element={<Home />} />
                      <Route path="/discover" element={<Discover />} />
                      <Route path="discover/*" element={<Discover />} />
                      <Route
                        path="discover/:category/:tag"
                        element={<DiscoverTag />}
                      />
                      <Route path="calendar/*" element={<Calendar />} />
                      <Route path="schedule/*" element={<Schedule />} />
                      <Route path="calendar/day/*" element={<Calendar />} />
                      <Route path="calendar/week/*" element={<Calendar />} />
                      <Route path="calendar/month/*" element={<Calendar />} />
                      <Route path="calendar/year/*" element={<Calendar />} />
                      <Route path="calendar/agenda/*" element={<Calendar />} />
                      <Route
                        path="calendar/settings"
                        element={<CalendarSettings />}
                      />
                      <Route
                        path="study/mymindmaps/*"
                        element={<MyMindmaps />}
                      />
                      <Route
                        path="study/mindmap/:id"
                        element={<ViewMindmap />}
                      />
                      <Route
                        path="study/editmindmap/:id"
                        element={<EditMindmap />}
                      />
                      <Route
                        path="study/newflashcard/"
                        element={<NewFlashcard />}
                      />
                      <Route
                        path="study/editflashcard/:id/*"
                        element={<EditFlashcard />}
                      />
                      <Route
                        path="study/myflashcards/*"
                        element={<MyFlashcards />}
                      />
                      <Route
                        path="study/flashcard/:id/*"
                        element={<StartFlashcard />}
                      />
                      <Route
                        path="study/reviewflashcards/:id"
                        element={<ReviewFlashcard />}
                      />
                      <Route path="study/myzettels/*" element={<MyZettels />} />
                      <Route path="study/zettel/new" element={<NewZettel />} />
                      <Route path="study/zettel/:id" element={<EditZettel />} />
                      <Route path="study/myquizzes/*" element={<MyQuizzes />} />
                      <Route path="study/quiz/:id" element={<StartQuiz />} />
                      <Route path="study/newquiz/" element={<NewQuiz />} />
                      <Route path="study/editquiz/:id" element={<EditQuiz />} />
                      <Route path="enem/" element={<School />} />
                      <Route path="enem/mindmaps" element={<SchoolMaps />} />
                      <Route
                        path="enem/mindmaps/:subject"
                        element={<SchoolMapsSubject />}
                      />
                      <Route path="enem/enem" element={<Enem />} />
                      <Route path="ciclobasico/" element={<CicloBasico />} />
                      <Route
                        path="ciclobasico/:article"
                        element={<CicloBasicoArticle />}
                      />
                      <Route path="cicloclinico/" element={<CicloClinico />} />
                      <Route
                        path="cicloclinico/:speciality"
                        element={<CicloClinicoSpeciality />}
                      />
                      <Route
                        path="cicloclinico/:speciality/:article"
                        element={<CicloClinicoArticle />}
                      />
                      <Route path="/cid10" element={<ICD10 />} />
                      <Route path="/cid10/:slug" element={<ICD10Details />} />
                      <Route path="medicines/*" element={<Medicines />} />
                      <Route path="account/*" element={<Account />} />
                    </Route>
                    <Route
                      path="study/mindmap/fullscreen/:id"
                      element={<Fullscreen />}
                    />
                  </Route>
                  <Route element={<FooterLayout />}></Route>
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </QuizStorage>
            </MindmapStorage>
          </ZettelStorage>
        </FlashcardStorage>
        {/* </ScheduleStorage> */}
        {/* </CalendarStorage> */}
      </UserStorage>
    </>
  );
}

export default App;
