import React from "react";

import styles from "./CalendarSettings.module.css";
import { CalendarContext } from "../../Context/CalendarContext";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import CalendarModal from "./CalendarModal";

export default function CalendarSettings() {
  const {
    showCalendarModal,
    setShowCalendarModal,
    savedCalendars,
    setSelectedCalendar,
  } = React.useContext(CalendarContext);

  return (
    <>
      <div>
        <button
          className="createNewButton"
          onClick={() => setShowCalendarModal(!showCalendarModal)}
        >
          Create new calendar
        </button>
      </div>
      {showCalendarModal && (
        <OutsideAlerter setMenuButton={setShowCalendarModal}>
          <CalendarModal />
        </OutsideAlerter>
      )}
      <div>
        <h4>Settings for my calendars</h4>
        <div>
          {savedCalendars.map((cal) => (
            <React.Fragment key={cal.id}>
              <button
                className="defaultButton"
                onClick={() => {
                  setSelectedCalendar(cal);
                  setShowCalendarModal(true);
                }}
              >
                <span className={`${cal.color} ${styles.roundIcon}`}></span>
                {cal.name ? cal.name : "(Sem Titulo)"}
              </button>
            </React.Fragment>
          ))}
        </div>
        <h4>General</h4>
        <h4>Import</h4>
        <h4>Export</h4>
      </div>
    </>
  );
}
