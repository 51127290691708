import css from "./CardCategory.module.css";

function CardCategory(props) {
  let className = css.container;
  if (props.isSelected) {
    className += " " + css.selected;
  }

  return (
    <div
      className={className}
      onClick={props.onClick}
      onDoubleClick={props.onDoubleClick}
    >
      <i className={`material-icons ${css.cardIcon}`}>{props.icon}</i>
      <h5 className={css.title}>{props.title}</h5>
    </div>
  );
}

export default CardCategory;
