import React, { useContext, useEffect, useReducer, useState } from "react";

import { UserContext } from "./UserContext";
import { getMindmapsSnippetsFirebase } from "../Utils/firebaseMindmaps";
import {
  savedMindmapsReducer,
  savedMindmapsSnippetsReducer,
} from "./mindmapsReducer";

export const MindmapContext = React.createContext();

export const MindmapStorage = ({ children }) => {
  const { user } = useContext(UserContext);
  const [savedMindmaps, dispatchMindmaps] = useReducer(
    savedMindmapsReducer,
    []
  );
  const [savedMindmapsSnippets, dispatchMindmapsSnippets] = useReducer(
    savedMindmapsSnippetsReducer,
    []
  );

  // POPULATE MINDMAPS
  useEffect(() => {
    if (user) {
      if (savedMindmapsSnippets.length === 0) {
        getMindmapsSnippetsFirebase(user).then(function (result) {
          dispatchMindmapsSnippets({
            type: "init",
            payload: result ? result.mindmapsSnippets : [],
          });
        });
      }
    }
  }, [user, savedMindmapsSnippets.length]);

  return (
    <MindmapContext.Provider
      value={{
        savedMindmaps,
        dispatchMindmaps,
        savedMindmapsSnippets,
        dispatchMindmapsSnippets,
      }}
    >
      {children}
    </MindmapContext.Provider>
  );
};
