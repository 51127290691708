import React from "react";
import { Link } from "react-router-dom";
import Head from "../../Utils/Head";

export const CicloClinico = () => {
  const specialitiesList = [
    "Cardiologia",
    "Cirurgia",
    "Dermatologia",
    "Endocrinologia",
    "Gastroenterologia",
    "Ginecologia",
    "Hematologia",
    "Infectologia",
    "Medicina de Emergência",
    "Medicina Preventiva",
    "Nefrologia",
    "Neurologia",
    "Obstetrícia",
    "Oftalmologia",
    "Ortopedia",
    "Pediatria",
    "Pneumologia",
    "Psiquiatria",
    "Reumatologia",
    "Urologia",
  ];

  const slugify = (text) =>
    text
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-");

  return (
    <>
      <Head title="CicloClinico" description="CicloClinico - Mairimed" />
      <h2>Ciclo Clínico</h2>
      {specialitiesList.map((sp, i) => (
        <h4 key={i}>
          <Link to={slugify(sp)}>{sp}</Link>
        </h4>
      ))}
    </>
  );
};
