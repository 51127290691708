import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getMindmapFirebase } from "../../Utils/firebaseMindmaps";
import Toolbar from "../Layout/Toolbar";
import ChartElement from "./ChartElement";
import Connection from "./Connection";

export const Fullscreen = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [mindmap, setMindmap] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [isDragging, setIsDragging] = React.useState(false);
  const [startX, setStartX] = React.useState(0);
  const [startY, setStartY] = React.useState(0);
  const [next, setNext] = React.useState("");
  const [prev, setPrev] = React.useState("");
  const WIDTH = (mindmap.width / mindmap.zoom) * 3;
  const HEIGHT = (mindmap.height / mindmap.zoom) * 3;

  React.useEffect(() => {
    getMindmapFirebase(params.id).then(function (result) {
      setMindmap(result);
      setPrev(result.prev);
      setNext(result.next);
      setLoading(false);
    });
  }, [params.id]);

  const R = 40;

  const getChildren = (list, parent, elements) => {
    const itemLevel1 = list.filter((item) => item.level === 1);
    const children = list.filter((item) => item.parentId === parent.id);
    let countTill4 = 0;
    let level1YFactor = 1;
    let level1YFactor4 = 1;
    let childrenCounter = 0;
    for (let i = 0; i < children.length; i++) {
      let xFactor = 5;
      let yFactor = 5;
      let xParent = 0;
      childrenCounter = childrenCounter + 1;
      countTill4 = countTill4 + 1;
      let item = children[i];
      let isLeftSide = true;
      let isUp = true;
      // SET ISLEFT AND ISUP
      if (item.level === 1) {
        i % 2 === 0 ? (isLeftSide = true) : (isLeftSide = false);
        countTill4 < 3 ? (isUp = true) : (isUp = false);
      } else {
        isLeftSide = parent.isLeftSide;
      }
      if (item.level === 3) {
        i < 3 ? (isUp = false) : (isUp = true);
      }

      // CHANGE ITEM 1
      if (item.level === 1 && childrenCounter === 1) {
        if (itemLevel1.length < 5) {
          xFactor = 14;
        } else {
          xFactor = 8;
          level1YFactor4 = -600;
        }
      }
      // CHANGE ITEM 2
      if (item.level === 1 && childrenCounter === 2) {
        if (itemLevel1.length < 6) {
          xFactor = 14;
          level1YFactor4 = 1;
        } else {
          xFactor = 8;
          level1YFactor4 = -600;
        }
      }
      // CHANGE ITEM 3
      if (item.level === 1 && childrenCounter === 3) {
        if (itemLevel1.length < 7) {
          xFactor = 14;
          level1YFactor4 = 1;
        } else {
          xFactor = 8;
          level1YFactor4 = 600;
        }
      }
      // CHANGE ITEM 4
      if (item.level === 1 && childrenCounter === 4) {
        if (itemLevel1.length < 8) {
          xFactor = 14;
          level1YFactor4 = 1;
        } else {
          xFactor = 8;
          level1YFactor4 = 600;
        }
      }

      // CHANGE CHILDREN ITENS 1, 2, 3, 4
      if (item.level === 2 && parent.childrenCounter < 5) {
        xFactor = 8;
        yFactor = yFactor + 150;
      }

      // MUDA ITENS 5, 6, 7, 8
      if (item.level === 1 && childrenCounter >= 5) {
        xFactor = xFactor + 45;
        xParent = xParent - 1;
        level1YFactor4 = 1;
      }
      level1YFactor = countTill4 === 1 ? level1YFactor + 1 : level1YFactor + 0;
      if (item.level === 1 && childrenCounter % 5 === 0) {
        level1YFactor = level1YFactor - 32;
      }

      if (item.level === 2) {
        xFactor = xFactor + 14;
        yFactor = yFactor + 60 + i * 450;

        // if (i < 2) {
        //   yFactor = yFactor + 10 + i * 460;
        // } else if (i === 2) {
        //   yFactor = yFactor + 10 + i * 380;
        // } else if (i === 3) {
        //   yFactor = yFactor + 10 + i * 340;
        // } else if (i === 4) {
        //   yFactor = yFactor + 10 + i * 300;
        // } else {
        //   yFactor = yFactor + 10 + i * 280;
        // }
      }
      if (item.level === 3) {
        xFactor = xFactor + 12;
        yFactor = yFactor - 150 + i * 50;
      }

      countTill4 === 4 ? (countTill4 = 0) : (countTill4 = countTill4 + 0);

      // MUDA Y DO LEVEL 1
      let y1 = 0;
      if (isUp) {
        if (childrenCounter >= 5) {
          y1 = parent.y - 400 + (R * -level1YFactor) / 4;
        } else {
          y1 = parent.y - 80 + (R * -level1YFactor) / 4;
        }
      } else {
        if (childrenCounter >= 5) {
          y1 = parent.y + item.title.length + 400 + (R * level1YFactor) / 4;
        } else {
          y1 = parent.y + 80 + (R * level1YFactor) / 2;
        }
      }

      const element = {
        id: item.id,
        childrenCounter: childrenCounter,
        title: item.title,
        pName: parent.title,
        color: item.color,
        icon: item.icon,
        level: item.level,
        // x:
        //   isLeftSide === true
        //     ? parent.x + R * xFactor * -1
        //     : parent.x + R * xFactor,
        // y:
        //   item.level === 1
        //     ? y1
        //     : parent.isUp
        //     ? parent.y + yFactor * -1 * (i / 2 + parent.parentFactor * +1)
        //     : parent.y + yFactor * 1 * (i / 2 + parent.parentFactor * +1),
        x:
          isLeftSide === true
            ? parent.x + R * xFactor * -1
            : parent.x + R * xFactor,
        y:
          item.level === 1
            ? y1 + level1YFactor4
            : parent.isUp
            ? parent.y + yFactor * -1
            : parent.y + yFactor * 1,
        px: parent.x,
        py: parent.y,
        isLeftSide,
        isUp: item.level === 1 ? isUp : parent.isUp,
        parentFactor: 3 * item.level,
        xParent,
      };
      elements.push(element);
      getChildren(list, element, elements);
    }
  };

  const setElements = (list) => {
    const elements = [];
    const x0 = WIDTH / 2;
    const y0 = HEIGHT / 2;
    const root = list.find((item) => item.level === 0);
    const rootElement = {
      id: root.id,
      title: root.title,
      level: root.level,
      x: x0,
      y: y0,
    };
    elements.push(rootElement);
    getChildren(list, rootElement, elements);
    return elements;
  };

  const ZOOM_FACTOR = 1.1;

  const zoomIn = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const zoom = mindmap.zoom * ZOOM_FACTOR;
    const newMindmap = { ...mindmap };
    newMindmap.zoom = zoom;
    setMindmap(newMindmap);
  };

  const zoomOut = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const zoom = mindmap.zoom / ZOOM_FACTOR;
    const newMindmap = { ...mindmap };
    newMindmap.zoom = zoom;
    setMindmap(newMindmap);
  };

  const zoomReset = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const zoom = 0.2;
    const x = 0;
    const y = 0;
    const newMindmap = { ...mindmap };
    newMindmap.zoom = zoom;
    newMindmap.x = x;
    newMindmap.y = y;
    setMindmap(newMindmap);
  };

  const zoomMenu = [
    {
      name: "chevron_left",
      onClick: () =>
        prev.id ? navigate(`/study/mindmap/fullscreen/${prev.id}`) : null,
    },
    {
      name: "chevron_right",
      onClick: () =>
        next.id ? navigate(`/study/mindmap/fullscreen/${next.id}`) : null,
    },
    { name: "zoomIn", onClick: (e) => zoomIn(e) },
    { name: "zoomOut", onClick: (e) => zoomOut(e) },
    { name: "zoomReset", onClick: (e) => zoomReset(e) },
    {
      name: "close_fullscreen",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/study/mindmap/${mindmap.id}`);
      },
    },
  ];

  const onMouseDown = (e) => {
    if (!mindmap.moveMode) {
      return;
    }
    setIsDragging(true);
    const newStartX = mindmap.x * 1 + e.clientX;
    setStartX(newStartX);
    const newStartY = mindmap.y * 1 + e.clientY;
    setStartY(newStartY);
  };

  const onMouseMove = (e) => {
    if (!isDragging) {
      return;
    }
    e.preventDefault();
    const newMindmap = { ...mindmap };
    const x = (newMindmap.x + startX - e.clientX) * 0.5;
    const y = (newMindmap.y + startY - e.clientY) * 0.5;
    newMindmap.x = x;
    newMindmap.y = y;
    setMindmap(newMindmap);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  } else {
    const elements = mindmap.list ? setElements(mindmap.list) : [];
    return (
      <div>
        <Toolbar
          list={zoomMenu}
          type="default"
          location={["horizontal", "right", "top"]}
        />
        <svg
          viewBox={`${mindmap.x} ${mindmap.y + 2000} ${WIDTH} ${HEIGHT}`}
          onMouseDown={(e) => onMouseDown(e)}
          onMouseMove={(e) => onMouseMove(e)}
          onMouseUp={() => onMouseUp()}
          xmlns="http://www.w3.org/2000/svg"
        >
          <Connection list={elements} />
          {elements.map((element) => (
            <ChartElement
              key={element.id}
              title={element.title}
              color={element.color}
              icon={element.icon}
              id={element.id}
              level={element.level}
              x={element.x}
              y={element.y}
              px={element.px}
              py={element.py}
              isSelected={element.id === mindmap.id}
              isLeftSide={element.isLeftSide}
              onClick={() => {}}
            />
          ))}
        </svg>
      </div>
    );
  }
};
