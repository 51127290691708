import {
  doc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

// CALENDAR

export const getCalendarsFirebase = async (user) => {
  const docRef = collection(db, "users", user.uid, "calendars");
  const docSnap = await getDocs(docRef);
  if (docSnap) {
    return docSnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const createCalendarFirebase = async (user, calendar) => {
  await setDoc(doc(db, "users", user.uid, "calendars", calendar.id), {
    id: calendar.id,
    name: calendar.name,
    description: calendar.description,
    checked: calendar.checked,
    color: calendar.color,
  });
  console.log("calendar created");
};

export const updateCalendarFirebase = async (user, calendar) => {
  const userCal = doc(db, "users", user.uid, "calendars", calendar.id);
  await updateDoc(userCal, {
    id: calendar.id,
    name: calendar.name,
    description: calendar.description,
    checked: calendar.checked,
    color: calendar.color,
  });
  console.log("calendar updated");
};

export const deleteCalendarFirebase = async (user, calendar) => {
  try {
    const userCal = doc(db, "users", user.uid, "calendars", calendar.id);
    await deleteDoc(userCal);
  } catch (err) {
    console.error(err);
  } finally {
    console.log("calendar deleted");
  }
};

// EVENTS

export const getEventsFirebase = async (user) => {
  const docRef = collection(db, "users", user.uid, "events");
  const docSnap = await getDocs(docRef);
  if (docSnap) {
    return docSnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const createEventFirebase = async (user, event) => {
  await setDoc(doc(db, "users", user.uid, "events", event.id), {
    user: user.uid,
    title: event.title,
    description: event.description,
    calendar: event.calendar,
    dayStart: event.dayStart,
    dayEnd: event.dayEnd,
    allDay: event.allDay,
    id: event.id,
    freq: event.freq,
    interval: event.interval,
    until: event.until,
    rruleName: event.rruleName,
    rruleId: event.rruleId,
    location: event.location,
    url: event.url,
    geo: event.geo,
    categories: event.categories,
    status: event.status,
    busyStatus: event.busyStatus,
    organizer: event.organizer,
    attendees: event.attendees,
  });
  console.log("event created");
};

export const updateEventFirebase = async (user, event) => {
  await updateDoc(doc(db, "users", user.uid, "events", event.id), {
    user: user.uid,
    title: event.title,
    description: event.description,
    calendar: event.calendar,
    dayStart: event.dayStart,
    dayEnd: event.dayEnd,
    allDay: event.allDay,
    id: event.id,
    freq: event.freq,
    interval: event.interval,
    until: event.until,
    rruleName: event.rruleName,
    rruleId: event.rruleId,
    location: event.location,
    url: event.url,
    geo: event.geo,
    categories: event.categories,
    status: event.status,
    busyStatus: event.busyStatus,
    organizer: event.organizer,
    attendees: event.attendees,
  });
  console.log("event updated");
};

export const deleteEventFirebase = async (user, event) => {
  await deleteDoc(doc(db, "users", user.uid, "events", event.id));
  console.log("event deleted");
};
