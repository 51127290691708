import React from "react";
import dayjs from "dayjs";

import styles from "../Calendar/CalendarToolbar.module.css";
import { UserContext } from "../../Context/UserContext";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import MyCalendars from "./MyCalendars";
import { CalendarContext } from "../../Context/CalendarContext";
import { newId } from "../../Utils/newId";

export const CalendarToolbar = () => {
  const { loading, user } = React.useContext(UserContext);
  const {
    smallCalendarMonth,
    setSmallCalendarMonth,
    dispatchCalEvent,
    filteredEvents,
  } = React.useContext(CalendarContext);
  const [filterModal, setFilterModal] = React.useState(false);
  // const [viewModal, setViewModal] = React.useState(false);

  React.useEffect(() => {
    function getLastEventArray() {
      var eventsToRecur = filteredEvents.filter(
        (evt) => evt.rruleName !== "Does not repeat" && evt.until === null
      );
      var idArray = [];
      var rruleIdArray = eventsToRecur.reduce((result, evt) => {
        if (idArray.map((i) => i).indexOf(evt.rruleId) < 0) {
          idArray.push(evt.rruleId);
        }
        result = idArray;
        return result;
      }, []);
      var lastEventArray = rruleIdArray.map((id) => 0);
      for (let i = 0; i < rruleIdArray.length; i++) {
        eventsToRecur.forEach(function (evt) {
          if (evt.rruleId === rruleIdArray[i]) {
            if (dayjs(evt.dayEnd) > dayjs(lastEventArray[i].dayEnd)) {
              lastEventArray[i] = evt;
            }
          }
        });
      }
      return lastEventArray;
    }
    function NewYear(lastEvents) {
      lastEvents.forEach(function (evt) {
        var dayRStart = dayjs(evt.dayStart);
        var dayREnd = dayjs(evt.dayEnd);
        while (
          dayjs(evt.dayEnd).endOf("year").diff(dayjs(dayREnd), "day") + 10 >=
          0
        ) {
          dayRStart = dayjs(dayRStart).add(evt.interval, evt.freq);
          dayREnd = dayjs(dayREnd).add(evt.interval, evt.freq);
          var calendarEventR = {
            id: newId(),
            title: evt.title,
            description: evt.description,
            calendar: evt.calendar,
            dayStart: dayRStart.valueOf(),
            dayEnd: dayREnd.valueOf(),
            allDay: evt.allDay,
            freq: evt.freq,
            interval: evt.interval,
            until: evt.until,
            rruleName: evt.rruleName,
            rruleId: evt.rruleId,
            location: null,
            url: null,
            geo: null,
            categories: null,
            status: null,
            busyStatus: null,
            organizer: null,
            attendees: null,
          };
          dispatchCalEvent({
            user: user,
            type: "push",
            payload: calendarEventR,
          });
        }
      });
    }

    if (
      Number(
        dayjs(new Date(dayjs().year(), smallCalendarMonth)).format("M")
      ) === 12
    ) {
      var array = getLastEventArray();
      array = array.filter(
        (e) =>
          Number(dayjs(e.dayEnd).format("YYYY")) ===
          Number(
            dayjs(new Date(dayjs().year(), smallCalendarMonth)).format("YYYY")
          ) +
            1
      );

      if (array[0]) {
        console.log("mudou o ano, criando novos eventos...");
        NewYear(array);
      }
    }
  }, [smallCalendarMonth, filteredEvents, dispatchCalEvent, user]);

  function handlePrevMonth() {
    setSmallCalendarMonth(smallCalendarMonth - 1);
  }

  function handleNextMonth() {
    setSmallCalendarMonth(smallCalendarMonth + 1);
  }

  function handleReset() {
    setSmallCalendarMonth(dayjs().month());
  }

  if (loading === false) {
    return (
      <div>
        <div className={styles.controlMonth}>
          {/* <div className={styles.menuContainer}>
            <OutsideAlerter setMenuButton={setViewModal}>
              <button
                className={styles.button}
                onClick={() => setViewModal(!viewModal)}
              >
                <p>{view}</p>
                <span className="material-icons">expand_more</span>
              </button>

              {viewModal && (
                <nav className={styles.navFilterModal}>
                  <div>
                    <p>day</p>
                    <p>week</p>
                    <p>month</p>
                    <p>year</p>
                    <p>agenda</p>
                  </div>
                </nav>
              )}
            </OutsideAlerter>
          </div> */}
          <div className={styles.menuContainer}>
            <OutsideAlerter setMenuButton={setFilterModal}>
              <button
                className={styles.button}
                onClick={() => setFilterModal(!filterModal)}
              >
                <p>Filter</p>
                <span className="material-icons">expand_more</span>
              </button>

              {filterModal && (
                <nav className={styles.navFilterModal}>
                  <MyCalendars />
                </nav>
              )}
            </OutsideAlerter>
          </div>

          <button className="materialButton">
            <span
              className={`material-icons materialIcon`}
              onClick={handlePrevMonth}
            >
              chevron_left
            </span>
          </button>
          <button onClick={handleReset} className={styles.button}>
            Today
          </button>
          <button className="materialButton">
            <span
              className={`material-icons materialIcon`}
              onClick={handleNextMonth}
            >
              chevron_right
            </span>
          </button>
          <div className={styles.currentMonth}>
            {dayjs(new Date(dayjs().year(), smallCalendarMonth)).format(
              "MMMM YYYY"
            )}
          </div>
        </div>
      </div>
    );
  } else return <div></div>;
};
