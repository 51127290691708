import {
  createDeckFirebase,
  createCardFirebase,
  deleteDeckFirebase,
  updateDeckFirebase,
  updateCardFirebase,
  updateFlashcardsReview,
} from "../Utils/firebaseFlashcard";

export const savedCardsReducer = (state, { type, payload }) => {
  switch (type) {
    case "init":
      return payload;
    case "push":
      createCardFirebase(payload);
      return [...state, payload];
    default:
      throw new Error();
  }
};

export const savedFlashcardsSnippetsReducer = (state, { type, payload }) => {
  switch (type) {
    case "init":
      return payload;
    case "push":
      return [...state, payload];
    case "update":
      return state.map((d) => (d.id === payload.id ? payload : d));
    case "delete":
      return state.filter((d) => d.id !== payload.id);
    default:
      throw new Error();
  }
};

export const savedDecksReducer = (
  state,
  { type, user, deck, flashcardsSnippets }
) => {
  switch (type) {
    case "init":
      return deck;
    case "push":
      createDeckFirebase(user, deck, flashcardsSnippets);
      return [...state, deck];
    case "update":
      updateDeckFirebase(user, deck, flashcardsSnippets);
      return state.map((d) => (d.id === deck.id ? deck : d));
    case "delete":
      deleteDeckFirebase(user, deck, flashcardsSnippets);
      return state.filter((d) => d.id !== deck.id);
    default:
      throw new Error();
  }
};

export const flashcardsReviewReducer = (state, { type, user, payload }) => {
  switch (type) {
    case "init":
      return payload;
    case "push":
      return [...state, payload];
    case "update":
      updateFlashcardsReview(user, payload);
      return payload;
    case "delete":
      return state.filter((d) => d.id !== payload.id);
    default:
      throw new Error();
  }
};

export const savedMyDecksReducer = (state, { type, user, deck, cards }) => {
  switch (type) {
    case "init":
      return deck;
    case "push":
      return [...state, deck];
    case "updateDeck":
      return state.map((d) => (d.id === deck.id ? deck : d));
    case "updateCards":
      updateCardFirebase(user, deck, cards);
      deck.cards = cards;
      return state.map((d) => (d.id === deck.id ? deck : d));
    case "delete":
      return state.filter((d) => d.id !== deck.id);
    default:
      throw new Error();
  }
};
