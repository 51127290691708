import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import css from "./MyMindmaps.module.css";
import Toolbar from "../Layout/Toolbar";
import Card from "../Layout/Card";
import { newId } from "../../Utils/newId";
import { MindmapContext } from "../../Context/MindmapContext";
import { UserContext } from "../../Context/UserContext";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import Head from "../../Utils/Head";

export const MyMindmaps = () => {
  const { user, favoritesMindmapsId, favoritesMindmapsTitle } =
    useContext(UserContext);
  const { dispatchMindmaps, savedMindmapsSnippets, dispatchMindmapsSnippets } =
    useContext(MindmapContext);
  const [itemSelected, setItemSelected] = useState("");
  const navigate = useNavigate();
  const [filteredMindmaps, setFilteredMindmaps] = useState(
    savedMindmapsSnippets
  );
  const [tagSelected, setTagSelected] = useState(null);
  const [userTags, setUserTags] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fiveRandom, setFiveRandom] = useState([]);

  useEffect(() => {
    const sortedList = savedMindmapsSnippets.sort((a, b) =>
      a.title > b.title ? 1 : b.title > a.title ? -1 : 0
    );
    setFilteredMindmaps(sortedList);
    const tagArray = [];
    savedMindmapsSnippets.forEach((map) => {
      if (map.tag1 !== "" && tagArray.map((tag) => tag).indexOf(map.tag1) < 0) {
        tagArray.push(map.tag1);
      }
      if (map.tag2 !== "" && tagArray.map((tag) => tag).indexOf(map.tag2) < 0) {
        tagArray.push(map.tag2);
      }
      if (map.tag3 !== "" && tagArray.map((tag) => tag).indexOf(map.tag3) < 0) {
        tagArray.push(map.tag3);
      }
    });
    setUserTags(tagArray);

    function getFiveRandom(array) {
      // Shuffle array
      const shuffled = array.sort(() => 0.5 - Math.random());

      // Get sub-array of first n elements after shuffled
      let selected = shuffled.slice(0, 5);
      return selected;
    }

    setFiveRandom(getFiveRandom(savedMindmapsSnippets));
  }, [savedMindmapsSnippets]);

  function resetTags() {
    setTagSelected(null);
    setFilteredMindmaps(savedMindmapsSnippets);
  }

  function filterMaps(tag) {
    setTagSelected(tag);
    let arrayMaps = savedMindmapsSnippets.filter(
      (map) => map.tag1 === tag || map.tag2 === tag || map.tag3 === tag
    );
    setFilteredMindmaps([...arrayMaps]);
  }

  function add() {
    const item = {
      id: newId(),
      title: "New Mindmap",
      level: 0,
      parentId: null,
      color: "color0",
      x: 0,
      y: 0,
      image: "",
      icon: "",
    };
    const newMindmap = {
      id: item.id,
      hide: false,
      userId: user.uid,
      userName: user.displayName,
      title: "New Mindmap",
      comment: "",
      reference: "",
      list: [item],
      level: 0,
      tag1: "",
      tag2: "",
      tag3: "",
      prev: "",
      next: "",
      parentId: null,
      x: 0,
      y: 0,
      width: 700,
      height: 445,
      zoom: 0.2,
      moveMode: true,
    };

    let mindmapsSnippets = savedMindmapsSnippets;

    const newMindmapsSnippet = {
      id: item.id,
      userName: user.displayName,
      title: "New Mindmap",
      tag1: "",
      tag2: "",
      tag3: "",
      list: newMindmap.list.length,
    };

    mindmapsSnippets.push(newMindmapsSnippet);

    // dispatchMindmapsSnippets({
    //   type: "push",
    //   payload: newMindmapsSnippet,
    // });

    dispatchMindmaps({
      type: "push",
      user: user,
      payload: newMindmap,
      mindmapsSnippets: mindmapsSnippets,
    });
    navigate(`/study/editmindmap/${item.id}`);
  }

  const actionMenu = [
    savedMindmapsSnippets.length < 100
      ? { name: "add", type: "alert", onClick: () => add() }
      : {
          name: "add",
          type: "primary",
          onClick: () => alert("Maximum capacity of itens (100)"),
        },
    itemSelected
      ? { name: "delete", type: "alert", onClick: () => setDeleteModal(true) }
      : {
          name: "delete",
          type: "primary",
          onClick: () => alert("Select an item"),
        },
  ];

  function setSelected(id) {
    setItemSelected(id);
  }

  function deleteId(id) {
    const selected = savedMindmapsSnippets.filter(
      (mindmap) => id === mindmap.id
    );
    const mindmapsSnippets = savedMindmapsSnippets.filter(
      (mindmap) => id !== mindmap.id
    );
    dispatchMindmapsSnippets({
      type: "delete",
      payload: selected[0],
    });

    dispatchMindmaps({
      type: "delete",
      user: user,
      payload: selected[0],
      mindmapsSnippets: mindmapsSnippets,
    });
  }

  return (
    <>
      <Head title="Mindmaps" description="Mindmaps - Mairimed" />
      <div className={css.tagsContainer}>
        <button
          className={
            tagSelected === null
              ? `defaultActiveButton ${css.tagItem}`
              : `defaultButton ${css.tagItem}`
          }
          onClick={() => resetTags()}
        >
          All tags
        </button>
        {userTags.map((tag, i) => (
          <button
            key={i}
            onClick={() => filterMaps(tag)}
            className={
              tagSelected === tag
                ? `defaultActiveButton ${css.tagItem}`
                : `defaultButton ${css.tagItem}`
            }
          >
            {tag}
          </button>
        ))}
      </div>

      <Toolbar
        list={actionMenu}
        type="alert"
        location={["horizontal", "right", "top"]}
      />

      <div className={css.list}>
        <div className={css.card}>
          <h4>5 Review</h4>
          {fiveRandom.map((item) => (
            <p
              key={item.id}
              className={css.cardItem}
              onClick={() => navigate(`/study/mindmap/${item.id}`)}
            >
              {item.title}
            </p>
          ))}
        </div>
        <div className={css.card}>
          <h4>5 Favorites</h4>
          {favoritesMindmapsTitle.map((map, i) => (
            <p
              key={favoritesMindmapsId[i]}
              className={css.cardItem}
              onClick={() =>
                navigate(`/study/mindmap/${favoritesMindmapsId[i]}`)
              }
            >
              {map}
            </p>
          ))}
        </div>
      </div>
      <div className={css.list}>
        {filteredMindmaps
          .sort((a, b) => a.title.charCodeAt(0) - b.title.charCodeAt(0))
          .map((item) => (
            <div key={item.id}>
              <Card
                id={item.id}
                onClick={() => setSelected(item.id)}
                onDoubleClick={() => navigate(`/study/mindmap/${item.id}`)}
                isSelected={item.id === itemSelected}
                icon="route"
                title={item.title}
                items={`${item.list} branches`}
                tag1={item.tag1 && item.tag1}
                tag2={item.tag2 && item.tag2}
                tag3={item.tag3 && item.tag3}
                user={item.userName}
              />
            </div>
          ))}
      </div>
      {deleteModal && (
        <OutsideAlerter setMenuButton={setDeleteModal}>
          <div className={css.deleteModal}>
            {filteredMindmaps.map((item) => {
              if (item.id === itemSelected) {
                return <h3 key={item.id}>Delete {item.title}?</h3>;
              } else {
                return null;
              }
            })}
            <p>
              You are about to delete this mindmap and all of its data. No one
              will be able to access this mindmap ever again.
            </p>
            <div>
              <button
                className="defaultButton"
                onClick={() => setDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className="deleteButton"
                onClick={() => {
                  deleteId(itemSelected);
                  setDeleteModal(false);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </OutsideAlerter>
      )}
    </>
  );
};

export default MyMindmaps;
