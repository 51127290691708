import React from "react";
import { useNavigate } from "react-router-dom";

import css from "./MyZettel.module.css";
import { ZettelContext } from "../../Context/ZettelContext";
import Head from "../../Utils/Head";
import { UserContext } from "../../Context/UserContext";
import Toolbar from "../Layout/Toolbar";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import Card from "../Layout/Card";

export const MyZettels = () => {
  const { dispatchZettels, savedZettelsSnippets, dispatchZettelsSnippets } =
    React.useContext(ZettelContext);
  const { user } = React.useContext(UserContext);
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [tagSelected, setTagSelected] = React.useState(null);
  const [userTags, setUserTags] = React.useState([]);
  const [filteredZettels, setFilteredZettels] =
    React.useState(savedZettelsSnippets);

  React.useEffect(() => {
    setFilteredZettels(savedZettelsSnippets);
    const tagArray = [];
    savedZettelsSnippets.forEach((zettel) => {
      if (
        zettel.tag1 !== "" &&
        tagArray.map((tag) => tag).indexOf(zettel.tag1) < 0
      ) {
        tagArray.push(zettel.tag1);
      }
      if (
        zettel.tag2 !== "" &&
        tagArray.map((tag) => tag).indexOf(zettel.tag2) < 0
      ) {
        tagArray.push(zettel.tag2);
      }
      if (
        zettel.tag3 !== "" &&
        tagArray.map((tag) => tag).indexOf(zettel.tag3) < 0
      ) {
        tagArray.push(zettel.tag3);
      }
    });
    setUserTags([...tagArray]);
  }, [savedZettelsSnippets]);

  function resetTags() {
    setTagSelected(null);
    setFilteredZettels(savedZettelsSnippets);
  }

  function filterZettels(tag) {
    setTagSelected(tag);
    let arrayMaps = savedZettelsSnippets.filter(
      (deck) => deck.tag1 === tag || deck.tag2 === tag || deck.tag3 === tag
    );
    setFilteredZettels([...arrayMaps]);
  }

  function handleClick(zettel) {
    return navigate(`/study/zettel/${zettel.id}`);
  }

  const actionMenu = [
    savedZettelsSnippets.length < 100
      ? {
          name: "add",
          type: "alert",
          onClick: () => navigate("/study/zettel/new"),
        }
      : {
          name: "add",
          type: "primary",
          onClick: () => alert("Maximum capacity of itens (100)"),
        },

    selected
      ? { name: "delete", type: "alert", onClick: () => setDeleteModal(true) }
      : {
          name: "delete",
          type: "primary",
          onClick: () => alert("Select an item"),
        },
  ];

  function deleteZettel(zettel) {
    const zettelsSnippets = savedZettelsSnippets.filter(
      (d) => d.id !== zettel.id
    );
    const cards = zettel.cards;

    dispatchZettelsSnippets({
      type: "delete",
      payload: zettel,
    });

    dispatchZettels({
      type: "delete",
      user: user,
      zettel: zettel,
      cards: cards,
      zettelsSnippets: zettelsSnippets,
    });
    navigate("/study/myzettels");
  }

  return (
    <>
      <Head title="Zettel" description="Zettel - Mairimed" />
      <div className={css.tagsBar}>
        <button
          className={
            tagSelected === null
              ? `defaultActiveButton ${css.tagItem}`
              : `defaultButton ${css.tagItem}`
          }
          onClick={() => resetTags()}
        >
          All tags
        </button>
        {userTags.map((tag, i) => (
          <button
            key={i}
            onClick={() => filterZettels(tag)}
            className={
              tagSelected === tag
                ? `defaultActiveButton ${css.tagItem}`
                : `defaultButton ${css.tagItem}`
            }
          >
            {tag}
          </button>
        ))}
      </div>
      <Toolbar
        list={actionMenu}
        type="alert"
        location={["horizontal", "right", "top"]}
      />
      <div className={css.list}>
        {filteredZettels.map((zettel) => (
          <div key={zettel.id}>
            <Card
              onClick={() => setSelected(zettel)}
              onDoubleClick={() => handleClick(zettel)}
              isSelected={zettel.id === selected.id}
              icon="notes"
              title={zettel.title}
              items={`${zettel.zettels} zettels`}
              tag1={zettel.tag1 && zettel.tag1}
              tag2={zettel.tag2 && zettel.tag2}
              tag3={zettel.tag3 && zettel.tag3}
              user={zettel.userName}
            />
          </div>
        ))}
      </div>
      {deleteModal && (
        <OutsideAlerter setMenuButton={setDeleteModal}>
          <div className={css.deleteModal}>
            <h3>Delete {selected.title}?</h3>
            <p>
              You are about to delete this zettels and all of its data. No one
              will be able to access this zettels ever again.
            </p>
            <div>
              <button
                className="defaultButton"
                onClick={() => setDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className="deleteButton"
                onClick={() => {
                  deleteZettel(selected);
                  setDeleteModal(false);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </OutsideAlerter>
      )}
    </>
  );
};
