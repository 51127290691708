import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import css from "./ICD10.module.css";
import { UserContext } from "../../Context/UserContext";
import { db } from "../../firebaseConfig";

export const ICD10 = () => {
  const { userData } = useContext(UserContext);
  const [icdList, setIcdList] = useState([]);
  const [letterList, setLetterList] = useState([]);
  const [content, setContent] = useState("");
  const [letter, setLetter] = useState("a");
  const alphabet = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  useEffect(() => {
    const getCID10 = async () => {
      const docRef = doc(db, "icd", "cid10");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("cicloBasico loaded");
        return docSnap.data();
      } else {
        console.log("No such document");
      }
    };

    getCID10().then(function (result) {
      setIcdList(result.cid10);
      setLetterList(result.cid10.filter((cid) => cid.slug[0] === "a"));
    });
  }, []);

  useEffect(() => {
    setLetterList(icdList.filter((cid) => cid.slug[0] === letter));
  }, [letter, icdList]);

  const slugify = (text) =>
    text
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-");

  const createCID10Firebase = async (cid10) => {
    await setDoc(doc(db, "icd", "cid10"), {
      cid10,
    });
    console.log(`cid10 created`);
  };

  function handleCreateCID10() {
    const arr = content.split(/=>|\n/);
    const newCidList = [...icdList];
    for (let index = 0; index < arr.length; index++) {
      const element = {
        slug: slugify(arr[index]),
        cod: arr[index],
        title: arr[index + 1],
      };
      index % 2 === 0 && newCidList.push(element);
    }
    createCID10Firebase(newCidList);
  }

  if (userData && icdList[0]) {
    return (
      <div>
        {userData.staff && (
          <div>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              onChange={(e) => setContent(e.target.value)}
            ></textarea>
            <button onClick={() => handleCreateCID10()}>save</button>
          </div>
        )}
        <div className={css.alphabet}>
          {alphabet.map((lett, i) => (
            <span key={i} onClick={() => setLetter(lett)}>
              {lett}
            </span>
          ))}
        </div>
        <div className={css.letter}>{letter}</div>
        {alphabet.map((lett, i) => (
          <React.Fragment key={i}>
            {letter === lett &&
              letterList.map((icd) => (
                <div key={icd.slug} className={css.letterItems}>
                  <Link to={"/cid10/" + icd.slug}>
                    {icd.cod + " - " + icd.title}
                  </Link>
                </div>
              ))}
          </React.Fragment>
        ))}
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
