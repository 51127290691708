import React from "react";

import styles from "./Search.module.css";

export const Search = ({ searchQuery, setSearchQuery, ...props }) => {
  return (
    <form
      action="/"
      method="get"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <label htmlFor="header-search">
        <span className={styles.visuallyHidden}>Search</span>
      </label>
      <input
        value={searchQuery}
        onInput={(e) => setSearchQuery(e.target.value)}
        type="text"
        id="header-search"
        placeholder="Type to search..."
        name="s"
        autoComplete="off"
        maxLength="25"
      />
      {/* <button type="submit">Search</button> */}
    </form>
  );
};
