import React from "react";

import styles from "./RepeatModal.module.css";

export default function EventCalendarModal({
  savedCalendars,
  selectedCalendar,
  setSelectedCalendar,
  setCalendarModal,
  setEditRecur,
  setUpdateCalendar,
  selectedEvent,
  checkRecurEvents,
}) {
  function handleClick(cal) {
    selectedEvent &&
      checkRecurEvents() &&
      selectedCalendar !== cal &&
      setEditRecur(true);
    selectedEvent &&
      checkRecurEvents() &&
      selectedCalendar !== cal &&
      setUpdateCalendar(true);
    setSelectedCalendar(cal);
    setCalendarModal(false);
  }

  return (
    <div className={styles.body}>
      {savedCalendars.map((cal) => (
        <React.Fragment key={cal.id}>
          <button onClick={() => handleClick(cal)}>
            <span className={`${cal.color} ${styles.roundIcon}`}></span>
            {cal.name}
          </button>
        </React.Fragment>
      ))}
    </div>
  );
}
