import React from "react";

import css from "./Home.module.css";
import Head from "../Utils/Head";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const navigate = useNavigate();

  return (
    <section>
      <Head title="Home" description="Home - Mairimed" />
      <div>
        <div className={css.content}>
          {/* <figure onClick={() => navigate("/enem")} className={css.hexCard}>
            <i className={`material-icons ${css.hexIcon}`}>school</i>
            <div className={css.cardContent}>Enem</div>
          </figure> */}
          <figure
            onClick={() => navigate("/ciclobasico")}
            className={css.hexCard}
          >
            <i className={`material-icons ${css.hexIcon}`}>summarize</i>
            <div className={css.cardContent}>Med Básico</div>
          </figure>
          <figure
            onClick={() => navigate("/cicloclinico")}
            className={css.hexCard}
          >
            <i className={`material-icons ${css.hexIcon}`}>monitor_heart</i>
            <div className={css.cardContent}>Med Clínico</div>
          </figure>
        </div>
      </div>
    </section>
  );
};
