import dayjs from "dayjs";
import React from "react";

import styles from "./RepeatModal.module.css";

export default function RepeatModal({
  daySelected,
  rruleName,
  setRruleName,
  setRepeatModal,
  setInterval,
  setFreq,
  setRecurChange,
}) {
  function handleNotRepeat(e) {
    e.preventDefault();
    setRruleName("Does not repeat");
    setRecurChange("noRepeat");
    setRepeatModal(false);
  }
  function handleDaily(e) {
    e.preventDefault();
    rruleName === "Daily" ? setRecurChange(false) : setRecurChange(true);
    setRruleName("Daily");
    setFreq("day");
    setInterval(1);
    setRepeatModal(false);
  }
  function handleWeekly(e) {
    e.preventDefault();
    rruleName === `Weekly on ${dayjs(daySelected).format("dddd")}`
      ? setRecurChange(false)
      : setRecurChange(true);
    setRruleName(`Weekly on ${dayjs(daySelected).format("dddd")}`);
    setFreq("week");
    setInterval(1);
    setRepeatModal(false);
  }
  function handleMonthly(e) {
    e.preventDefault();
    rruleName === `Monthly on ${dayjs(daySelected).format("DD")}`
      ? setRecurChange(false)
      : setRecurChange(true);
    setRruleName(`Monthly on ${dayjs(daySelected).format("DD")}`);
    setFreq("month");
    setInterval(1);
    setRepeatModal(false);
  }
  function handleAnnualy(e) {
    e.preventDefault();
    rruleName === `Annually on ${dayjs(daySelected).format("MMMM DD")}`
      ? setRecurChange(false)
      : setRecurChange(true);
    setRruleName(`Annually on ${dayjs(daySelected).format("MMMM DD")}`);
    setFreq("year");
    setInterval(1);
    setRepeatModal(false);
  }

  return (
    <div className={styles.body}>
      <button onClick={handleNotRepeat}>Does not repeat</button>
      <button onClick={handleDaily}>Daily</button>
      <button onClick={handleWeekly}>
        Weekly on {dayjs(daySelected).format("dddd")}
      </button>
      <button onClick={handleMonthly}>
        Monthly on {dayjs(daySelected).format("DD")}
      </button>
      <button onClick={handleAnnualy}>
        Annually on {dayjs(daySelected).format("MMMM DD")}
      </button>
    </div>
  );
}
