import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import css from "./NewQuiz.module.css";
import { QuizContext } from "../../Context/QuizContext";
import { UserContext } from "../../Context/UserContext";
import Input from "../Forms/Input";
import Head from "../../Utils/Head";
import { newId } from "../../Utils/newId";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { Search } from "../Forms/Search";
import { getQuizFirebase } from "../../Utils/firebaseQuiz";
import { storage } from "../../firebaseConfig";
import { createTagFirebase } from "../../Utils/firebaseUser";

export const EditQuiz = () => {
  const { user, userData, savedTags, setSavedTags } =
    React.useContext(UserContext);
  const { dispatchQuizzes, savedQuizzesSnippets, dispatchQuizzesSnippets } =
    React.useContext(QuizContext);
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [quiz, setQuiz] = React.useState("");
  const [questions, setQuestions] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertAnswers, setAlertAnswers] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [showTagsModal, setShowTagsModal] = React.useState(false);
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = React.useState(query || "");
  const [imageUpload, setImageUpload] = React.useState(null);
  const [progress, setProgress] = React.useState(null);
  const [hide, setHide] = React.useState(false);

  React.useEffect(() => {
    getQuizFirebase(params.id).then(function (result) {
      setQuiz(result);
      setTitle(result.title);
      setDescription(result.description);
      setHide(result.hide);
      const tagsResult = [];
      result.tag1 !== "" && tagsResult.push(result.tag1);
      result.tag2 !== "" && tagsResult.push(result.tag2);
      result.tag3 !== "" && tagsResult.push(result.tag3);
      setTags(tagsResult);
      const quizQuestions = result.questions;
      quizQuestions.sort(function (a, b) {
        return a.index - b.index;
      });
      setQuestions(quizQuestions);
      user.uid === result.userId && setLoading(false);
    });
  }, [params.id, user.uid]);

  const filterTags = (tagsFil, query) => {
    if (!query) {
      return [];
    }
    return tagsFil.filter((tag) => {
      const tagName = tag.title.toLowerCase();
      return tagName.includes(query.toLowerCase());
    });
  };
  const filteredTags = filterTags(savedTags, searchQuery);

  function newAnswer(index) {
    const newQuestions = [...questions];
    const newAnswers = [...questions[index].answers, ""];
    newQuestions[index].answers = newAnswers;
    return setQuestions(newQuestions);
  }

  function deleteAnswer(qIdx, aIdx) {
    const newQuestions = [...questions];
    let newAnswers = [...questions[qIdx].answers];
    newAnswers.splice(aIdx, 1);
    newQuestions[qIdx].answers = newAnswers;
    return setQuestions(newQuestions);
  }

  function newQuestion() {
    const newQuestions = [
      ...questions,
      {
        id: newId(),
        index: questions.length + 1,
        answers: ["", ""],
        correct: "",
      },
    ];
    return setQuestions(newQuestions);
  }

  function deleteQuestion(index) {
    let array = questions;
    array.splice(index, 1); // 2nd parameter means remove one item only
    array.forEach((quest) => {
      if (quest.index > index) {
        quest.index = quest.index - 1;
      }
    });
    return setQuestions([...array]);
  }

  function handleValidation() {
    let formIsValid = true;
    const filteredAnswers = questions.filter(
      (question) => question.answers.indexOf("") < 0
    );

    if (filteredAnswers.length < 2) {
      formIsValid = false;
      setAlertAnswers("You need two answers to create a Deck");
      if (!title.value) {
        setAlertTitle("You need a title to create a Deck");
      } else {
        setAlertTitle("");
      }
    } else if (!title === "") {
      formIsValid = false;
      setAlertTitle("You need a title to create a Deck");
    } else {
      setAlertAnswers("");
      setAlertTitle("");
    }
    return formIsValid;
  }

  function handleSubmit() {
    const updatedQuiz = {
      id: quiz.id,
      hide: hide,
      userId: user.uid,
      userName: user.displayName,
      title: title,
      description: description,
      tag1: tags[0] ? tags[0] : "",
      tag2: tags[1] ? tags[1] : "",
      tag3: tags[2] ? tags[2] : "",
      questions: questions.filter(
        (question) => question.answers.indexOf("") < 0
      ),
    };
    const updatedQuizSnippet = {
      id: updatedQuiz.id,
      userName: updatedQuiz.userName,
      title: updatedQuiz.title,
      tag1: tags[0] ? tags[0].title : "",
      tag2: tags[1] ? tags[1].title : "",
      tag3: tags[2] ? tags[2].title : "",
      questions: updatedQuiz.questions.length,
    };

    const quizzesSnippets = savedQuizzesSnippets.map((snippet) =>
      snippet.id === updatedQuizSnippet.id ? updatedQuizSnippet : snippet
    );

    dispatchQuizzesSnippets({
      type: "update",
      payload: updatedQuizSnippet,
    });

    dispatchQuizzes({
      type: "update",
      quiz: updatedQuiz,
      user: user,
      quizzesSnippets: quizzesSnippets,
    });

    navigate("/study/myquizzes");
  }

  function handleNewTag() {
    const newTag = {
      id: newId(),
      title: searchQuery.toLowerCase(),
    };
    setSavedTags([...savedTags, newTag]);
    createTagFirebase(newTag);
    addTag(newTag);
  }

  function addTag(tag) {
    if (tags.indexOf(tag) < 0) {
      setTags([...tags, tag]);
    }
    setShowTagsModal(false);
  }

  function deleteTag(tag) {
    let filteredTags = tags.filter((t) => t.id !== tag.id);
    setTags(filteredTags);
  }

  function updateAnswer(newAnswer, qIdx, aIdx) {
    const newQuestions = [...questions];
    questions[qIdx].answers[aIdx] = newAnswer;
    setQuestions(newQuestions);
  }

  function updateQuestion(newQuestion, qIdx) {
    const newQuestions = [...questions];
    questions[qIdx].question = newQuestion;
    setQuestions(newQuestions);
  }

  function handleChecked(qIdx, aIdx) {
    const newQuestions = [...questions];
    questions[qIdx - 1].correct = aIdx;
    setQuestions(newQuestions);
  }

  const uploadImage = (qIdx) => {
    const name = newId();
    const imageRef = ref(storage, `quizImages/${name}`);
    const uploadTask = uploadBytesResumable(imageRef, imageUpload);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const newQuestions = [...questions];
          questions[qIdx - 1].image = downloadURL;
          setQuestions(newQuestions);
          alert("Image Uploaded");
        });
      }
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <Head title={`Edit ${quiz.title}`} />
        <h3>Edit {quiz.title}</h3>
        {alertTitle && <p className={css.error}>{alertTitle}</p>}
        {alertAnswers && <p className={css.error}>{alertAnswers}</p>}

        <form className="form">
          <Input
            label="Title"
            type="text"
            name="title"
            required
            maxLength="50"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <Input
            label="Description"
            type="text"
            name="description"
            maxLength="100"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          {userData.staff && (
            <button
              className={hide ? "defaultActiveButton" : "defaultButton"}
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
              }}
            >
              Hide
            </button>
          )}

          <div className={css.tagsContainer}>
            Tags:
            {tags.map((tag) => (
              <div
                className={css.tag}
                key={tag.id}
                onClick={() => deleteTag(tag)}
              >
                <span>{tag.title}</span>
                <i className={`material-icons ${css.tagClose}`}>clear</i>
              </div>
            ))}
          </div>
          <button
            className="defaultButton"
            onClick={(e) => {
              e.preventDefault();
              setShowTagsModal(true);
            }}
            disabled={tags.length > 2 ? true : false}
          >
            Add Tags
          </button>

          {questions.map((question) => (
            <React.Fragment key={question.index}>
              <div className={css.card}>
                <div className={css.header}>
                  <div></div>
                  {question.index > 2 ? (
                    <div onClick={() => deleteQuestion(question.index - 1)}>
                      <i className="material-icons materialIcon">delete</i>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className={css.body}>
                  <Input
                    label={`Question ` + question.index}
                    type="text"
                    name="question"
                    maxLength="1500"
                    value={question.question ? question.question : ""}
                    onChange={(e) =>
                      updateQuestion(e.target.value, question.index - 1)
                    }
                  />
                  {userData.staff && (
                    <>
                      {question.image && <img alt="" src={question.image} />}
                      <div>
                        <input
                          type="file"
                          onChange={(event) => {
                            setImageUpload(event.target.files[0]);
                          }}
                        />
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            uploadImage(question.index);
                          }}
                          disabled={progress !== null && progress < 100}
                        >
                          Upload Image
                        </button>
                      </div>
                    </>
                  )}
                  {question.answers.map((answer, idx) => (
                    <React.Fragment key={idx}>
                      <small>correct?</small>
                      <div className={css.checkbox}>
                        <input
                          type="checkbox"
                          checked={idx === question.correct ? true : false}
                          onChange={() => handleChecked(question.index, idx)}
                        />
                        <input
                          label="Answer"
                          type="text"
                          maxLength="400"
                          placeholder={`answer ` + (idx + 1)}
                          value={
                            question.answers[0] ? question.answers[idx] : ""
                          }
                          onChange={(e) =>
                            updateAnswer(
                              e.target.value,
                              question.index - 1,
                              idx
                            )
                          }
                          autoComplete="off"
                        />
                        {idx > 1 ? (
                          <small
                            onClick={() =>
                              deleteAnswer(question.index - 1, idx)
                            }
                          >
                            delete
                          </small>
                        ) : (
                          <small></small>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                  <button
                    className={css.card}
                    onClick={(e) => {
                      e.preventDefault();
                      newAnswer(question.index - 1);
                    }}
                    disabled={question.answers.length > 4 ? true : false}
                  >
                    <i className="material-icons materialIcon">
                      add_circle_outline
                    </i>
                    New Answer
                  </button>
                </div>
              </div>
            </React.Fragment>
          ))}

          <button
            className={css.card}
            onClick={(e) => {
              e.preventDefault();
              newQuestion();
            }}
            disabled={questions.length > 500 ? true : false}
          >
            <i className="material-icons materialIcon">add_circle_outline</i>{" "}
            New Question
          </button>
          <button
            className={css.saveButton}
            onClick={(e) => {
              e.preventDefault();
              handleValidation() && handleSubmit();
            }}
          >
            Done
          </button>
          {alertTitle && <p className={css.error}>{alertTitle}</p>}
          {alertAnswers && <p className={css.error}>{alertAnswers}</p>}
        </form>

        {showTagsModal && (
          <OutsideAlerter setMenuButton={setShowTagsModal}>
            <div className={css.tagsModal}>
              <h3>Tags</h3>
              <Search
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              {filteredTags.map((tag) => (
                <div
                  className={css.tag}
                  key={tag.id}
                  onClick={() => {
                    addTag(tag);
                  }}
                >
                  {tag.title}
                </div>
              ))}
              <div className={css.modalFooter}></div>
              <button
                className="saveButton"
                onClick={() => handleNewTag()}
                disabled={
                  !filteredTags
                    .map((tag) => tag.title)
                    .includes(searchQuery.toLowerCase()) &&
                  searchQuery.length > 0
                    ? false
                    : true
                }
              >
                Save New Tag
              </button>
            </div>
          </OutsideAlerter>
        )}
      </>
    );
  }
};
