import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./EditMindmap.module.css";
import { MindmapContext } from "../../Context/MindmapContext";
import { UserContext } from "../../Context/UserContext";
import Details from "./Details";
import Toolbar from "../Layout/Toolbar";
import Chart from "./Chart";
import { newId } from "../../Utils/newId";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { Search } from "../Forms/Search";
import { listIcons } from "../../Utils/listIcons";
import { getMindmapFirebase } from "../../Utils/firebaseMindmaps";
import Head from "../../Utils/Head";
import { createTagFirebase } from "../../Utils/firebaseUser";

export const EditMindmap = () => {
  const { user, userData, savedTags, setSavedTags } =
    React.useContext(UserContext);
  const { dispatchMindmaps, savedMindmapsSnippets, dispatchMindmapsSnippets } =
    React.useContext(MindmapContext);
  const params = useParams();
  const navigate = useNavigate();
  const wrapper = React.createRef();
  const [mindmap, setMindmap] = React.useState({});
  const [isDragging, setIsDragging] = React.useState(false);
  const [startX, setStartX] = React.useState(0);
  const [startY, setStartY] = React.useState(0);
  const [itemSelected, setItemSelected] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [color, setColor] = React.useState(itemSelected.color);
  const [icon, setIcon] = React.useState(itemSelected.icon);
  const [showIconModal, setShowIconModal] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const [showTagsModal, setShowTagsModal] = React.useState(false);
  const [showMapsModal, setShowMapsModal] = React.useState(false);
  const [next, setNext] = React.useState("");
  const [prev, setPrev] = React.useState("");
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = React.useState(query || "");
  const [changed, setChanged] = React.useState(false);
  const [hide, setHide] = React.useState(false);

  React.useEffect(() => {
    getMindmapFirebase(params.id).then(function (result) {
      result && setMindmap(result);
      result && setHide(result.hide);
      let mapTags = [];
      result && result.tag1 !== "" && mapTags.push(result.tag1);
      result && result.tag2 !== "" && mapTags.push(result.tag2);
      result && result.tag3 !== "" && mapTags.push(result.tag3);
      setTags(mapTags);
      result && setItemSelected(result.list[0]);
      result && setPrev(result.prev);
      result && setNext(result.next);
      result && user.uid === result.userId && setLoading(false);
    });
  }, [params.id, user.uid]);

  React.useEffect(() => {
    setColor(itemSelected.color);
  }, [itemSelected]);

  const filterTags = (tagsFil, query) => {
    if (!query) {
      return [];
    }
    return tagsFil.filter((tag) => {
      const tagName = tag.title.toLowerCase();
      return tagName.includes(query.toLowerCase());
    });
  };

  const filteredTags = filterTags(savedTags, searchQuery);

  const filterMaps = (mapsFil, query) => {
    if (!query) {
      return [];
    }
    return mapsFil.filter((map) => {
      if (map.id !== mindmap.id) {
        const mapName = map.title.toLowerCase();
        return mapName.includes(query.toLowerCase());
      } else {
        return null;
      }
    });
  };

  const filteredMaps = filterMaps(savedMindmapsSnippets, searchQuery);

  function addPrev(map) {
    let newMap = { id: map.id, title: map.title };
    if (prev !== map) {
      setPrev(newMap);
    }
    setChanged(true);
    setShowMapsModal(false);
  }

  function addNext(map) {
    let newMap = { id: map.id, title: map.title };
    if (next !== map) {
      setNext(newMap);
    }
    setChanged(true);
    setShowMapsModal(false);
  }

  // React.useEffect(() => {
  //   const newMindmap = { ...mindmap };
  //   if (newMindmap.list) {
  //     newMindmap.list.forEach((i, idx) => {
  //       if (i.id === itemSelected.id) {
  //         newMindmap.list[idx].color = color;
  //         itemSelected.color = color;
  //       }
  //     });
  //   }
  // }, [color]);

  //   const onResize = () => {
  //     const width = wrapper.current.clientWidth;
  //     const height = wrapper.current.clientHeight;
  //     const newMindmap = { ...mindmap };
  //     newMindmap.width = width;
  //     newMindmap.height = height;
  //     setMindmap(newMindmap);
  //   };

  //   React.useEffect(() => {
  //     if (wrapper && wrapper.current && wrapper.current.clientWidth) {
  //       window.addEventListener("resize", onResize);
  //     }

  //     if (wrapper && wrapper.current && wrapper.current.clientWidth) {
  //       return () => {
  //         window.removeEventListener("resize", onResize);
  //       };
  //     }
  //   }, []);

  function addIcon(icon) {
    const newMindmap = { ...mindmap };
    newMindmap.list.forEach((map) => {
      if (itemSelected.id === map.id) {
        itemSelected.icon = icon;
      }
    });
    setMindmap(newMindmap);
    setChanged(true);
  }

  function handleNewTag() {
    const newTag = {
      id: newId(),
      title: searchQuery.toLowerCase(),
    };
    setSavedTags([...savedTags, newTag]);
    createTagFirebase(newTag);
    addTag(newTag);
  }

  function addTag(tag) {
    if (tags.indexOf(tag) < 0) {
      setTags([...tags, tag]);
    }
    setSearchQuery("");
    setChanged(true);
    setShowTagsModal(false);
  }

  const toggleMoveMode = () => {
    const moveMode = !mindmap.moveMode;
    const newMindmap = { ...mindmap };
    newMindmap.moveMode = moveMode;
    setMindmap(newMindmap);
  };

  const ZOOM_FACTOR = 1.1;

  const zoomIn = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const zoom = mindmap.zoom * ZOOM_FACTOR;
    const newMindmap = { ...mindmap };
    newMindmap.zoom = zoom;
    setMindmap(newMindmap);
  };

  const zoomOut = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const zoom = mindmap.zoom / ZOOM_FACTOR;
    const newMindmap = { ...mindmap };
    newMindmap.zoom = zoom;
    setMindmap(newMindmap);
  };

  const zoomReset = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const zoom = 0.2;
    const x = 0;
    const y = 0;
    const newMindmap = { ...mindmap };
    newMindmap.zoom = zoom;
    newMindmap.x = x;
    newMindmap.y = y;
    setMindmap(newMindmap);
  };

  const handleSelected = (id) => {
    // const item = repository.getItem(id);
    const item = mindmap.list.filter((item) => id === item.id);
    setItemSelected(item[0]);
  };

  const changeName = (newName) => {
    const newMindmap = { ...mindmap };
    newMindmap.list.forEach((i, idx) => {
      if (i.id === itemSelected.id) {
        newMindmap.list[idx].title = newName;
        itemSelected.title = newName;
        if (itemSelected.id === mindmap.id) {
          newMindmap.title = newName;
        }
      }
    });
    setMindmap(newMindmap);
    setChanged(true);
  };

  const changeComment = (newComment) => {
    const newMindmap = { ...mindmap };
    newMindmap.comment = newComment;
    setMindmap(newMindmap);
    setChanged(true);
  };

  const changeRef = (newRef) => {
    const newMindmap = { ...mindmap };
    newMindmap.reference = newRef;
    setMindmap(newMindmap);
    setChanged(true);
  };

  function validateAddItem() {
    let isValid = true;
    let arrayLevel1 = [];
    let childrensArray = [];
    mindmap.list.forEach((item) => {
      if (item.level === 1) {
        arrayLevel1.push(item);
      }
      if (item.parentId === itemSelected.id) {
        childrensArray.push(item);
      }
    });
    if (arrayLevel1.length >= 8 && itemSelected.level === 0) {
      isValid = false;
    } else if (itemSelected.level === 3) {
      isValid = false;
    } else if (childrensArray.length === 7 && itemSelected.level !== 0) {
      isValid = false;
    }
    return isValid;
  }

  const actionList = [
    {
      name: "add",
      onClick: () =>
        validateAddItem()
          ? addItem()
          : alert("Maximum capacity for this item has been reached"),
    },
    { name: "delete", onClick: () => deleteItem() },
  ];

  function addItem() {
    const addId = newId();
    const addLevel = itemSelected.level + 1;
    const newItem = {
      id: addId,
      title: "New item",
      level: addLevel,
      color: itemSelected.color ? itemSelected.color : "color0",
      rootId: mindmap.id,
      levelZeroId: addLevel === 1 ? addId : itemSelected.levelZeroId,
      parentId: itemSelected.id,
      image: "",
      icon: "___",
    };
    const newMindmap = mindmap;
    newMindmap.list.push(newItem);
    setMindmap({ ...newMindmap });
    setChanged(true);
    // setItemSelected(newItem);
  }

  const deleteItem = () => {
    if (itemSelected.id === mindmap.id) {
      alert("Can't delete the root");
    } else {
      const newMindmap = { ...mindmap };
      const newList = newMindmap.list.filter(
        (item) => item.id !== itemSelected.id
      );
      newMindmap.list = newList;
      setMindmap(newMindmap);
      setChanged(true);
    }
  };

  const saveMindmap = () => {
    const newMindmap = { ...mindmap };
    newMindmap.tag1 = tags[0] ? tags[0] : "";
    newMindmap.tag2 = tags[1] ? tags[1] : "";
    newMindmap.tag3 = tags[2] ? tags[2] : "";
    newMindmap.prev = prev;
    newMindmap.next = next;
    newMindmap.hide = hide;

    let mindmapsSnippets = savedMindmapsSnippets;

    const updateMindmapSnippets = {
      id: newMindmap.id,
      userName: newMindmap.userName,
      title: newMindmap.title,
      tag1: newMindmap.tag1 ? newMindmap.tag1.title : "",
      tag2: newMindmap.tag2 ? newMindmap.tag2.title : "",
      tag3: newMindmap.tag3 ? newMindmap.tag3.title : "",
      list: newMindmap.list.length,
    };

    if (
      mindmapsSnippets.map((map) => map.id).indexOf(updateMindmapSnippets.id) <
      0
    ) {
      mindmapsSnippets.push(updateMindmapSnippets);
    } else {
      mindmapsSnippets = mindmapsSnippets.map((map) =>
        map.id === updateMindmapSnippets.id ? updateMindmapSnippets : map
      );
    }

    dispatchMindmapsSnippets({
      type: "update",
      payload: updateMindmapSnippets,
    });

    dispatchMindmaps({
      type: "update",
      user: user,
      payload: newMindmap,
      mindmapsSnippets: mindmapsSnippets,
    });
    setChanged(false);
    // navigate("/study/mymindmaps");
  };

  const onMouseDown = (e) => {
    if (!mindmap.moveMode) {
      return;
    }
    setIsDragging(true);
    const newStartX = mindmap.x * 1 + e.clientX;
    setStartX(newStartX);
    const newStartY = mindmap.y * 1 + e.clientY;
    setStartY(newStartY);
  };

  const onMouseMove = (e) => {
    if (!isDragging) {
      return;
    }
    e.preventDefault();
    const newMindmap = { ...mindmap };
    const x = (newMindmap.x + startX - e.clientX) * 0.5;
    const y = (newMindmap.y + startY - e.clientY) * 0.5;
    newMindmap.x = x;
    newMindmap.y = y;
    setMindmap(newMindmap);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const viewMenu = [
    { name: "hive", onClick: () => setShowMapsModal(true) },
    {
      name: "chevron_left",
      onClick: () => (prev.id ? navigate(`/study/mindmap/${prev.id}`) : null),
    },
    {
      name: "chevron_right",
      onClick: () => (next.id ? navigate(`/study/mindmap/${next.id}`) : null),
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <Head
          title={`Edit ${mindmap.title}`}
          description={`${mindmap.title} - Edit Mairimed`}
        />
        <div className={css.container} ref={wrapper}>
          <Chart
            mindmapName={mindmap.title}
            mindmapId={mindmap.id}
            id={itemSelected.id}
            list={mindmap.list}
            onClick={handleSelected}
            x={mindmap.x}
            y={mindmap.y}
            width={mindmap.width}
            height={mindmap.height}
            zoom={mindmap.zoom}
            onZoomIn={zoomIn}
            onZoomOut={zoomOut}
            onZoomReset={zoomReset}
            onToggleMoveMode={toggleMoveMode}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
          />
          <Toolbar
            type="alert"
            location={["right", "bottom", "horizontal"]}
            list={actionList}
          />
        </div>
        {/* <Toolbar
          type="default"
          location={["horizontal", "left", "bottom"]}
          list={viewMenu}
        /> */}
        <Details
          userData={userData}
          itemSelected={itemSelected}
          onChangeName={(e) => {
            e.preventDefault();
            changeName(e.target.value);
          }}
          comment={mindmap.comment}
          onChangeComment={(e) => changeComment(e.target.value)}
          reference={mindmap.reference}
          onChangeRef={(e) => {
            e.preventDefault();
            changeRef(e.target.value);
          }}
          setHide={setHide}
          hide={hide}
          setColor={setColor}
          color={color}
          list={mindmap.list}
          setShowTagsModal={setShowTagsModal}
          tags={tags}
          setTags={setTags}
          icon={icon}
          setIcon={setIcon}
          setShowIconModal={setShowIconModal}
          prev={prev}
          next={next}
          saveMindmap={saveMindmap}
          changed={changed}
          setChanged={setChanged}
        />
        {showMapsModal && (
          <OutsideAlerter setMenuButton={setShowMapsModal}>
            <div className={css.tagsModal}>
              <Search
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              <h3>Choose prev</h3>
              <div className={css.choose}>
                {filteredMaps.map((map) => (
                  <div
                    className={map.id === prev.id ? css.cardActive : css.card}
                    key={map.id}
                    onClick={() => {
                      addPrev(map);
                    }}
                  >
                    {map.title}
                    {map.tag1 && (
                      <small className={css.cardTag}>{map.tag1}</small>
                    )}
                    {map.tag2 && (
                      <small className={css.cardTag}>{map.tag2}</small>
                    )}
                    {map.tag3 && (
                      <small className={css.cardTag}>{map.tag3}</small>
                    )}
                  </div>
                ))}
              </div>
              <h3>Choose next</h3>
              <div className={css.choose}>
                {filteredMaps.map((map) => (
                  <div
                    className={map.id === next.id ? css.cardActive : css.card}
                    key={map.id}
                    onClick={() => {
                      addNext(map);
                    }}
                  >
                    {map.title}
                    {map.tag1 && (
                      <small className={css.cardTag}>{map.tag1}</small>
                    )}
                    {map.tag2 && (
                      <small className={css.cardTag}>{map.tag2}</small>
                    )}
                    {map.tag3 && (
                      <small className={css.cardTag}>{map.tag3}</small>
                    )}
                  </div>
                ))}
              </div>
              <div className={css.modalFooter}></div>
            </div>
          </OutsideAlerter>
        )}
        {showTagsModal && (
          <OutsideAlerter setMenuButton={setShowTagsModal}>
            <div className={css.tagsModal}>
              <h3>Tags</h3>
              <Search
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              <button
                className="saveButton"
                onClick={() => handleNewTag()}
                disabled={
                  !filteredTags
                    .map((tag) => tag.title)
                    .includes(searchQuery.toLowerCase()) &&
                  searchQuery.length > 0
                    ? false
                    : true
                }
              >
                Save New Tag
              </button>
              {filteredTags.map((tag) => (
                <div
                  className={css.tag}
                  key={tag.id}
                  onClick={() => {
                    addTag(tag);
                  }}
                >
                  {tag.title}
                </div>
              ))}
              <div className={css.modalFooter}></div>
            </div>
          </OutsideAlerter>
        )}
        {showIconModal && (
          <OutsideAlerter setMenuButton={setShowIconModal}>
            <div className={css.iconsModal}>
              <h3>Icons</h3>
              <div>
                {listIcons.map((icon, i) => (
                  <i
                    className="material-icons materialIcon"
                    onClick={() => {
                      addIcon(icon);
                      setShowIconModal(false);
                    }}
                    key={i}
                  >
                    {icon}
                  </i>
                ))}
              </div>
            </div>
          </OutsideAlerter>
        )}
      </>
    );
  }
};

export default EditMindmap;
