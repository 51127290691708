import React from "react";
import { Link } from "react-router-dom";
import Head from "../../Utils/Head";

export const SchoolMaps = () => {
  const schoolList = [
    "Biologia",
    "Filosofia",
    "Fisica",
    "Geografia",
    "Historia",
    "Literatura",
    "Portugues",
    "Quimica",
  ];

  const slugify = (text) =>
    text
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-");

  return (
    <>
      <Head title="EnemMaps" description="Enem Maps - Mairimed" />
      <h2>Mindmaps</h2>
      {schoolList.map((item, i) => (
        <h4 key={i}>
          <Link to={slugify(item)}>{item}</Link>
        </h4>
      ))}
    </>
  );
};
