import React, { useContext, useEffect, useReducer, useState } from "react";

import { UserContext } from "./UserContext";
import {
  getFlashcardsSnippetsFirebase,
  getFlashcardsReviewFirebase,
} from "../Utils/firebaseFlashcard";
import {
  savedCardsReducer,
  savedDecksReducer,
  savedMyDecksReducer,
  savedFlashcardsSnippetsReducer,
  flashcardsReviewReducer,
} from "./flashcardsReducer";

export const FlashcardContext = React.createContext();

export const FlashcardStorage = ({ children }) => {
  const { user } = useContext(UserContext);
  const [savedCards, dispatchCards] = useReducer(savedCardsReducer, []);
  const [savedDecks, dispatchDecks] = useReducer(savedDecksReducer, []);
  const [savedFlashcardsSnippets, dispatchFlashcardsSnippets] = useReducer(
    savedFlashcardsSnippetsReducer,
    []
  );
  const [flashcardsReview, dispatchFlashcardsReview] = useReducer(
    flashcardsReviewReducer,
    []
  );
  const [myDecks, dispatchMyDecks] = useReducer(savedMyDecksReducer, []);
  const [flashcardsReviewArray, setFlashcardsReviewArray] = useState([]);
  const [cardsReview, setCardsReview] = useState([]);

  // POPULATE CARDS
  useEffect(() => {
    if (user) {
      if (savedFlashcardsSnippets.length === 0) {
        getFlashcardsSnippetsFirebase(user).then(function (result) {
          dispatchFlashcardsSnippets({
            type: "init",
            payload: result ? result.flashcardsSnippets : [],
          });
        });
      }
      if (flashcardsReview.length === 0) {
        getFlashcardsReviewFirebase(user).then(function (result) {
          dispatchFlashcardsReview({
            type: "init",
            payload: result ? result : {},
          });
          let array = [];
          for (var i in result) {
            array = [...array, i];
          }
          setFlashcardsReviewArray(array);
        });
      }
    }
  }, [
    user,
    savedFlashcardsSnippets.length,
    flashcardsReview,
    flashcardsReviewArray,
  ]);

  React.useEffect(() => {
    if (user) {
      setCardsReview([]);
    }
  }, [user]);

  return (
    <FlashcardContext.Provider
      value={{
        savedCards,
        dispatchCards,
        savedDecks,
        dispatchDecks,
        myDecks,
        dispatchMyDecks,
        savedFlashcardsSnippets,
        dispatchFlashcardsSnippets,
        flashcardsReview,
        dispatchFlashcardsReview,
        flashcardsReviewArray,
        cardsReview,
        setCardsReview,
      }}
    >
      {children}
    </FlashcardContext.Provider>
  );
};
