import React from "react";
import {
  getQuizzesSnippetsFirebase,
  getQuizzesScoresFirebase,
} from "../Utils/firebaseQuiz";

import {
  savedQuizzesReducer,
  savedQuizzesSnippetsReducer,
  quizzesScoresReducer,
} from "./quizReducer";
import { UserContext } from "./UserContext";

export const QuizContext = React.createContext();

export const QuizStorage = ({ children }) => {
  const { user } = React.useContext(UserContext);
  const [savedQuizzes, dispatchQuizzes] = React.useReducer(
    savedQuizzesReducer,
    []
  );
  const [savedQuizzesSnippets, dispatchQuizzesSnippets] = React.useReducer(
    savedQuizzesSnippetsReducer,
    []
  );
  const [quizzesScores, dispatchQuizzesScores] = React.useReducer(
    quizzesScoresReducer,
    []
  );

  // POPULATE QUIZZES
  React.useEffect(() => {
    if (user) {
      if (savedQuizzesSnippets.length === 0) {
        getQuizzesSnippetsFirebase(user).then(function (result) {
          dispatchQuizzesSnippets({
            type: "init",
            payload: result ? result.quizzesSnippets : [],
          });
        });
      }
      if (quizzesScores.length === 0) {
        getQuizzesScoresFirebase(user).then(function (result) {
          dispatchQuizzesScores({
            type: "init",
            payload: result ? result.quizzesScores : [],
          });
        });
      }
    }
  }, [user, savedQuizzesSnippets.length, quizzesScores.length]);

  return (
    <QuizContext.Provider
      value={{
        savedQuizzes,
        dispatchQuizzes,
        savedQuizzesSnippets,
        dispatchQuizzesSnippets,
        quizzesScores,
        dispatchQuizzesScores,
      }}
    >
      {children}
    </QuizContext.Provider>
  );
};
