import React from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import css from "./Sidebar.module.css";

export const Sidebar = () => {
  const { userData } = React.useContext(UserContext);
  const { pathname } = useLocation();

  if (userData) {
    return (
      <div className={css.sidebar}>
        <div className={css.sidebarWrapper}>
          <div className={css.sidebarMenu}>
            <h3 className={css.sidebarTitle}>Discover</h3>
            <ul className={css.sidebarList}>
              {pathname === "/" ? (
                <Link to="/" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      local_library
                    </i>
                    Study
                  </li>
                </Link>
              ) : (
                <Link to="/" className={css.link}>
                  <li className={css.sidebarListItem}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      local_library
                    </i>
                    Study
                  </li>
                </Link>
              )}
              {pathname === "/cid10" ? (
                <Link to="/cid10" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      medical_information
                    </i>
                    CID-10
                  </li>
                </Link>
              ) : (
                <Link to="/cid10" className={css.link}>
                  <li className={css.sidebarListItem}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      medical_information
                    </i>
                    CID-10
                  </li>
                </Link>
              )}
              {/* {pathname === "/dicover" ? (
                <Link to="/discover" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      explore
                    </i>
                    Discover
                  </li>
                </Link>
              ) : (
                <Link to="/discover" className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      explore
                    </i>
                    Discover
                  </li>
                </Link>
              )} */}

              {/* 
            {pathname === "/medicines" ? (
              <Link to="/medicines" className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i className={`material-icons ${css.sidebarIcon}`}>
                    local_pharmacy
                  </i>
                  Fármacos
                </li>
              </Link>
            ) : (
              <Link to="/medicines" className={css.link}>
                <li className={css.sidebarListItem}>
                  <i className={`material-icons ${css.sidebarIcon}`}>
                    local_pharmacy
                  </i>
                  Fármacos
                </li>
              </Link>
            )} 
            */}

              {/* {userData.staff && (
                <Link to="/calendar" className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      calendar_month
                    </i>
                    Calendar
                  </li>
                </Link>
              )} */}
            </ul>
          </div>

          <div className={css.sidebarMenu}>
            <h3 className={css.sidebarTitle}>My Study</h3>
            <ul className={css.sidebarList}>
              {pathname === "/study/mymindmaps" ? (
                <Link to="/study/mymindmaps" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>route</i>
                    Mindmaps
                  </li>
                </Link>
              ) : (
                <Link to="/study/mymindmaps" className={css.link}>
                  <li className={css.sidebarListItem}>
                    <i className={`material-icons ${css.sidebarIcon}`}>route</i>
                    Mindmaps
                  </li>
                </Link>
              )}

              {pathname === "/study/myflashcards" ? (
                <Link to="/study/myflashcards" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      layers
                    </i>
                    Flashcards
                  </li>
                </Link>
              ) : (
                <Link to="/study/myflashcards" className={css.link}>
                  <li className={css.sidebarListItem}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      layers
                    </i>
                    Flashcards
                  </li>
                </Link>
              )}
              {pathname === "/study/myquizzes" ? (
                <Link to="/study/myquizzes" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>quiz</i>
                    Quiz
                  </li>
                </Link>
              ) : (
                <Link to="/study/myquizzes" className={css.link}>
                  <li className={css.sidebarListItem}>
                    <i className={`material-icons ${css.sidebarIcon}`}>quiz</i>
                    Quiz
                  </li>
                </Link>
              )}
              {/* {pathname === "/study/myzettels" ? (
                <Link to="/study/myzettels" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>notes</i>
                    Zettels
                  </li>
                </Link>
              ) : (
                <Link to="/study/myzettels" className={css.link}>
                  <li className={css.sidebarListItem}>
                    <i className={`material-icons ${css.sidebarIcon}`}>notes</i>
                    Zettels
                  </li>
                </Link>
              )} */}
              {/* {userData.staff && (
                <Link to="/study/analytics" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.link}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      bar_chart
                    </i>
                    Analytics
                  </li>
                </Link>
              )} */}
            </ul>
          </div>

          <div className={`copyright ${css.copyright} `}>
            mairimed.com © 2022
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
