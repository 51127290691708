import React, { useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import HTMLReactParser from "html-react-parser";
import { useParams } from "react-router-dom";

import { db } from "../../firebaseConfig";
import Head from "../../Utils/Head";

export const CicloBasicoArticle = () => {
  const params = useParams();
  const [article, setArticle] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  function makeTitle(slug) {
    var words = slug.split("-");

    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    return words.join(" ");
  }

  useEffect(() => {
    const getCicloBasico = async () => {
      const docRef = doc(db, "articles", "cicloBasico");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("cicloBasico loaded");
        return docSnap.data();
      } else {
        console.log("No such document");
      }
    };

    getCicloBasico().then(function (result) {
      setArticle(result[params.article]);
      setLoading(true);
    });
  }, [params.article]);

  if (!loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <Head
          title={makeTitle(params.article)}
          description={makeTitle(params.article) + " - Mairimed"}
        />
        <h1>{makeTitle(params.article)}</h1>
        {HTMLReactParser(article)}
      </div>
    );
  }
};
