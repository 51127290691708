import React from "react";

import styles from "./MyCalendars.module.css";
import { CalendarContext } from "../../Context/CalendarContext";
import { UserContext } from "../../Context/UserContext";

export default function Labels() {
  const { user } = React.useContext(UserContext);
  const { savedCalendars, dispatchCalendar } =
    React.useContext(CalendarContext);

  function handleChecked(cal) {
    cal.checked = !cal.checked;
    dispatchCalendar({ user: user, type: "update", payload: cal });
  }

  return (
    <div className={styles.body}>
      {savedCalendars.map((cal, idx) => (
        <label key={cal.id} className={"checkbox"}>
          {cal.name}
          <input
            type="checkbox"
            checked={cal.checked}
            onChange={() => handleChecked(cal)}
          />
          <span className={`checkmark ${cal.color}`}></span>
        </label>
      ))}
    </div>
  );
}
