import { useNavigate } from "react-router-dom";

import css from "./Chart.module.css";
import ChartElement from "./ChartElement";
import Connection from "./Connection";
import Toolbar from "../Layout/Toolbar";

function Chart(props) {
  const navigate = useNavigate();
  const WIDTH = props.width / props.zoom;
  const HEIGHT = props.height / props.zoom;
  let R = 40;

  const getChildren = (list, parent, elements) => {
    const itemLevel1 = list.filter((item) => item.level === 1);
    const children = list.filter((item) => item.parentId === parent.id);
    let countTill4 = 0;
    let level1YFactor = 1;
    let level1YFactor4 = 1;
    let childrenCounter = 0;
    for (let i = 0; i < children.length; i++) {
      let xFactor = 5;
      let yFactor = 5;
      let xParent = 0;
      childrenCounter = childrenCounter + 1;
      countTill4 = countTill4 + 1;
      let item = children[i];
      let isLeftSide = true;
      let isUp = true;
      // SET ISLEFT AND ISUP
      if (item.level === 1) {
        i % 2 === 0 ? (isLeftSide = true) : (isLeftSide = false);
        countTill4 < 3 ? (isUp = true) : (isUp = false);
      } else {
        isLeftSide = parent.isLeftSide;
      }
      if (item.level === 3) {
        i < 3 ? (isUp = false) : (isUp = true);
      }

      // CHANGE ITEM 1
      if (item.level === 1 && childrenCounter === 1) {
        if (itemLevel1.length < 5) {
          xFactor = 14;
        } else {
          xFactor = 8;
          level1YFactor4 = -600;
        }
      }
      // CHANGE ITEM 2
      if (item.level === 1 && childrenCounter === 2) {
        if (itemLevel1.length < 6) {
          xFactor = 14;
          level1YFactor4 = 1;
        } else {
          xFactor = 8;
          level1YFactor4 = -600;
        }
      }
      // CHANGE ITEM 3
      if (item.level === 1 && childrenCounter === 3) {
        if (itemLevel1.length < 7) {
          xFactor = 14;
          level1YFactor4 = 1;
        } else {
          xFactor = 8;
          level1YFactor4 = 600;
        }
      }
      // CHANGE ITEM 4
      if (item.level === 1 && childrenCounter === 4) {
        if (itemLevel1.length < 8) {
          xFactor = 14;
          level1YFactor4 = 1;
        } else {
          xFactor = 8;
          level1YFactor4 = 600;
        }
      }

      // CHANGE CHILDREN ITENS 1, 2, 3, 4
      if (item.level === 2 && parent.childrenCounter < 5) {
        xFactor = 8;
        yFactor = yFactor + 150;
      }

      // MUDA ITENS 5, 6, 7, 8
      if (item.level === 1 && childrenCounter >= 5) {
        xFactor = xFactor + 45;
        xParent = xParent - 1;
        level1YFactor4 = 1;
      }
      level1YFactor = countTill4 === 1 ? level1YFactor + 1 : level1YFactor + 0;
      if (item.level === 1 && childrenCounter % 5 === 0) {
        level1YFactor = level1YFactor - 32;
      }

      if (item.level === 2) {
        xFactor = xFactor + 14;
        yFactor = yFactor + 60 + i * 450;

        // if (i < 2) {
        //   yFactor = yFactor + 10 + i * 460;
        // } else if (i === 2) {
        //   yFactor = yFactor + 10 + i * 380;
        // } else if (i === 3) {
        //   yFactor = yFactor + 10 + i * 340;
        // } else if (i === 4) {
        //   yFactor = yFactor + 10 + i * 300;
        // } else {
        //   yFactor = yFactor + 10 + i * 280;
        // }
      }
      if (item.level === 3) {
        xFactor = xFactor + 12;
        yFactor = yFactor - 150 + i * 50;
      }

      countTill4 === 4 ? (countTill4 = 0) : (countTill4 = countTill4 + 0);

      // MUDA Y DO LEVEL 1
      let y1 = 0;
      if (isUp) {
        if (childrenCounter >= 5) {
          y1 = parent.y - 400 + (R * -level1YFactor) / 4;
        } else {
          y1 = parent.y - 80 + (R * -level1YFactor) / 4;
        }
      } else {
        if (childrenCounter >= 5) {
          y1 = parent.y + item.title.length + 400 + (R * level1YFactor) / 4;
        } else {
          y1 = parent.y + 80 + (R * level1YFactor) / 2;
        }
      }

      const element = {
        id: item.id,
        childrenCounter: childrenCounter,
        title: item.title,
        pName: parent.title,
        color: item.color,
        icon: item.icon,
        level: item.level,
        // x:
        //   isLeftSide === true
        //     ? parent.x + R * xFactor * -1
        //     : parent.x + R * xFactor,
        // y:
        //   item.level === 1
        //     ? y1
        //     : parent.isUp
        //     ? parent.y + yFactor * -1 * (i / 2 + parent.parentFactor * +1)
        //     : parent.y + yFactor * 1 * (i / 2 + parent.parentFactor * +1),
        x:
          isLeftSide === true
            ? parent.x + R * xFactor * -1
            : parent.x + R * xFactor,
        y:
          item.level === 1
            ? y1 + level1YFactor4
            : parent.isUp
            ? parent.y + yFactor * -1
            : parent.y + yFactor * 1,
        px: parent.x,
        py: parent.y,
        isLeftSide,
        isUp: item.level === 1 ? isUp : parent.isUp,
        parentFactor: 3 * item.level,
        xParent,
      };
      elements.push(element);
      getChildren(list, element, elements);
    }
  };

  const setElements = (list) => {
    const elements = [];
    const x0 = WIDTH / 2;
    const y0 = HEIGHT / 2;
    const root = list.find((item) => item.level === 0);
    const rootElement = {
      id: root.id,
      title: root.title,
      level: root.level,
      x: x0,
      y: y0,
    };
    elements.push(rootElement);
    getChildren(list, rootElement, elements);
    return elements;
  };

  const zoomMenu = [
    { name: "zoomIn", onClick: (e) => props.onZoomIn(e) },
    { name: "zoomOut", onClick: (e) => props.onZoomOut(e) },
    { name: "zoomReset", onClick: (e) => props.onZoomReset(e) },
    {
      name: "fullscreen",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/study/mindmap/fullscreen/${props.mindmapId}`);
      },
    },
  ];

  const elements = setElements(props.list);
  return (
    <div className={css.container}>
      <Toolbar
        list={zoomMenu}
        type="default"
        location={["horizontal", "right", "top"]}
      />
      <svg
        viewBox={`${props.x} ${props.y} ${WIDTH} ${HEIGHT}`}
        onMouseDown={(e) => props.onMouseDown(e)}
        onMouseMove={(e) => props.onMouseMove(e)}
        onMouseUp={() => props.onMouseUp()}
        xmlns="http://www.w3.org/2000/svg"
      >
        <Connection list={elements} />
        {elements.map((element) => (
          <ChartElement
            key={element.id}
            title={element.title}
            color={element.color}
            icon={element.icon}
            onClick={props.onClick}
            id={element.id}
            level={element.level}
            x={element.x}
            y={element.y}
            px={element.px}
            py={element.py}
            isSelected={element.id === props.id}
            isLeftSide={element.isLeftSide}
          />
        ))}
      </svg>
    </div>
  );
}

export default Chart;
