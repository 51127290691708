import React from "react";

import styles from "./CalendarModal.module.css";
import { CalendarContext } from "../../Context/CalendarContext";
import { newId } from "../../Utils/newId";
import { UserContext } from "../../Context/UserContext";

export default function CalendarModal() {
  const {
    calendarColors,
    setShowCalendarModal,
    selectedCalendar,
    dispatchCalEvent,
    dispatchCalendar,
  } = React.useContext(CalendarContext);
  const { user } = React.useContext(UserContext);
  const [name, setName] = React.useState(
    selectedCalendar ? selectedCalendar.name : ""
  );
  const [description, setDescription] = React.useState(
    selectedCalendar ? selectedCalendar.description : ""
  );
  const [color, setColor] = React.useState(
    selectedCalendar ? selectedCalendar.color : "color0"
  );

  function handleSubmit(e) {
    e.preventDefault();

    const calendar = {
      name,
      description,
      checked: true,
      color,
      id: selectedCalendar ? selectedCalendar.id : newId(),
    };
    if (selectedCalendar) {
      dispatchCalendar({
        user: user,
        type: "update",
        payload: calendar,
      });
    } else {
      dispatchCalendar({ user: user, type: "push", payload: calendar });
    }
    setShowCalendarModal(false);
  }

  return (
    <div className={styles.calendar}>
      <form className={styles.form}>
        <header className={styles.header}>
          <span
            onClick={() => setShowCalendarModal(false)}
            className={`material-icons ${styles.materialIcon} ${styles.materialButton}`}
          >
            close
          </span>
        </header>
        <div className={styles.body}>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            required
            className={styles.inputText}
            onChange={(e) => setName(e.target.value)}
            autoComplete="off"
          />
          <input
            type="text"
            name="description"
            placeholder="Description"
            value={description}
            required
            className={styles.inputText}
            onChange={(e) => setDescription(e.target.value)}
            autoComplete="off"
          />
          <input type="text" placeholder="Time Zone" />
          <div className={styles.bookmark}>
            {calendarColors.map((col, i) => (
              <span
                key={i}
                onClick={() => setColor(col)}
                className={`${col} ${styles.roundIcon}`}
              >
                {color === col && (
                  <span className={`material-icons ${styles.materialCheck}`}>
                    check
                  </span>
                )}
              </span>
            ))}
          </div>

          {selectedCalendar && (
            <div className={styles.formFooter}>
              {selectedCalendar.name !== user.displayName && (
                <button
                  className="deleteButton"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatchCalEvent({
                      type: "deleteCalendar",
                      payload: selectedCalendar,
                    });
                    dispatchCalendar({
                      user: user,
                      type: "delete",
                      payload: selectedCalendar,
                    });
                    setShowCalendarModal(false);
                  }}
                >
                  Delete Calendar
                </button>
              )}
            </div>
          )}
        </div>
        <div className={styles.formFooter}>
          <button type="submit" onClick={handleSubmit} className="saveButton">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
