import React from "react";
import { getUserScheduleFirebase } from "../Utils/firebaseSchedule";
import { savedMealsReducer, savedUserScheduleReducer } from "./scheduleReducer";
import { UserContext } from "./UserContext";

export const ScheduleContext = React.createContext();

export const ScheduleStorage = ({ children }) => {
  const { user } = React.useContext(UserContext);
  const [selectedTask, setSelectedTask] = React.useState("");
  const [savedMeals, dispatchMeals] = React.useReducer(savedMealsReducer, []);

  const [savedUserSchedule, dispatchUserSchedule] = React.useReducer(
    savedUserScheduleReducer,
    []
  );

  const [loadingSchedule, setLoadingSchedule] = React.useState(true);

  // POPULATE USERS SCHEDULE
  React.useEffect(() => {
    if (user) {
      if (savedMeals.length === 0) {
        getUserScheduleFirebase(user).then(function (result) {
          dispatchUserSchedule({
            user: user,
            type: "init",
            payload: result ? result : {},
          });
          setLoadingSchedule(false);
        });
      }
    }
  }, [user, savedMeals.length]);

  // React.useEffect(() => {
  //   if (user) {
  //     if (savedMeals.length === 0) {
  //       getMealsFirebase().then(function (result) {
  //         dispatchMeals({ type: "init", payload: result });
  //         console.log("init shedule loaded");
  //       });
  //     }
  //   }
  // }, [user, savedMeals.length]);

  return (
    <ScheduleContext.Provider
      value={{
        savedMeals,
        dispatchMeals,
        selectedTask,
        setSelectedTask,
        savedUserSchedule,
        dispatchUserSchedule,
        loadingSchedule,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};
