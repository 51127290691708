import styles from "./ChartElement.module.css";

function ChartElement(props) {
  const WIDTH = 100;
  const HEIGHT = 40;
  const isLeftSide = props.isLeftSide;
  let x = props.x - 15;
  let textOffset = -5;
  let iconX = 250;
  let textAlignment = styles.right;
  if (isLeftSide) {
    x -= WIDTH - 30;
    textOffset = 5;
    props.title.length < 12
      ? (iconX = 80 + props.title.length * 10)
      : (iconX = 130 + props.title.length * 12);
    textAlignment = styles.left;
  }
  if (!props.level) {
    textOffset = 0;
    textAlignment = styles.middle;
  }
  let className = styles.container;
  if (props.isSelected) {
    className += " " + styles.selected;
  }

  let startOffset = 0;
  if (props.isLeftSide) {
    props.title.length < 12 ? (startOffset = 20) : (startOffset = 0);
  } else {
    startOffset = 0;
  }

  let circleR = 12 * props.title.length;
  const root = (
    <circle
      className={className}
      cx={props.x}
      cy={props.y}
      r={circleR}
      fill="none"
      stroke="black"
    />
  );
  // COLOCAR CHILD COM RAMO MAIS FINO E GRANDCHILD COMO MAIS FINO
  const child = (
    <rect
      className={className}
      rx="3"
      ry="3"
      x={isLeftSide ? x + 30 : x - 50}
      y={props.y - HEIGHT / 1.5}
      width={WIDTH}
      height={HEIGHT}
      fill="none"
      stroke="black"
    />
  );
  // const grandChild = (
  //   <circle cx={props.x} cy={props.y} r="3" className={className} />
  // );
  let element;
  switch (props.level) {
    case 0:
      element = root;
      break;
    case 1:
      element = child;
      break;
    case 2:
      element = child;
      break;
    default:
      element = child;
      break;
  }

  return (
    <>
      <g onClick={() => props.onClick(props.id)}>
        {props.level === 0 && element}
        <text
          x={props.x - textOffset - iconX}
          y={props.y + 3}
          className={`material-icons materialIcon ${styles.icon}`}
        >
          {props.icon === "---" ? "" : props.icon}
        </text>
        {/* <text
          className={styles.text + " " + textAlignment}
          x={props.x + textOffset}
          y={props.y}
        >
          {props.title}
        </text> */}
        {props.level === 0 ? (
          <text
            className={styles.text + " " + textAlignment}
            x={props.x + textOffset}
            y={props.y}
          >
            {props.title}
          </text>
        ) : (
          <text className={styles.text + " " + className} x="0" y="0">
            <textPath startOffset={startOffset + "%"} href={"#" + props.id}>
              {props.title}
            </textPath>
          </text>
        )}
        {props.title.length === 0 && element}
      </g>
    </>
  );
}

export default ChartElement;
