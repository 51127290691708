import React from "react";
import dayjs from "dayjs";

import { CalendarContext } from "../../Context/CalendarContext";
import styles from "./Day.module.css";

export default function Day({ day }) {
  const { setDaySelected, setShowEventModal } =
    React.useContext(CalendarContext);

  function getCurrentDayClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? styles.currentDay
      : "";
  }

  return (
    <>
      <div
        className={styles.cell}
        onClick={() => {
          setDaySelected(day);
          setShowEventModal(true);
        }}
      >
        <p className={`${styles.dayNumber} ${getCurrentDayClass()}`}>
          {day.format("DD")}
        </p>
        <div className={styles.body}></div>
      </div>
    </>
  );
}
