import React from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { Link } from "react-router-dom";
import Head from "../../Utils/Head";

export const CicloBasico = () => {
  const [loading, setLoading] = React.useState(false);
  const [cicloBasico, setCicloBasico] = React.useState([]);

  function makeTitle(slug) {
    var words = slug.split("-");

    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    return words.join(" ");
  }

  React.useEffect(() => {
    const getCicloBasico = async () => {
      const docRef = doc(db, "articles", "cicloBasico");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("cicloBasico loaded");
        return docSnap.data();
      } else {
        console.log("No such document");
      }
    };

    getCicloBasico().then(function (result) {
      if (result) {
        setCicloBasico(Object.keys(result).sort());
        setLoading(true);
      }
    });
  }, []);

  if (!loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <Head title="CicloBasico" description="CicloBasico - Mairimed" />
        <h2>Ciclo Básico</h2>
        {cicloBasico.map((title, i) => (
          <h4 key={i}>
            <Link to={title}>{makeTitle(title)}</Link>
          </h4>
        ))}
      </div>
    );
  }
};
