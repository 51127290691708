import {
  createMindmapFirebase,
  updateMindmapFirebase,
  deleteMindmapFirebase,
} from "../Utils/firebaseMindmaps";

export const savedMindmapsSnippetsReducer = (state, { type, payload }) => {
  switch (type) {
    case "init":
      return payload;
    case "push":
      return [...state, payload];
    case "update":
      return state.map((mindmap) =>
        mindmap.id === payload.id ? payload : mindmap
      );
    case "delete":
      return state.filter((mindmap) => mindmap.id !== payload.id);
    case "pushItem":
      return [...state, payload];
    case "deleteItem":
      return state.filter((item) => item.id !== payload.id);
    default:
      throw new Error();
  }
};

export const savedMindmapsReducer = (
  state,
  { type, user, payload, mindmapsSnippets }
) => {
  switch (type) {
    case "init":
      return payload;
    case "push":
      createMindmapFirebase(user, payload, mindmapsSnippets);
      return [...state, payload];
    case "update":
      updateMindmapFirebase(user, payload, mindmapsSnippets);
      return state.map((mindmap) =>
        mindmap.id === payload.id ? payload : mindmap
      );
    case "delete":
      deleteMindmapFirebase(user, payload, mindmapsSnippets);
      return state.filter((mindmap) => mindmap.id !== payload.id);
    case "pushItem":
      return [...state, payload];
    case "deleteItem":
      return state.filter((item) => item.id !== payload.id);
    default:
      throw new Error();
  }
};
