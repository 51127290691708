import React from "react";
import { Link } from "react-router-dom";

import css from "./Login.module.css";
import { UserContext } from "../../Context/UserContext";
import useForm from "../../Hooks/useForm";
import Button from "../Forms/Button";
import Input from "../Forms/Input";
import Error from "../../Utils/Error";
import Head from "../../Utils/Head";

const LoginCreate = () => {
  const { userRegister, loading, error } = React.useContext(UserContext);
  const name = useForm();
  const lastName = useForm();
  const email = useForm("email");
  const password = useForm("password");
  const confirmPassword = useForm("confirmPassword");
  const [alertFirst, setAlertFirst] = React.useState("");
  const [alertLast, setAlertLast] = React.useState("");
  const [alertEmail, setAlertEmail] = React.useState("");
  const [alertPass, setAlertPass] = React.useState("");
  const [alertValid, setAlertValid] = React.useState("");

  function handleValidation() {
    let formIsValid = true;
    if (!name.value) {
      formIsValid = false;
      setAlertFirst("You need a first name");
    } else {
      setAlertFirst("");
    }
    if (!lastName.value) {
      formIsValid = false;
      setAlertLast("You need a last name");
    } else {
      setAlertLast("");
    }
    if (!email.value) {
      formIsValid = false;
      setAlertEmail("You need a email");
    } else {
      setAlertEmail("");
    }
    if (!password.value) {
      formIsValid = false;
      setAlertValid("You need a password");
    }
    if (password.value !== confirmPassword.value) {
      formIsValid = false;
      setAlertValid("Password and Confirm Password need to be the same");
    } else {
      setAlertValid("");
    }
    return formIsValid;
  }

  async function handleSubmit() {
    userRegister(name.value, lastName.value, email.value, password.value);
  }

  return (
    <>
      <section className={css.login}>
        <div></div>
        <div className={css.forms}>
          <Head title="Create your Mairimed Account" />
          <h1 className="title">Create your Mairimed Account</h1>
          {alertFirst && <p className={css.error}>{alertFirst}</p>}
          {alertLast && <p className={css.error}>{alertLast}</p>}
          {alertEmail && <p className={css.error}>{alertEmail}</p>}
          {alertValid && <p className={css.error}>{alertValid}</p>}
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleValidation() && handleSubmit();
            }}
          >
            <Input label="First name" type="text" name="name" {...name} />
            <Input
              label="Last name"
              type="text"
              name="lastName"
              {...lastName}
            />
            <Input label="Email" type="email" name="email" {...email} />
            <Input
              label="Password"
              type="password"
              name="password"
              {...password}
            />
            <Input
              label="Confirm Password"
              type="password"
              name="confirmPassword"
              {...confirmPassword}
            />
            {loading ? (
              <Button disabled>Loading...</Button>
            ) : (
              <Button>Submit</Button>
            )}
            <Error error={error} />
          </form>
          <p>
            already hove an account?{" "}
            <Link className="textLink" to="/login">
              Sign in
            </Link>
          </p>
        </div>
      </section>
    </>
  );
};

export default LoginCreate;
