import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./StartQuiz.module.css";
import { UserContext } from "../../Context/UserContext";
import { getQuizFirebase } from "../../Utils/firebaseQuiz";
import { QuizContext } from "../../Context/QuizContext";
import Head from "../../Utils/Head";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { setFavoritesFirebase } from "../../Utils/firebaseUser";

export const StartQuiz = () => {
  const {
    user,
    savedFavorites,
    favoritesQuizId,
    setFavoritesQuizId,
    favoritesQuizTitle,
    setFavoritesQuizTitle,
  } = useContext(UserContext);
  const { dispatchQuizzesScores, quizzesScores } =
    React.useContext(QuizContext);
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [quiz, setQuiz] = useState("");
  const [questions, setQuestions] = useState([]);
  const [indexQuestion, setIndexQuestion] = useState(0);
  const [endQuiz, setEndQuiz] = useState(false);
  const [score, setScore] = useState(0);
  const [answered, setAnswered] = useState(false);
  const [selected, setSelected] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [favorite, setFavorite] = useState(false);

  useEffect(() => {
    getQuizFirebase(params.id).then(function (result) {
      result ? setQuiz(result) : setQuiz(false);
      result && setQuestions(result.questions);
      if (favoritesQuizId.indexOf(result.id) >= 0) {
        setFavorite(true);
      }
      setLoading(false);
    });
  }, [user, params.id]);

  function handleCssButton(i) {
    let answerCss = css.answerButton;
    if (i === questions[indexQuestion].correct) {
      answerCss = css.correct;
    }
    if (selected === i && i !== questions[indexQuestion].correct) {
      answerCss = css.incorrect;
    }
    return answerCss;
  }

  function handleAnswer(i) {
    setAnswered(true);
    if (i === questions[indexQuestion].correct) {
      setScore(score + 1);
    }
  }

  function handleNextQuestion() {
    setAnswered(false);
    if (questions.length > indexQuestion + 1) {
      setIndexQuestion(indexQuestion + 1);
    } else {
      setEndQuiz(true);
    }
  }

  function updateQuizzesScore() {
    const percentageScore = Math.round((score * 100) / questions.length);
    let newQuizScore = {
      quizId: quiz.id,
      quizTitle: quiz.title,
      percentageScore: percentageScore,
    };
    let updatedQuizzesScores = quizzesScores;

    if (quizzesScores.map((q) => q.quizId).indexOf(quiz.id) < 0) {
      updatedQuizzesScores.push(newQuizScore);
    } else {
      updatedQuizzesScores = quizzesScores.map((q) =>
        q.quizId === newQuizScore.quizId ? newQuizScore : q
      );
    }

    quizzesScores.length < 100 &&
      dispatchQuizzesScores({
        type: "update",
        user: user,
        payload: updatedQuizzesScores,
      });
    navigate("/study/myquizzes");
  }

  const setFavoriteFirebase = () => {
    let updatedfavorites = savedFavorites;
    updatedfavorites.quizId = favoritesQuizId;
    updatedfavorites.quizTitle = favoritesQuizTitle;
    if (favorite) {
      updatedfavorites.quizId = updatedfavorites.quizId.filter(
        (fav) => fav !== quiz.id
      );
      updatedfavorites.quizTitle = updatedfavorites.quizTitle.filter(
        (fav) => fav !== quiz.title
      );
      setFavoritesQuizId(updatedfavorites.quizId);
      setFavoritesQuizTitle(updatedfavorites.quizTitle);
      setFavoritesFirebase(user, updatedfavorites);
      setFavorite(!favorite);
    } else {
      if (updatedfavorites.quizId.length < 5) {
        updatedfavorites.quizId.push(quiz.id);
        updatedfavorites.quizTitle.push(quiz.title);
        setFavoritesQuizId(updatedfavorites.quizsId);
        setFavoritesQuizTitle(updatedfavorites.quizsTitle);
        setFavoritesFirebase(user, updatedfavorites);
        setFavorite(!favorite);
      } else {
        alert("Maximum capacity of favorites (5)");
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  } else if (quiz === false) {
    return (
      <>
        <h2>Quiz not found</h2>
      </>
    );
  } else {
    return (
      <>
        <Head
          title={`Start ${quiz.title}`}
          description={`Start ${quiz.title} - Mairimed`}
        />
        <div className={css.wrapper}>
          <div className={css.body}>
            <h2>{quiz.title}</h2>
            <p>{quiz.description}</p>
            <div className={css.options}>
              {quiz.userId === user.uid ? (
                <i
                  className="material-icons materialIcon"
                  onClick={() => navigate(`/study/editquiz/${quiz.id}`)}
                >
                  edit
                </i>
              ) : (
                <div className={css.divSpace}></div>
              )}
              {favorite ? (
                <i
                  className="material-icons materialIcon"
                  onClick={() => {
                    setFavoriteFirebase();
                  }}
                >
                  favorite
                </i>
              ) : (
                <i
                  className="material-icons materialIcon"
                  onClick={() => {
                    setFavoriteFirebase();
                  }}
                >
                  favorite_border
                </i>
              )}
              {answered ? (
                <div className={css.options}>
                  <button
                    className="defaultButton"
                    onClick={() => handleNextQuestion()}
                  >
                    Next
                  </button>
                </div>
              ) : endQuiz ? (
                <div className={css.options}>
                  <button
                    className="saveButton"
                    onClick={() => updateQuizzesScore()}
                  >
                    Save results
                  </button>
                </div>
              ) : (
                <div className={css.options}></div>
              )}
            </div>
            <div className={css.cardContainer}>
              {!endQuiz ? (
                <>
                  <div className={css.card}>
                    <div
                      className={
                        questions[indexQuestion].question.length > 765
                          ? css.questionTextLarge
                          : css.questionSection
                      }
                    >
                      <div className={css.questionCount}>
                        <span>Question {indexQuestion + 1}</span>/{" "}
                        {questions.length}
                      </div>
                      <div>{questions[indexQuestion].question}</div>
                      {questions[indexQuestion].image && (
                        <button onClick={() => setImageModal(true)}>
                          image
                        </button>
                      )}
                    </div>
                    <div className={css.answerSection}>
                      {questions[indexQuestion].answers.map((a, i) => (
                        <button
                          className={
                            answered ? handleCssButton(i) : css.answerButton
                          }
                          onClick={() => {
                            setSelected(i);
                            handleAnswer(i);
                          }}
                          key={i}
                          disabled={answered ? true : false}
                        >
                          {a}
                        </button>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={css.card}>
                    <div className={css.questionSection}>
                      <div className={css.questionCount}>
                        <span>Result</span>
                      </div>
                      You score {score} out of {questions.length}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {imageModal && (
          <OutsideAlerter setMenuButton={setImageModal}>
            <div className={css.modal}>
              <img alt="" src={questions[indexQuestion].image} />
            </div>
          </OutsideAlerter>
        )}
      </>
    );
  }
};
