import {
  doc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

// DECKS

export const getTagFlashcardsFirebase = async (tag) => {
  const resultDecks = [];
  const docRef = collection(db, "flashcards");
  const q1 = query(
    docRef,
    where("tag1.title", "==", tag),
    where("hide", "==", false)
  );
  const q2 = query(
    docRef,
    where("tag2.title", "==", tag),
    where("hide", "==", false)
  );
  const q3 = query(
    docRef,
    where("tag3.title", "==", tag),
    where("hide", "==", false)
  );
  const querySnap1 = await getDocs(q1);
  const querySnap2 = await getDocs(q2);
  const querySnap3 = await getDocs(q3);
  if (querySnap1) {
    console.log(`flashcards tag1=${tag} loaded`);
    let docs = querySnap1.docs.map((doc) => ({ ...doc.data() }));
    docs.length > 0 && docs.forEach((doc) => resultDecks.push(doc));
  }
  if (querySnap2) {
    console.log(`flashcards tag2=${tag} loaded`);
    let docs = querySnap2.docs.map((doc) => ({ ...doc.data() }));
    docs.length > 0 && docs.forEach((doc) => resultDecks.push(doc));
  }
  if (querySnap3) {
    console.log(`flashcards tag3=${tag} loaded`);
    let docs = querySnap3.docs.map((doc) => ({ ...doc.data() }));
    docs.length > 0 && docs.forEach((doc) => resultDecks.push(doc));
  } else {
    console.log("No such document");
  }
  return resultDecks;
};

export const getFlashcardsSnippetsFirebase = async (user) => {
  const docRef = doc(db, "users", user.uid, "snippets", "flashcardsSnippets");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log("flashcardsSnippets loaded");
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const getFlashcardsReviewFirebase = async (user) => {
  const docRef = doc(db, "users", user.uid, "analytics", "flashcardsReview");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log("flashcardsReview loaded");
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const getUserDecksFirebase = async (user) => {
  const docRef = collection(db, "decks");
  const q = query(docRef, where("userId", "==", user.uid));
  const querySnap = await getDocs(q);
  if (querySnap) {
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const getDeckFirebase = async (id) => {
  const docRef = doc(db, "flashcards", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`deck ${id} loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const createDeckFirebase = async (user, deck, flashcardsSnippets) => {
  await setDoc(doc(db, "flashcards", deck.id), {
    id: deck.id,
    hide: deck.hide,
    userId: deck.userId,
    userName: deck.userName,
    title: deck.title,
    description: deck.description,
    tag1: deck.tag1,
    tag2: deck.tag2,
    tag3: deck.tag3,
    cards: deck.cards,
  });

  await setDoc(doc(db, "users", user.uid, "snippets", "flashcardsSnippets"), {
    flashcardsSnippets,
  });

  console.log(`Flashcard ${deck.title} created`);
};

export const updateDeckFirebase = async (user, deck, flashcardsSnippets) => {
  await updateDoc(doc(db, "flashcards", deck.id), {
    id: deck.id,
    hide: deck.hide,
    userId: deck.userId,
    userName: deck.userName,
    title: deck.title,
    description: deck.description,
    tag1: deck.tag1,
    tag2: deck.tag2,
    tag3: deck.tag3,
    cards: deck.cards,
  });

  await setDoc(doc(db, "users", user.uid, "snippets", "flashcardsSnippets"), {
    flashcardsSnippets,
  });

  console.log(`Flashcard ${deck.title} updated`);
};

export const updateFlashcardsReview = async (user, flashcardsReview) => {
  await setDoc(
    doc(db, "users", user.uid, "analytics", "flashcardsReview"),
    flashcardsReview
  );
  console.log(`flashcardsReview updated`);
};

export const deleteDeckFirebase = async (user, deck, flashcardsSnippets) => {
  await deleteDoc(doc(db, "flashcards", deck.id));
  await setDoc(doc(db, "users", user.uid, "snippets", "flashcardsSnippets"), {
    flashcardsSnippets,
  });

  console.log(`Flashcard ${deck.title} deleted`);
};

// CARDS

export const createCardFirebase = async (deck, card) => {
  await setDoc(doc(db, "decks", deck.id, "cards", card.id), {
    id: card.id,
    front: card.front,
    back: card.back,
  });
  console.log("Card created");
};

export const updateCardFirebase = async (user, deck, cards) => {
  await updateDoc(doc(db, "users", user.uid, "myDecks", deck.id), {
    cards,
  });
  console.log("Card updated");
};
