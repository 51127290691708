import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { db } from "../../firebaseConfig";

export const ICD10Details = () => {
  const [icd, setIcd] = useState(false);
  const params = useParams();

  useEffect(() => {
    const getCID10 = async () => {
      const docRef = doc(db, "icd", "cid10");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("cicloBasico loaded");
        return docSnap.data();
      } else {
        console.log("No such document");
      }
    };

    getCID10().then(function (result) {
      setIcd(
        result.cid10[result.cid10.map((icd) => icd.slug).indexOf(params.slug)]
      );
    });
  }, [params.slug]);

  if (icd) {
    return (
      <div>
        {icd.cod} - {icd.title}
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
